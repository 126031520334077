import { default as _91id_93fv98oxKlOsMeta } from "/app/main-site/pages/admin/[slug]/[id].vue?macro=true";
import { default as indexyR7YSwg5qeMeta } from "/app/main-site/pages/admin/logger/index.vue?macro=true";
import { default as indexkEd48VdiyjMeta } from "/app/main-site/pages/admin/reviews/index.vue?macro=true";
import { default as indexbkOIQdmfeBMeta } from "/app/main-site/pages/admin/table/index.vue?macro=true";
import { default as indexsMNx3XanCoMeta } from "/app/main-site/pages/comparison/index.vue?macro=true";
import { default as index34kbkIjD2yMeta } from "/app/main-site/pages/cookies-policy/index.vue?macro=true";
import { default as indexDsp0HPXQ9WMeta } from "/app/main-site/pages/directory/index.vue?macro=true";
import { default as indexR8PnmnnU4FMeta } from "/app/main-site/pages/directory/map/index.vue?macro=true";
import { default as indexdOYNttnQjBMeta } from "/app/main-site/pages/faq/index.vue?macro=true";
import { default as indexOWET4SFRVpMeta } from "/app/main-site/pages/index.vue?macro=true";
import { default as indexQ1TiTYLegHMeta } from "/app/main-site/pages/portfolio/index.vue?macro=true";
import { default as indexNd2UuEtm5MMeta } from "/app/main-site/pages/privacy-policy/index.vue?macro=true";
import { default as indexz8yrZ7dbWhMeta } from "/app/main-site/pages/profile/index.vue?macro=true";
import { default as indexPvOik7Sq2YMeta } from "/app/main-site/pages/property/[id]/index.vue?macro=true";
import { default as indexqft6xH3wYRMeta } from "/app/main-site/pages/terms/index.vue?macro=true";
import { default as emailMaf7YpYqISMeta } from "/app/main-site/pages/third-party-auth/email.vue?macro=true";
import { default as googleoXhIVxUTATMeta } from "/app/main-site/pages/third-party-auth/google.vue?macro=true";
import { default as component_45stubV9JpzQo11UMeta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubV9JpzQo11U } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-slug-id___en",
    path: "/admin/:slug()/:id()",
    component: () => import("/app/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___de",
    path: "/de/admin/:slug()/:id()",
    component: () => import("/app/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___ru",
    path: "/ru/admin/:slug()/:id()",
    component: () => import("/app/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___es",
    path: "/es/admin/:slug()/:id()",
    component: () => import("/app/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-logger___en",
    path: "/admin/logger",
    meta: indexyR7YSwg5qeMeta || {},
    component: () => import("/app/main-site/pages/admin/logger/index.vue")
  },
  {
    name: "admin-logger___de",
    path: "/de/admin/logger",
    meta: indexyR7YSwg5qeMeta || {},
    component: () => import("/app/main-site/pages/admin/logger/index.vue")
  },
  {
    name: "admin-logger___ru",
    path: "/ru/admin/logger",
    meta: indexyR7YSwg5qeMeta || {},
    component: () => import("/app/main-site/pages/admin/logger/index.vue")
  },
  {
    name: "admin-logger___es",
    path: "/es/admin/logger",
    meta: indexyR7YSwg5qeMeta || {},
    component: () => import("/app/main-site/pages/admin/logger/index.vue")
  },
  {
    name: "admin-reviews___en",
    path: "/admin/reviews",
    meta: indexkEd48VdiyjMeta || {},
    component: () => import("/app/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___de",
    path: "/de/admin/reviews",
    meta: indexkEd48VdiyjMeta || {},
    component: () => import("/app/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___ru",
    path: "/ru/admin/reviews",
    meta: indexkEd48VdiyjMeta || {},
    component: () => import("/app/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___es",
    path: "/es/admin/reviews",
    meta: indexkEd48VdiyjMeta || {},
    component: () => import("/app/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-table___en",
    path: "/admin/table",
    meta: indexbkOIQdmfeBMeta || {},
    component: () => import("/app/main-site/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___de",
    path: "/de/admin/table",
    meta: indexbkOIQdmfeBMeta || {},
    component: () => import("/app/main-site/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___ru",
    path: "/ru/admin/table",
    meta: indexbkOIQdmfeBMeta || {},
    component: () => import("/app/main-site/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___es",
    path: "/es/admin/table",
    meta: indexbkOIQdmfeBMeta || {},
    component: () => import("/app/main-site/pages/admin/table/index.vue")
  },
  {
    name: "comparison___en",
    path: "/comparison",
    meta: indexsMNx3XanCoMeta || {},
    component: () => import("/app/main-site/pages/comparison/index.vue")
  },
  {
    name: "comparison___de",
    path: "/de/comparison",
    meta: indexsMNx3XanCoMeta || {},
    component: () => import("/app/main-site/pages/comparison/index.vue")
  },
  {
    name: "comparison___ru",
    path: "/ru/comparison",
    meta: indexsMNx3XanCoMeta || {},
    component: () => import("/app/main-site/pages/comparison/index.vue")
  },
  {
    name: "comparison___es",
    path: "/es/comparison",
    meta: indexsMNx3XanCoMeta || {},
    component: () => import("/app/main-site/pages/comparison/index.vue")
  },
  {
    name: "cookies-policy___en",
    path: "/cookies-policy",
    component: () => import("/app/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___de",
    path: "/de/cookies-policy",
    component: () => import("/app/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___ru",
    path: "/ru/cookies-policy",
    component: () => import("/app/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___es",
    path: "/es/cookies-policy",
    component: () => import("/app/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "directory___en",
    path: "/directory",
    meta: indexDsp0HPXQ9WMeta || {},
    component: () => import("/app/main-site/pages/directory/index.vue")
  },
  {
    name: "directory___de",
    path: "/de/directory",
    meta: indexDsp0HPXQ9WMeta || {},
    component: () => import("/app/main-site/pages/directory/index.vue")
  },
  {
    name: "directory___ru",
    path: "/ru/directory",
    meta: indexDsp0HPXQ9WMeta || {},
    component: () => import("/app/main-site/pages/directory/index.vue")
  },
  {
    name: "directory___es",
    path: "/es/directory",
    meta: indexDsp0HPXQ9WMeta || {},
    component: () => import("/app/main-site/pages/directory/index.vue")
  },
  {
    name: "directory-map___en",
    path: "/directory/map",
    meta: indexR8PnmnnU4FMeta || {},
    component: () => import("/app/main-site/pages/directory/map/index.vue")
  },
  {
    name: "directory-map___de",
    path: "/de/directory/map",
    meta: indexR8PnmnnU4FMeta || {},
    component: () => import("/app/main-site/pages/directory/map/index.vue")
  },
  {
    name: "directory-map___ru",
    path: "/ru/directory/map",
    meta: indexR8PnmnnU4FMeta || {},
    component: () => import("/app/main-site/pages/directory/map/index.vue")
  },
  {
    name: "directory-map___es",
    path: "/es/directory/map",
    meta: indexR8PnmnnU4FMeta || {},
    component: () => import("/app/main-site/pages/directory/map/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: indexdOYNttnQjBMeta || {},
    component: () => import("/app/main-site/pages/faq/index.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: indexdOYNttnQjBMeta || {},
    component: () => import("/app/main-site/pages/faq/index.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    meta: indexdOYNttnQjBMeta || {},
    component: () => import("/app/main-site/pages/faq/index.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    meta: indexdOYNttnQjBMeta || {},
    component: () => import("/app/main-site/pages/faq/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexOWET4SFRVpMeta || {},
    component: () => import("/app/main-site/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexOWET4SFRVpMeta || {},
    component: () => import("/app/main-site/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexOWET4SFRVpMeta || {},
    component: () => import("/app/main-site/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexOWET4SFRVpMeta || {},
    component: () => import("/app/main-site/pages/index.vue")
  },
  {
    name: "portfolio___en",
    path: "/portfolio",
    component: () => import("/app/main-site/pages/portfolio/index.vue")
  },
  {
    name: "portfolio___de",
    path: "/de/portfolio",
    component: () => import("/app/main-site/pages/portfolio/index.vue")
  },
  {
    name: "portfolio___ru",
    path: "/ru/portfolio",
    component: () => import("/app/main-site/pages/portfolio/index.vue")
  },
  {
    name: "portfolio___es",
    path: "/es/portfolio",
    component: () => import("/app/main-site/pages/portfolio/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/app/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/app/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/ru/privacy-policy",
    component: () => import("/app/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/app/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: indexz8yrZ7dbWhMeta || {},
    component: () => import("/app/main-site/pages/profile/index.vue")
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: indexz8yrZ7dbWhMeta || {},
    component: () => import("/app/main-site/pages/profile/index.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    meta: indexz8yrZ7dbWhMeta || {},
    component: () => import("/app/main-site/pages/profile/index.vue")
  },
  {
    name: "profile___es",
    path: "/es/profile",
    meta: indexz8yrZ7dbWhMeta || {},
    component: () => import("/app/main-site/pages/profile/index.vue")
  },
  {
    name: "property-id___en",
    path: "/property/:id()",
    meta: indexPvOik7Sq2YMeta || {},
    component: () => import("/app/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "property-id___de",
    path: "/de/property/:id()",
    meta: indexPvOik7Sq2YMeta || {},
    component: () => import("/app/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "property-id___ru",
    path: "/ru/property/:id()",
    meta: indexPvOik7Sq2YMeta || {},
    component: () => import("/app/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "property-id___es",
    path: "/es/property/:id()",
    meta: indexPvOik7Sq2YMeta || {},
    component: () => import("/app/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    component: () => import("/app/main-site/pages/terms/index.vue")
  },
  {
    name: "terms___de",
    path: "/de/terms",
    component: () => import("/app/main-site/pages/terms/index.vue")
  },
  {
    name: "terms___ru",
    path: "/ru/terms",
    component: () => import("/app/main-site/pages/terms/index.vue")
  },
  {
    name: "terms___es",
    path: "/es/terms",
    component: () => import("/app/main-site/pages/terms/index.vue")
  },
  {
    name: "third-party-auth-email___en",
    path: "/third-party-auth/email",
    component: () => import("/app/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___de",
    path: "/de/third-party-auth/email",
    component: () => import("/app/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___ru",
    path: "/ru/third-party-auth/email",
    component: () => import("/app/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___es",
    path: "/es/third-party-auth/email",
    component: () => import("/app/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-google___en",
    path: "/third-party-auth/google",
    component: () => import("/app/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___de",
    path: "/de/third-party-auth/google",
    component: () => import("/app/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___ru",
    path: "/ru/third-party-auth/google",
    component: () => import("/app/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___es",
    path: "/es/third-party-auth/google",
    component: () => import("/app/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/search",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/search/:pathMatch(.*)",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/ru-RU-sitemap.xml",
    component: component_45stubV9JpzQo11U
  },
  {
    name: component_45stubV9JpzQo11UMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubV9JpzQo11U
  }
]
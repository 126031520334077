<template>
  <div v-if="loading" class="profile-container__portfolio">
    <div class="top-blocks">
      <q-skeleton width="314px" height="106px" />
      <q-skeleton width="314px" height="106px" />
      <q-skeleton width="314px" height="106px" />
    </div>
    <div class="charts">
      <q-skeleton width="652px" height="312px" />
      <q-skeleton width="314px" height="312px" />
    </div>
  </div>
  <div
    v-else-if="!isNoPortfolio"
    class="profile-container__portfolio animate__animated animate__fadeIn"
  >
    <div class="top-blocks">
      <portfolio-top-block
        :title="totalInvestment"
        :subtitle="$t('profile.portfolio_balance')"
        :is-badge="portfolioData?.changedPercentage !== 0"
        is-majot
        :badge-value="portfolioData?.changedPercentage"
        :is-up="portfolioData?.changedPercentage > 0"
      />
      <portfolio-top-block
        :title="cashFlow"
        :subtitle="$t('profile.portfolio_projected_cashflow')"
        text-subtitle
        is-info-icon
      />
      <portfolio-top-block
        :title="
          $t('profile.portfolio_units', { count: portfolioData?.unitsCount })
        "
        :subtitle="$t('profile.portfolio_total_units')"
        text-subtitle
      />
    </div>
    <div class="charts">
      <portfolio-charts-total-invest
        v-if="portfolioData?.charts?.totalInvestment"
        :chart="portfolioData?.charts?.totalInvestment"
      />
      <portfolio-charts-total-tokens :chart="totalTokensChart" />
    </div>
    <portfolio-investments :token-distribution="tokenDistribution" />
    <section v-if="portfolioDataRef?.units">
      <div v-if="!$device.isMobile" class="cards-grid">
        <portfolio-property-card
          v-for="unit in portfolioDataRef.units"
          :key="unit.id"
          :unit="unit"
          :portfolio="portfolioDataRef"
          :user-tokens="
            portfolioData?.charts?.distributionByToken?.find(
              (item) => item.unitId === unit.id
            )?.balance
          "
        />
      </div>
      <div v-else class="cards-grid">
        <portfolio-property-card-mobile
          v-for="unit in portfolioDataRef.units"
          :key="unit.id"
          :unit="unit"
          :portfolio="portfolioDataRef"
        />
      </div>
    </section>
  </div>
  <div
    v-else-if="!$device.isMobile && isNoPortfolio"
    class="profile-container__portfolio profile-container__portfolio--nodata animate__animated animate__fadeIn"
  >
    <search-page-no-objects>
      <template #default>
        <span style="max-width: 362px; text-align: center">
          <h3
            class="text-head-2 text-bold mobile-font--h4 text-black-monochrome text-sora"
          >
            {{ $t("profile.p_no_portfolio.no_props") }}
          </h3>
          <p class="text-body-1 mobile-font--b2 text-black-monochrome">
            {{ $t("profile.p_no_portfolio.browse") }}
          </p>
        </span>
        <nav aria-label="actions" style="display: flex; gap: 24px">
          <base-button
            text="Try demo"
            variant="dark"
            size="sg"
            icon-size="20px"
            style="width: 155px"
            @click="handleStartPortfolioDemo"
          />
          <base-button
            v-if="!useUserStore.isInSystem"
            :text="$t('header.h_wallet')"
            variant="prime"
            size="sg"
            icon-name="base/Right"
            icon-position="right"
            icon-size="20px"
            @click="debouncedLoginWeb3"
          />
        </nav>
      </template>
    </search-page-no-objects>
  </div>
  <div
    v-else-if="$device.isMobile && isNoPortfolio"
    class="profile-container__portfolio profile-container__portfolio--nodata animate__animated animate__fadeIn"
  >
    <search-page-no-objects>
      <template #default>
        <span style="max-width: 362px; text-align: center">
          <h3
            class="text-head-2 text-bold mobile-font--h4 text-black-monochrome text-sora"
          >
            {{ $t("profile.p_no_portfolio.no_props") }}
          </h3>
          <p class="text-body-1 mobile-font--b2 text-black-monochrome">
            {{ $t("profile.p_no_portfolio.browse") }}
          </p>
        </span>
        <nav
          aria-label="actions"
          style="display: flex; gap: 19px; flex-direction: column"
        >
          <base-button
            text="Try demo"
            variant="dark"
            size="sg"
            icon-size="20px"
            @click="handleStartPortfolioDemo"
          />
          <base-button
            v-if="!useUserStore.isInSystem"
            :text="$t('header.h_wallet')"
            variant="prime"
            size="sg"
            icon-name="base/Right"
            icon-position="right"
            icon-size="20px"
            @click="debouncedLoginWeb3"
          />
        </nav>
      </template>
    </search-page-no-objects>
  </div>
</template>

<script setup lang="ts">
import type { PortfolioDTO } from "~/services/swagger/Api";
import { userStore } from "~/store/user";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import { formatPrice } from "~/utilities/helpers/format-data/number";
import getRoute from "~/utilities/configs/apiMap";
import { apiPost } from "~/services/api";
import type { PropertyItem } from "~/types/search-page-items";
import { debounce } from "lodash";
import { Notification } from "~/services/notifications/toast";
import type { QNotifyUpdateOptions } from "quasar";

interface DataItem {
  date: string;
  total: number;
}

interface CountryTokenData {
  countryId: string;
  totalTokens: number;
}

interface ProviderTokenData {
  id: string;
  name: string;
  totalTokens: number;
  logoFile: string | undefined;
}

export interface TokenDistribution {
  countries: CountryTokenData[];
  providers: ProviderTokenData[];
}

interface Emits {
  (event: "startPortfolio", value: boolean): void;
}

const useUserStore = userStore();

const PORTFOLIO = useUserStore.getPortfolioClass();

const emit = defineEmits<Emits>();

const isLocalCurrency = computed(() => "USD" !== BASE_CURRENCY.value.code);

const loading = computed(() => useUserStore.$state.portfolioLoading);
const isNoPortfolio = ref(false);
const portfolioDataRef = ref<PortfolioDTO | null>({ units: [] });
const portfolioData = computed(() => portfolioDataRef.value);
const tokenDistribution = ref<TokenDistribution | null>(null);

const totalInvestment = computed(() => {
  return (
    BASE_CURRENCY.value.symbol +
    formatPrice(
      portfolioData.value?.balance,
      true,
      isLocalCurrency.value,
      undefined,
      2
    )
  );
});

const cashFlow = computed(() => {
  return (
    BASE_CURRENCY.value.symbol +
    formatPrice(
      portfolioData.value?.projectedMonthlyCashflow,
      true,
      isLocalCurrency.value
    )
  );
});

const totalTokensChart = computed(() => {
  return (
    portfolioData.value?.charts?.distributionByToken.map((item) => {
      return {
        ...item,
        name: portfolioData.value?.units?.find((q) => q.id === item.unitId)
          ?.name,
      };
    }) ?? []
  );
});

const fetchPortfolio = async () => {
  const res = await PORTFOLIO.getUserPortfolio();
  if (!res) {
    handleNoPortfolio();
  } else {
    portfolioDataRef.value = res;
    tokenDistribution.value = calculateTokenDistribution(
      portfolioDataRef.value?.units as PropertyItem[]
    );
    emit("startPortfolio", true);
  }
};

const isPortfolioInDemo = ref(false);

const portfolioDemoObj = reactive({
  balances: [],
  balance: 15000,
  changedPercentage: 10,
  charts: {
    totalInvestment: [],
    distributionByToken: [],
    distributionByPrice: [],
  },
  projectedMonthlyCashflow: 3259,
  tokenGrowthEarning: [],
  units: [],
  unitsCount: 10,
});

const handleDemoStart = async () => {
  useUserStore.$state.portfolioLoading = true;
  const url = getRoute({ endpont: "post_units" });
  const unitAmount = getRandomNumber(1, 7);
  const data = await apiPost({
    url: url,
    body: {
      limit: unitAmount,
      offset: 0,
      status: ["published"],
      "tokenomics.priceUSD": { gte: 1 },
    },
  });
  const response = data?.data?.value as {
    count: number | null;
    rows: PropertyItem[] | null;
  };
  if (!response || !response.rows) {
    useUserStore.$state.portfolioLoading = false;
    return;
  }
  portfolioDemoObj.balance = response.rows.reduce(
    (sum, unit) =>
      sum + unitAmount * parseFloat(unit.tokenomics.priceUSD ?? "1"),
    0
  );
  portfolioDemoObj.changedPercentage = getRandomNumber(1, 100);
  portfolioDemoObj.projectedMonthlyCashflow = getRandomNumber(1, 1000);
  portfolioDemoObj.unitsCount = unitAmount;
  portfolioDemoObj.tokenGrowthEarning = response.rows.map((unit) => ({
    unitId: unit.id,
    balance: unitAmount.toString(),
  }));
  portfolioDemoObj.charts.totalInvestment = generateDataArray(
    response.rows.map((unit) => unit.tokenomics.priceUSD)
  );
  portfolioDemoObj.charts.distributionByToken = response.rows.map((unit) => ({
    unitId: unit.id,
    percentage: getRandomNumber(1, 20).toString(),
    balance: unitAmount.toString(),
  }));
  portfolioDemoObj.units = response.rows;
  portfolioDataRef.value = portfolioDemoObj;
  tokenDistribution.value = calculateTokenDistribution(portfolioDemoObj.units);

  isNoPortfolio.value = false;
  isPortfolioInDemo.value = true;
  useUserStore.$state.portfolioLoading = false;
};

const calculateTokenDistribution = (
  units: PropertyItem[]
): TokenDistribution => {
  const countryMap = new Map<string, CountryTokenData>();
  const providerMap = new Map<string, ProviderTokenData>();

  for (const unit of units) {
    const tokensAmount =
      portfolioDataRef.value?.charts.distributionByToken.find(
        (item) => item.unitId === unit.id
      )?.balance;
    const purchaseAmount =
      parseFloat(unit.tokenomics.priceUSD ?? "0") *
      parseFloat(tokensAmount ?? "1");
    const countryMarker = unit.country?.code ?? unit.countryId;
    if (countryMarker) {
      if (countryMap.has(countryMarker)) {
        countryMap.get(countryMarker)!.totalTokens += purchaseAmount;
      } else {
        countryMap.set(countryMarker, {
          countryId: countryMarker,
          totalTokens: purchaseAmount,
        });
      }
    }

    if (unit.provider) {
      const { id, name } = unit.provider;
      if (providerMap.has(id)) {
        providerMap.get(id)!.totalTokens += purchaseAmount;
      } else {
        providerMap.set(id, {
          id,
          name,
          totalTokens: purchaseAmount,
          logoFile: unit.provider.logoFile?.url,
        });
      }
    }
  }

  return {
    countries: Array.from(countryMap.values()),
    providers: Array.from(providerMap.values()),
  };
};

const generateRandomDate = (start: Date, end: Date): string => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  ).toISOString();
};

const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateDataArray = (prices: (string | undefined)[]): DataItem[] => {
  const startDate = new Date("2025-01-01T00:00:00.000Z");
  const endDate = new Date();

  const dates = Array.from({ length: 10 }, () =>
    generateRandomDate(startDate, endDate)
  )
    .map((date) => new Date(date))
    .sort((a, b) => a.getTime() - b.getTime());

  let total = prices.reduce((sum, price) => sum + parseFloat(price ?? "30"), 0);
  const step = total / dates.length;

  return dates.map((date): DataItem => {
    total += step + (Math.random() - 0.5) * 10;
    total = Math.max(0, total);
    return {
      date: date.toISOString(),
      total: parseFloat(total.toFixed(6)),
    };
  });
};

const handleNoPortfolio = async () => {
  // userTokens.value = await PORTFOLIO.getManualTokens();
  // if (userTokens.value?.length) {
  //   portfolioDataRef.value!.units = [
  //     ...userTokens.value.map((item) => item.unit),
  //   ];
  //   portfolioDataRef.value!.unitsCount = userTokens.value?.length;
  //   portfolioDataRef.value!.balance =
  //     userTokens.value?.reduce(
  //       (sum, item) => sum + item.price * parseFloat(item.balance),
  //       0
  //     ) || 0;
  //     portfolioDataRef.value!.charts = portfolioDemoObj.charts
  //     portfolioDataRef.value!.balances = portfolioDemoObj.balances
  //     portfolioDataRef.value!.tokenGrowthEarning = portfolioDemoObj.tokenGrowthEarning
  // } else {
  //   isNoPortfolio.value = true;
  //   useUserStore.$state.userPortfolio = { null: null };
  // }
  isNoPortfolio.value = true;
  useUserStore.$state.userPortfolio = { null: null };
  useUserStore.$state.portfolioLoading = false;
};

const isDemoPortfolioStarted = ref(false);
const demotoastInst = ref<(props?: QNotifyUpdateOptions) => void>();

const handleStartPortfolioDemo = () => {
  handleDemoStart();
  isDemoPortfolioStarted.value = true;
  const demoToast = new Notification({
    type: "demo-portfolio",
    actions: [
      {
        label: "Back",
        outline: true,
        size: "small",
        color: "white",
        round: false,
        noCaps: true,

        handler: () => {
          isDemoPortfolioStarted.value = false;
          handleNoPortfolio();
          emit("startPortfolio", false);
        },
      },
    ],
  });
  demotoastInst.value = demoToast.createNewToast();

  emit("startPortfolio", true);
};

const debouncedLoginWeb3 = debounce(() => {
  useUserStore.loginUserWeb3();
}, 300);

const initPortfolio = async () => {
  if (!useUserStore.isInSystem) {
    handleNoPortfolio();
  } else {
    fetchPortfolio();
  }
};

onMounted(() => {
  initPortfolio();
});

onBeforeRouteLeave(() => {
  if (demotoastInst.value) {
    demotoastInst.value({ timeout: 100 });
  }
});

defineExpose({ initPortfolio });
</script>

<style scoped lang="scss">
.profile-container__portfolio {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  width: 100%;
  height: fit-content;
  gap: 24px;
  &--nodata {
    .search-no-objects {
      height: 100%;
      gap: 24px;
    }
    .nodata-img {
      scale: 1.04;
    }
    .mobile-no-portfolio {
      display: flex;
      max-width: 345px;
      padding: 24px 24px 40px 24px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      border-radius: 16px;
      background: var(--white-contrast);
      box-shadow: var(--shadow-light);
      grid-column: span 2;

      &__text {
        text-align: center;
      }
    }
  }
  .top-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
  }
  .charts {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
  .cards-grid {
    display: grid;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .profile-container__portfolio {
    max-width: 100dvw;
    nav {
      width: 100%;
      .base-button {
        width: 100%;
      }
    }
    .top-blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      .portfolio__top-block--major {
        grid-column: span 2;
        max-width: 100%;
      }
      // flex-direction: column;
    }
    .charts {
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>

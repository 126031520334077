<template>
  <div
    :class="[
      'portfolio__top-block',
      { 'portfolio__top-block--major': props.isMajot },
    ]"
  >
    <div class="info">
      <p
        class="text-bold info-title"
        :class="[textSubtitle ? 'text-subtitle' : 'text-head-2 text-sora']"
      >
        {{ props.title }}
      </p>
      <span class="info__icon">
        <p class="text-body-2">{{ props.subtitle }}</p>
        <base-tooltip
          v-if="props.isInfoIcon"
          :tooltip-text="$t('profile.portfolio_cashflow')"
          style="margin-bottom: 1px"
        >
          <template #icon>
            <suspense>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </suspense> </template
        ></base-tooltip>
      </span>
    </div>
    <div v-if="props.isBadge" class="badge">
      <base-icon
        :name="`base/${props.isUp ? 'TrendUp' : 'TrendDown'}`"
        size="20px"
        :color="
          props.isUp ? 'var( --positive-secondary)' : 'var(--negative-sec)'
        "
      />
      <span
        class="text-body-2 badge__value"
        :class="{ 'badge__value--up': props.isUp }"
        ><span>{{ props.isUp ? "+" : "" }}</span>
        {{ formatPercentage(props.badgeValue) }}%</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatPercentage } from "~/utilities/helpers/format-data/number";

interface IProps {
  title: string;
  subtitle: string;
  isBadge?: boolean;
  isUp?: boolean;
  badgeValue?: number;
  isMajot?: boolean;
  textSubtitle?: boolean;
  isInfoIcon?: boolean;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.portfolio__top-block {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: var(--shadow-light);
  width: 100%;
  max-width: 316px;
  height: 100%;
  min-height: 106px;
  cursor: default;
  border: 1px solid var(--gray-monochrome-40);
  background-color: var(--white-monochrome);
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
  &--major {
    background-color: var(--violet-main);
    p{
      color: var(--white-contrast);
    }
    .info {
      gap: 15px;
    }
    .badge{
      background-color: var(--white-contrast)
    }
  }

  .badge {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 30px;
    border: 1px solid var(--gray-monochrome-40);
    margin-bottom: auto;
    &__value {
      color: var(--negative-sec);
      &--up {
        color: var(--positive-secondary);
      }
    }
  }
}
</style>

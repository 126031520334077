<template>
  <div class="img-preview">
    <button v-close-popup class="close-butt hover-rotate">
      <Suspense>
        <base-icon name="base/Close" size="100%" />
      </Suspense>
    </button>
    <img :src="props.data" alt="" style="max-height: 100%" >
  </div>
</template>

<script setup lang="ts">
interface IProps {
  data: string;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.img-preview {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  .close-butt {
    @include drop-button-styles;
    position: absolute;
    right: 26px;
    top: 26px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background: var(--Monochrome-White, #e9ebf8);
  }
}
</style>

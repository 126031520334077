<template>
  <section class="gm-map" >
    <base-error-backdrop v-if="isMapError" />
    <base-loader v-if="isLoader" hint="Map is loading..." />
    <div id="gmmap" ref="google_map" style="height: 100vh" v-bind="$attrs" />
  </section>
</template>

<script setup lang="ts">
import {
  useGoogleMapHTML,
  useGoogleMapFactory,
} from "~/utilities/composables/useGoogleMaps";

import type { AppContext } from "vue";
import { Api } from "~/services/swagger/Api";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";

definePageMeta({
  layout: "map-search",
});

interface EstateItem {
  estate: UpdatedUnitResponseDTO;
  size: "suggestions";
  propertyType: string;
  position: any;
}

const apiClient = new Api();
const route = useRoute();

const config = useRuntimeConfig();

const google_map = ref<HTMLElement | null>(null);
const isMapError = ref(false);
const loadCounter = ref(0);
const isLoader = ref(false);
const estates = ref<EstateItem[]>([]);

const loadMap = async () => {
  if (!google_map.value) {
    isMapError.value = true;
    return;
  }
  loadCounter.value++;
  useGoogleMapHTML().value = google_map.value;
  const { vueApp } = useNuxtApp();
  const appContext = vueApp._context as AppContext;
  const map = await useGoogleMapFactory({
    mapHTML: google_map.value,
    type: "dynamic",
    points: estates.value,
    appContext,
    options: {
      noControls: true,
      isInfo: true,
      customControls: true,
    },
  });

  isLoader.value = false;
  if (!map) {
    isMapError.value = true;
    function reCall() {
      if (loadCounter.value < 3) {
        setTimeout(() => {
          loadMap();
        }, 4000);
      } else return;
    }
    reCall();
  } else {
    isMapError.value = false;
  }
};

const fetchMapScript = () => {
  const { onLoaded } = useScriptGoogleMaps({
    apiKey: config.public.gmAPI,
  });

  isLoader.value = true;
  onLoaded(async (instance) => {
    (await instance.maps) as any as typeof google.maps;
    await fetchEstates();
    loadMap();
  });
};

const fetchEstates = async () => {
  const response = await apiClient.api.unitsControllerListPost({...route.query, limit: 1000});

  if (!response.data?.rows) return [];
  const estatesRaw = response.data as unknown as {
    rows: UpdatedUnitResponseDTO[];
  };

  const filteredData = estatesRaw.rows.filter(
    (item) => !(Array.isArray(item.coords) && item.coords.length === 0)
  );

  estates.value = filteredData.map((item) => {
    return {
      estate: {
        ...item,
      },
      size: "suggestions",
      position: new google.maps.LatLng({
        lat: item.coords![0],
        lng: item.coords![1],
      }),
      propertyType: "estates",
    };
  });
};

onMounted(async () => {
  fetchMapScript();
});
</script>

<style scoped lang="scss">
.gm-map {
  position: relative;
  height: calc(100dvh - 66px);
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

#gmmap {
  width: 100%;
}
</style>

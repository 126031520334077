<template>
  <section
    class="photo__wall"
    :class="{ 'no-swiper': !leftPhotoColumn || !leftPhotoColumn?.length }"
  >
    <base-search-card-sold-out-tag v-if="props.sold" />

    <base-loader v-if="isLoader" />
    <div
      v-if="leftPhotoColumn && leftPhotoColumn.length"
      class="photo__wall-secondary"
      @mouseenter="toggleTouchIcon"
      @mouseleave="toggleTouchIcon"
    >
      <div
        class="touch-container"
        :class="{ 'touch-container--hidden': !isTouchIcon }"
      >
        <img src="~/assets/img/photo-wall/touch_icon.svg" alt="touch_icon" >
      </div>
      <swiper
        :modules="[Navigation, Autoplay]"
        :slides-per-view="4"
        :space-between="1"
        :autoplay="{
          delay: 2000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }"
        :lazy="true"
        :grab-cursor="true"
        :direction="'vertical'"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(img, index) in leftPhotoColumn"
          :key="index"
          @click="swapPhotos(index)"
        >
          <span class="img-conteiner">
            <NuxtImg
              :src="img.small"
              alt="property_img"
              class="animate__animated animate__fadeIn"
              draggable="false"
              @click.prevent
              @load="onImageLoad"
              @error="onImageError(index)"
            />
          </span>
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="photo__wall-major"
      :class="{ 'no-swiper': !leftPhotoColumn || !leftPhotoColumn?.length }"
    >
      <span class="img-major" @click="openGallery">
        <NuxtImg
          :src="majorPhoto?.big"
          alt="property_img"
          class="animate__animated"
          :class="{ animate__fadeIn: isSwaped }"
          draggable="false"
          @click.prevent
          @load="onImageLoad"
          @error="onImageError(-1)"
        />
      </span>
      <div class="top-butts">
        <base-tag v-if="props.tag" :variant="props.tag" />
        <span
          v-if="partnerSrc"
          v-show="!partnerLogoError"
          class="issuer-logo"
          @click="handleProviderClick"
        >
          <img
            v-image-error="{
              onImageError: hideParnterLogo,
              onImageLoad: showPartnerLogo,
            }"
            :data-src="partnerSrc"
            alt="logo"
          >
        </span>
        <base-search-card-property-tag
        :property-subtype="props.subtype"
        :is-round="$device.isMobile"
      />
      </div>
      <button class="view-all-butt font--b5" @click="openGallery">
        {{ $t("unit_page.un_v_all") }}
        <suspense>
          <base-icon :filled="true" name="base/puzzle" size="16px" />
        </suspense>
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { FileResponseDTO, TokenIssuerDTO, UnitResponseDTO } from "~/services/swagger/Api";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";

interface Props {
  imgArray: Partial<FileResponseDTO>[];
  tag: string;
  issuer: TokenIssuerDTO | undefined;
  sold: boolean;
  providerId: string | undefined | null;
  subtype?:UnitResponseDTO["subtype"]
}

interface ThumbsImg {
  big: string;
  small: string;
  origin: string;
}

const localePath = useLocalePath();

const props = withDefaults(defineProps<Props>(), {
  imgArray: () => [],
});

const computedImgs = computed(() => props.imgArray);

const callPopUp = PopUpServices();

const swiperInst = ref<typeof Swiper | null>(null);

const leftPhotoColumn = ref<ThumbsImg[]>();

const sortedImgArray = (arr: Partial<FileResponseDTO>[]) => {
  const newArr: ThumbsImg[] = arr.map((item) => {
    return {
      small: getThumbnail(item, "thumbnail_130x100"),
      big: getThumbnail(item, "thumbnail_470x370"),
      origin: item.url || "",
    };
  });
  return newArr;
};

const majorPhoto = ref<ThumbsImg>();
const isSwaped = ref(false);
const isLoader = ref(true);
const isTouchIcon = ref(true);
let loadedImagesCount = 0;
const partnerLogoError = ref(true);
const majorImgIndex = ref(0);

const hideParnterLogo = () => {
  partnerLogoError.value = true;
};
const showPartnerLogo = () => {
  partnerLogoError.value = false;
};

const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};

const cutLeftColumnPhotos = (arr?: ThumbsImg[]) => {
  if (!arr || !Array.isArray(arr)) return;
  leftPhotoColumn.value = arr;
};

const cutMajorPhoto = (arr?: ThumbsImg[]) => {
  if (!arr || !Array.isArray(arr)) return;
  majorPhoto.value = arr.at(-1);
  majorImgIndex.value = arr.length - 1;
};

const swapPhotos = (index: number) => {
  if (
    leftPhotoColumn.value &&
    leftPhotoColumn.value[index] &&
    majorPhoto.value
  ) {
    isSwaped.value = !isSwaped.value;
    majorPhoto.value.big = leftPhotoColumn.value[index].big;
    majorImgIndex.value = index;
    setTimeout(() => {
      isSwaped.value = !isSwaped.value;
    }, 500);
  }
};

const toggleTouchIcon = () => {
  isTouchIcon.value = !isTouchIcon.value;
};

const onImageLoad = () => {
  loadedImagesCount++;
  if (loadedImagesCount === props.imgArray.length) {
    isLoader.value = false;
  }
};
const onImageError = (index: number) => {
  return () => {
    if (index === -1) {
      majorPhoto.value = { big: "", small: "", origin: "" };
    } else {
      leftPhotoColumn.value?.splice(index, 1);
    }
    loadedImagesCount++;
    if (loadedImagesCount === props.imgArray.length) {
      isLoader.value = false;
    }
  };
};

const openGallery = (imgIndex: number) => {
  callPopUp.callPopUp({
    componentName: "base-gallery",
    maximized: true,
    data: {
      imgs: computedImgs.value.map((q) => q.url),
      pickedPhotoIndex: majorImgIndex.value,
    },
  });
};

const partnerSrc = computed(() => {
  return props.issuer?.logoFile?.url;
});

const handleProviderClick = () => {
  const providerId = props.providerId;
  if (!providerId) return;

  const route = localePath(`/directory?providerIds[0]=${providerId}`);
  window.open(window.location.origin + route, "_blank");
};

onMounted(() => {
  const newArr = sortedImgArray(computedImgs.value);
  cutLeftColumnPhotos(newArr);
  cutMajorPhoto(newArr);
});
</script>

<style scoped lang="scss">
.photo__wall {
  position: relative;
  display: grid;
  grid-template-columns: 126px 467px;
  gap: 24px;
  justify-content: flex-end;
  width: 100%;
  max-width: 652px;
  height: 400px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: var(--shadow-light);
  background: var(--white-contrast);
  overflow: hidden;

  &-secondary {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .touch-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      &--hidden {
        z-index: -1;
        opacity: 0;
      }

      img {
        width: 40%;
      }
    }
    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img-conteiner {
        display: block;
        border-radius: 12px;
        width: 129px;
        height: 80px;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &-major {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 12px;
    overflow: hidden;
    .img-major {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .view-all-butt {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 109px;
      height: 36px;
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 40px;
      background: var(--white-monochrome);
      box-shadow: var(--shadow-light);
      align-items: center;
      margin: 0;
      padding: 0;
    }
    .top-butts {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      .issuer-logo {
        display: flex;
        height: 32px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        max-width: 110px;
        border-radius: 40px;
        border: 1px solid var(--gray-40);
        background: var(--white-contrast);
        cursor: pointer;
        img {
          height: 20px;
          width: 100%;
        }
      }
    }
  }
}
.no-swiper {
  // padding-left: 11px;
  grid-template-columns: 1fr;
}
</style>

<template>
  <section
    v-if="suggArray && Array.isArray(suggArray) && suggArray.length"
    class="base-suggested-estates"
  >
    <p class="text-subtitle text-bold text-black-monochrome">{{ getLabel }}</p>
    <div v-if="!$device.isMobile" class="suggested-container">
      <base-search-card-new
        v-for="item in suggArray.slice(0, 3)"
        :estate="item"
        size="suggestions"
        :property-type="props.type"
      />
    </div>
    <div v-else class="suggested-swiper">
      <swiper
        :modules="[Navigation, Pagination]"
        :slides-per-view="1"
        :space-between="1"
        loop
        allow-touch-move
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(item, index) in suggArray.slice(0, 3)"
          :key="index"
        >
          <base-search-card-new
            :estate="item"
            size="suggestions"
            :property-type="props.type"
          />
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { PropertyTypes } from "~/components/base/navigator/types";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";

interface IProps {
  estates: UpdatedUnitResponseDTO[];
  type: PropertyTypes;
}

const props = defineProps<IProps>();

const { t } = useI18n();

const suggArray = computed(() => {
  if (props.estates) {
    return props.estates.map((item) => {
      return {
        ...item,
        token: {
          ...item.token,
          issuer: {
            logo: item.provider?.logo,
            name: item.provider?.name,
            url: item.provider?.url,
          },
        },
      };
    });
  } else {
    return [];
  }
});

const getLabel = computed(() => {
  switch (props.type) {
    case "estates":
      return t("unit_page.un_sugg_estate");
    case "lands":
      return t("unit_page.un_sugg_land");
    default:
      return t("unit_page.un_sugg_estate");
  }
});

const swiperInst = ref<typeof Swiper | null>(null);
const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};
</script>

<style scoped lang="scss">
.base-suggested-estates {
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  gap: 32px;
  max-width: 990px;
  margin: auto;
  p {
    justify-self: center;
  }
  .suggested-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .suggested-swiper {
    position: relative;
    width: 100%;
    max-width: 382px;
    height: 470px;
    margin: auto;
    &--placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
    }
    .card-size-suggestions {
      height: 445px;
    }
    .swiper {
      width: 100%;
      max-width: 314px;
      height: 460px;
      :deep(.swiper-pagination-bullet-active) {
        width: 16px;
        border-radius: 6px;
      }

      &-active-index {
        position: absolute;
        bottom: 35px;
        right: 9px;
        z-index: 3;
        min-width: 32px;
        background-color: var(--black-monochrome-60);
        padding: 8px;
        border-radius: 8px;
      }
      --swiper-pagination-bottom: -5px;
      --swiper-pagination-bullet-size: 6px;
      --swiper-pagination-bullet-inactive-color: var(--gray-monochrome-40);
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-vertical-gap: 0px;
      --swiper-pagination-bullet-horizontal-gap: 2px;
      --swiper-pagination-color: var(--black-monochrome-60);
    }
  }
}
@media (max-width: 59rem) {
  .base-suggested-estates {
    overflow: scroll;
    .suggested-container {
      overflow: scroll;
    }
  }
}
</style>

<template>
  <section class="portfolio__card">
    <div class="property">
      <div class="property__badges">
        <span class="partner-logo">
          <q-img
            width="60px"
            height="14px"
            :src="props.unit?.provider?.logoFile?.url"
            fit="cover"
            lazy
          />
        </span>
        <span class="stage-badge" :style="{ borderColor: stageBadge.color }">
          <base-icon :name="`base/${stageBadge.icon}`" size="8px" filled />
          <p class="font--b6" :style="{ color: stageBadge.color }">
            {{ stageBadge.title }}
          </p>
        </span>
        <div class="metrics">
          <span class="metrics__item">
            <p class="font--b6">APR</p>
            <p class="font--b6">{{ formatPercentage(props.unit?.apr) }}%</p>
          </span>
          <span class="metrics__item">
            <p class="font--b6">IRR</p>
            <p class="font--b6">{{ formatPercentage(props.unit?.irr) }}%</p>
          </span>
        </div>
      </div>
      <div class="property__img">
        <q-img
          class="img"
          width="140px"
          height="72px"
          :src="unitImg || noImage"
          format="webp"
          lazy
        />
        <div class="info-amount">
          <span class="info-amount__avail">
            <p class="font--b1-2">
              {{ BASE_CURRENCY.symbol + formatPrice(1213240, true, true) }}
            </p>
            <p class="font--b5-5 text-positive-secondary">
              +{{ formatPercentage(12) }}%
            </p>
          </span>
          <span class="info-amount__token">
            <base-icon
              size="20px"
              color="var(--black-monochrome-60)"
              name="chain/all"
            />
            <p class="font--b4-semi-bold text-black-monochrome-60">
              24,000 tokens
            </p>
            <base-icon
              size="20px"
              color="var(--black-monochrome-60)"
              name="base/DownRight"
              rot="270deg"
            />
          </span>
        </div>
      </div>

      <div class="property__info">
        <div class="info-title">
          <div class="info-title__title">
            <p class="font--b3-m">{{ props.unit?.name }}</p>
            <span class="country-flexbox">
              <base-icon
                size="16px"
                filled
                :name="`flags/${props.unit?.country?.name}`"
              />
              <p class="font--b6 text-black-monochrome-60">
                {{ props.unit?.country?.name || "Not provided" }}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="investment">
      <div class="investment__metrics">
        <span class="metric-item">
          <p class="font--b4-semi-bold">
            {{ BASE_CURRENCY.symbol + formatPrice(tokenGrowthEarning, true, true) }}
          </p>
          <p class="font--b5-3 text-black-monochrome-60">
            Token growth earning
          </p>
        </span>
        <span class="metric-item">
          <p class="font--b4-semi-bold">
            {{ BASE_CURRENCY.symbol + formatPrice(240000, true, true) }}
          </p>
          <p class="font--b5-3 text-black-monochrome-60">
            Rental income earning
          </p>
        </span>
        <span class="metric-item">
          <span class="font--b4-semi-bold metric-item__flex">
            {{ BASE_CURRENCY.symbol + formatPrice(240000, true, true) }}

            <p class="font--b5-5 text-positive-secondary">
              +{{ formatPercentage(12) }}%
            </p>
          </span>
          <p class="font--b5-3 text-black-monochrome-60">
            Profitability projection
          </p>
        </span>
      </div>
      <nav class="investment__controls">
        <base-button
          variant="dark_ghost"
          text="Details"
          size="xss"
          @click.stop="goToUnit"
        />
        <base-button
          variant="prime"
          text=" Buy more tokens"
          size="xss"
          @click="goToProvider"
        />
      </nav>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import {
  formatPercentage,
  formatPrice,
} from "~/utilities/helpers/format-data/number";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import noImage from "~/assets/img/no-image.svg";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import type { PortfolioDTO } from "~/services/swagger/Api";

interface IProps {
  unit: UpdatedUnitResponseDTO;
  portfolio: PortfolioDTO;
}

const props = defineProps<IProps>();

const tokenGrowthEarning = computed(
  () =>
    props.portfolio.tokenGrowthEarning.find(
      (item) => item.unitId === props.unit.id
    )?.balance ?? 0
);

const { t } = useI18n();
const localePath = useLocalePath();
const stageBadge = computed(() => {
  const stage = getStage(props.unit?.stage);
  return {
    title: stage.title,
    color: stage.color,
    icon: stage.icon,
  };
});

const getStage = (stage: typeof props.unit.stage) => {
  switch (stage) {
    case "complete":
      return {
        title: t("search_filters.fr_stages.complete"),
        icon: "StageDotGreen",
        color: "var(--green-600)",
      };
    case "in_progress":
      return {
        title: t("search_filters.fr_stages.progress"),
        color: "var(--orange-sec)",
        icon: "StageDotOrange",
      };
    default:
      return {
        title: t("search_filters.fr_stages.progress"),
        color: "var(--orange-sec)",
        icon: "StageDotOrange",
      };
  }
};

const goToUnit = () => {
  if (!props.unit?.externalUrl) return;
  navigateTo(localePath(`/property/${props.unit.externalUrl}`));
};

const goToProvider = () => {
  redirectByProviderName({
    providerName: props.unit?.provider?.name,
    providerLink: props.unit?.provider?.url,
    utmLink: props.unit?.referralLink?.link,
    externalUrl: props.unit?.externalUrl,
    logoFile: props.unit?.provider?.logoFile?.url
  });
};

const unitImg = computed(
  () =>
    props.unit?.files?.find(
      (item) => item.type === "covers" || item.type === "image"
    )?.file?.url
);
</script>

<style scoped lang="scss">
.portfolio__card {
  @include mobile-fonts;
  display: flex;
  height: 340px;
  max-width: 345px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: 0px 0px 6.8px 0px rgba(0, 0, 0, 0.08);
  .property {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    width: 100%;
    flex-direction: column;
    &__badges {
      display: flex;
      align-items: flex-start;
      gap:8px;
      .partner-logo {
        display: flex;
        height: 24px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 40px;
        border: 1px solid var(--gray-monochrome-40);
        background: transparent;
        cursor: default;
      }
      .stage-badge {
        display: flex;
        height: 24px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 18px;
        border: 1px solid transparent;
        background: var(--white-contrast);
        cursor: default;
      }
      .metrics {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        &__item {
          display: flex;
          height: 24px;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 8px;
          border: 1px solid var(--gray-monochrome-40);
          background: var(--white-monochrome);
          cursor: default;
        }
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .img {
        border-radius: 8px;
      }
      .info-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        cursor: default;

        &__avail {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }
        &__token {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
        }
      }
    }
    &__info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .info-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        &__title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          .country-flexbox {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: default;
          }
        }
      }
    }
  }
  .investment {
    display: flex;
    padding: 12px 8px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: 8px;
    border: 0.7px solid var(--gray-monochrome-40);
    background: var(--white-monochrome);
    width: 100%;
    &__metrics {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      .metric-item {
        display: grid;
        align-items: center;
        justify-items: flex-start;
        cursor: default;

        &__flex {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }
    &__controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      .base-button {
        width: 100%;
      }
    }
  }
}
</style>

const web3Translations = {
  en: {
    connect: {
      selectingWallet: {
        header: "Available Wallets",
        sidebar: {
          heading: "",
          subheading: "Connect Your Web3 Wallet",
          paragraph:
            "To access and fully use all features of the RWA Estate project, please connect your Web3 wallet. Once connected, you’ll be able to explore, invest, and manage your real-world asset holdings seamlessly.",
        },
        installWallet:
          "You do not have any wallets installed that {app} supports, please use a supported wallet",
        agreement: {
          agree: "I agree to the",
          terms: "Terms & Conditions",
          and: "and",
          privacy: "Privacy Policy",
        },
        whyDontISeeMyWallet: "Why don't I see my wallet?",
        learnMore: "Click here to learn more",
      },
      connectingWallet: {
        header:
          "{connectionRejected, select, false {Connecting to {wallet}...} other {Connection Rejected}}",
        sidebar: {
          subheading: "Approve Connection",
          paragraph:
            "Please approve the connection in your wallet and authorize access to continue.",
        },
        mainText: "Connecting...",
        paragraph:
          "Make sure to select all accounts that you want to grant access to.",
        previousConnection:
          "{wallet} already has a pending connection request, please open the {wallet} app to login and connect.",
        rejectedText: "Connection Rejected!",
        rejectedCTA: "Click here to try again",
        primaryButton: "Back to wallets",
      },
      connectedWallet: {
        header: "Connection Successful",
        sidebar: {
          subheading: "Connection Successful!",
          paragraph: "Your wallet is now connected to {app}",
        },
        mainText: "Connected",
        accountSelected: "Account Selected",
        availableWallet: "available wallet",
      },
    },
    modals: {
      actionRequired: {
        heading: "Action required in {wallet}",
        paragraph: "Please switch the active account in your wallet.",
        linkText: "Learn more.",
        buttonText: "Okay",
      },
      switchChain: {
        heading: "Switch Chain",
        paragraph1:
          "{app} requires that you switch your wallet to the {nextNetworkName} network to continue.",
        paragraph2:
          "*Some wallets may not support changing networks. If you can not change networks in your wallet you may consider switching to a different wallet.",
      },
      confirmDisconnectAll: {
        heading: "Disconnect all Wallets",
        description:
          "Are you sure that you would like to disconnect all your wallets?",
        confirm: "Confirm",
        cancel: "Cancel",
      },
      confirmTransactionProtection: {
        heading: "Enable Transaction Protection",
        description:
          "Protect RPC endpoints hide your transaction from front-running and sandwich bots.",
        link: "Learn more",
        enable: "Enable",
        dismiss: "Dismiss",
      },
    },
    accountCenter: {
      connectAnotherWallet: "Connect another Wallet",
      disconnectAllWallets: "Disconnect all Wallets",
      currentNetwork: "Current Network",
      enableTransactionProtection: "Enable Transaction Protection",
      appInfo: "App Info",
      learnMore: "Learn More",
      gettingStartedGuide: "Getting Started Guide",
      smartContracts: "Smart Contract(s)",
      explore: "Explore",
      poweredBy: "powered by",
      addAccount: "Add Account",
      setPrimaryAccount: "Set Primary Account",
      disconnectWallet: "Disconnect Wallet",
      copyAddress: "Copy Wallet address",
    },
  },
  ru: {
    connect: {
      selectingWallet: {
        header: "Доступные кошельки",
        sidebar: {
          heading: "",
          subheading: "Подключите свой Web3 кошелёк",
          paragraph:
            "Чтобы получить доступ и полноценно использовать все функции проекта RWA Estate, подключите свой Web3 кошелёк. После подключения вы сможете исследовать, инвестировать и управлять своими активами реального мира без проблем.",
        },
        installWallet:
          "У вас нет установленных кошельков, поддерживаемых {app}, пожалуйста, используйте поддерживаемый кошелёк",
        agreement: {
          agree: "Я согласен с",
          terms: "Условиями использования",
          and: "и",
          privacy: "Политикой конфиденциальности",
        },
        whyDontISeeMyWallet: "Почему я не вижу свой кошелёк?",
        learnMore: "Нажмите здесь, чтобы узнать больше",
      },
      connectingWallet: {
        header:
          "{connectionRejected, select, false {Подключение к {wallet}...} other {Подключение отклонено}}",
        sidebar: {
          subheading: "Одобрите подключение",
          paragraph:
            "Пожалуйста, подтвердите подключение в вашем кошельке и авторизуйте доступ для продолжения.",
        },
        mainText: "Подключение...",
        paragraph:
          "Убедитесь, что выбрали все аккаунты, которым хотите предоставить доступ.",
        previousConnection:
          "{wallet} уже имеет ожидающий запрос на подключение, откройте приложение {wallet}, чтобы войти и подключиться.",
        rejectedText: "Подключение отклонено!",
        rejectedCTA: "Нажмите здесь, чтобы попробовать снова",
        primaryButton: "Назад к кошелькам",
      },
      connectedWallet: {
        header: "Подключение успешно",
        sidebar: {
          subheading: "Подключение успешно!",
          paragraph: "Ваш кошелёк теперь подключён к {app}",
        },
        mainText: "Подключено",
        accountSelected: "Выбран аккаунт",
        availableWallet: "доступный кошелёк",
      },
    },
    modals: {
      actionRequired: {
        heading: "Необходимо действие в {wallet}",
        paragraph: "Пожалуйста, смените активный аккаунт в вашем кошельке.",
        linkText: "Узнать больше.",
        buttonText: "Ок",
      },
      switchChain: {
        heading: "Сменить сеть",
        paragraph1:
          "{app} требует, чтобы вы переключили свой кошелёк на сеть {nextNetworkName} для продолжения.",
        paragraph2:
          "*Некоторые кошельки могут не поддерживать изменение сетей. Если вы не можете сменить сеть в своём кошельке, рассмотрите возможность использования другого кошелька.",
      },
      confirmDisconnectAll: {
        heading: "Отключить все кошельки",
        description: "Вы уверены, что хотите отключить все свои кошельки?",
        confirm: "Подтвердить",
        cancel: "Отмена",
      },
      confirmTransactionProtection: {
        heading: "Включить защиту транзакций",
        description:
          "Защищайте RPC-эндпоинты, скрывая свои транзакции от атак ботов.",
        link: "Узнать больше",
        enable: "Включить",
        dismiss: "Отклонить",
      },
    },
    accountCenter: {
      connectAnotherWallet: "Подключить другой кошелёк",
      disconnectAllWallets: "Отключить все кошельки",
      currentNetwork: "Текущая сеть",
      enableTransactionProtection: "Включить защиту транзакций",
      appInfo: "Информация о приложении",
      learnMore: "Узнать больше",
      gettingStartedGuide: "Руководство по началу работы",
      smartContracts: "Смарт-контракты",
      explore: "Исследовать",
      poweredBy: "работает на",
      addAccount: "Добавить аккаунт",
      setPrimaryAccount: "Установить основной аккаунт",
      disconnectWallet: "Отключить кошелёк",
      copyAddress: "Скопировать адрес кошелька",
    },
  },
  de: {
    connect: {
      selectingWallet: {
        header: "Verfügbare Wallets",
        sidebar: {
          heading: "",
          subheading: "Verbinden Sie Ihre Web3-Brieftasche",
          paragraph:
            "Um auf alle Funktionen des RWA Estate-Projekts zuzugreifen und diese vollständig zu nutzen, verbinden Sie bitte Ihre Web3-Brieftasche. Nach der Verbindung können Sie nahtlos Ihre echten Vermögenswerte erkunden, investieren und verwalten.",
        },
        installWallet:
          "Sie haben keine Wallets installiert, die von {app} unterstützt werden. Bitte verwenden Sie eine unterstützte Wallet.",
        agreement: {
          agree: "Ich stimme den",
          terms: "Nutzungsbedingungen",
          and: "und",
          privacy: "Datenschutzbestimmungen zu",
        },
        whyDontISeeMyWallet: "Warum sehe ich meine Brieftasche nicht?",
        learnMore: "Klicken Sie hier, um mehr zu erfahren",
      },
      connectingWallet: {
        header:
          "{connectionRejected, select, false {Verbindung zu {wallet} wird hergestellt...} other {Verbindung abgelehnt}}",
        sidebar: {
          subheading: "Verbindung bestätigen",
          paragraph:
            "Bitte bestätigen Sie die Verbindung in Ihrer Wallet und autorisieren Sie den Zugriff, um fortzufahren.",
        },
        mainText: "Verbindung wird hergestellt...",
        paragraph:
          "Stellen Sie sicher, dass Sie alle Konten auswählen, denen Sie Zugriff gewähren möchten.",
        previousConnection:
          "{wallet} hat bereits eine ausstehende Verbindungsanforderung. Bitte öffnen Sie die {wallet}-App, um sich anzumelden und zu verbinden.",
        rejectedText: "Verbindung abgelehnt!",
        rejectedCTA: "Klicken Sie hier, um es erneut zu versuchen",
        primaryButton: "Zurück zu den Wallets",
      },
      connectedWallet: {
        header: "Verbindung erfolgreich",
        sidebar: {
          subheading: "Verbindung erfolgreich!",
          paragraph: "Ihre Wallet ist jetzt mit {app} verbunden.",
        },
        mainText: "Verbunden",
        accountSelected: "Konto ausgewählt",
        availableWallet: "verfügbare Wallet",
      },
    },
    modals: {
      actionRequired: {
        heading: "Aktion erforderlich in {wallet}",
        paragraph: "Bitte wechseln Sie das aktive Konto in Ihrer Wallet.",
        linkText: "Mehr erfahren.",
        buttonText: "Okay",
      },
      switchChain: {
        heading: "Kette wechseln",
        paragraph1:
          "{app} erfordert, dass Sie Ihre Wallet auf das {nextNetworkName}-Netzwerk umschalten, um fortzufahren.",
        paragraph2:
          "*Einige Wallets unterstützen möglicherweise das Wechseln von Netzwerken nicht. Wenn Sie Ihr Netzwerk in Ihrer Wallet nicht ändern können, können Sie in Erwägung ziehen, eine andere Wallet zu verwenden.",
      },
      confirmDisconnectAll: {
        heading: "Alle Wallets trennen",
        description:
          "Sind Sie sicher, dass Sie alle Ihre Wallets trennen möchten?",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
      },
      confirmTransactionProtection: {
        heading: "Transaktionsschutz aktivieren",
        description:
          "Schützen Sie RPC-Endpunkte, indem Sie Ihre Transaktionen vor Front-Running- und Sandwich-Bots verbergen.",
        link: "Mehr erfahren",
        enable: "Aktivieren",
        dismiss: "Ablehnen",
      },
    },
    accountCenter: {
      connectAnotherWallet: "Eine andere Wallet verbinden",
      disconnectAllWallets: "Alle Wallets trennen",
      currentNetwork: "Aktuelles Netzwerk",
      enableTransactionProtection: "Transaktionsschutz aktivieren",
      appInfo: "App-Info",
      learnMore: "Mehr erfahren",
      gettingStartedGuide: "Einsteigerleitfaden",
      smartContracts: "Smart Contracts",
      explore: "Erforschen",
      poweredBy: "powered by",
      addAccount: "Konto hinzufügen",
      setPrimaryAccount: "Hauptkonto festlegen",
      disconnectWallet: "Wallet trennen",
      copyAddress: "Wallet-Adresse kopieren",
    },
  },
  es: {
    connect: {
      selectingWallet: {
        header: "Carteras Disponibles",
        sidebar: {
          heading: "",
          subheading: "Conecta tu Billetera Web3",
          paragraph:
            "Para acceder y utilizar todas las funciones del proyecto RWA Estate, por favor conecta tu billetera Web3. Una vez conectada, podrás explorar, invertir y gestionar tus activos del mundo real sin problemas.",
        },
        installWallet:
          "No tienes ninguna billetera instalada que {app} admita, por favor usa una billetera compatible.",
        agreement: {
          agree: "Estoy de acuerdo con los",
          terms: "Términos y Condiciones",
          and: "y",
          privacy: "Política de Privacidad",
        },
        whyDontISeeMyWallet: "¿Por qué no veo mi billetera?",
        learnMore: "Haz clic aquí para obtener más información",
      },
      connectingWallet: {
        header:
          "{connectionRejected, select, false {Conectando a {wallet}...} other {Conexión Rechazada}}",
        sidebar: {
          subheading: "Aprobar Conexión",
          paragraph:
            "Por favor, aprueba la conexión en tu billetera y autoriza el acceso para continuar.",
        },
        mainText: "Conectando...",
        paragraph:
          "Asegúrate de seleccionar todas las cuentas a las que deseas otorgar acceso.",
        previousConnection:
          "{wallet} ya tiene una solicitud de conexión pendiente, por favor abre la aplicación {wallet} para iniciar sesión y conectarte.",
        rejectedText: "¡Conexión Rechazada!",
        rejectedCTA: "Haz clic aquí para intentarlo de nuevo",
        primaryButton: "Volver a las billeteras",
      },
      connectedWallet: {
        header: "Conexión Exitosa",
        sidebar: {
          subheading: "¡Conexión Exitosa!",
          paragraph: "Tu billetera ahora está conectada a {app}.",
        },
        mainText: "Conectado",
        accountSelected: "Cuenta Seleccionada",
        availableWallet: "billetera disponible",
      },
    },
    modals: {
      actionRequired: {
        heading: "Acción requerida en {wallet}",
        paragraph: "Por favor, cambia la cuenta activa en tu billetera.",
        linkText: "Más información.",
        buttonText: "Aceptar",
      },
      switchChain: {
        heading: "Cambiar Red",
        paragraph1:
          "{app} requiere que cambies tu billetera a la red {nextNetworkName} para continuar.",
        paragraph2:
          "*Algunas billeteras pueden no admitir el cambio de red. Si no puedes cambiar de red en tu billetera, considera cambiar a otra billetera.",
      },
      confirmDisconnectAll: {
        heading: "Desconectar Todas las Billeteras",
        description:
          "¿Estás seguro de que deseas desconectar todas tus billeteras?",
        confirm: "Confirmar",
        cancel: "Cancelar",
      },
      confirmTransactionProtection: {
        heading: "Habilitar Protección de Transacciones",
        description:
          "Proteger los endpoints RPC oculta tu transacción de bots de front-running y sandwich.",
        link: "Más información",
        enable: "Habilitar",
        dismiss: "Descartar",
      },
    },
    accountCenter: {
      connectAnotherWallet: "Conectar otra Billetera",
      disconnectAllWallets: "Desconectar Todas las Billeteras",
      currentNetwork: "Red Actual",
      enableTransactionProtection: "Habilitar Protección de Transacciones",
      appInfo: "Información de la Aplicación",
      learnMore: "Más información",
      gettingStartedGuide: "Guía de Inicio",
      smartContracts: "Contrato(s) Inteligente(s)",
      explore: "Explorar",
      poweredBy: "desarrollado por",
      addAccount: "Añadir Cuenta",
      setPrimaryAccount: "Establecer Cuenta Principal",
      disconnectWallet: "Desconectar Billetera",
      copyAddress: "Copiar Dirección de la Billetera",
    },
  },
};
export default web3Translations;

<template>
  <section class="base-leave-review__block">
    <div
      v-if="(!unitReviews || !unitReviews?.length) && !isFiltersActive"
      class="base-leave-review__block--noreview"
    >
      <base-icon name="base/ReviewPlaceholder" size="187px" filled />
      <p class="text-head-2 text-bold text-sora">
        {{ $t("review.r_no_review") }}
      </p>
      <p class="text-body-1 text-regular">{{ $t("review.r_share") }}</p>
    </div>
    <div v-else class="base-leave-review__block--container">
      <div class="container-header">
        <span class="container-header--title">
          <p class="text-subtitle text-regular mobile-font--b1">
            {{ $t("review.r_property") }}
          </p>
          <span class="star-flex">
            <q-rating
              v-model="reviewsInfo.averageRating"
              max="5"
              size="24px"
              color="#F59914"
              :icon="`img:${starGray}`"
              :icon-selected="`img:${starSelected}`"
              :icon-half="`img:${starHalf}`"
              no-dimming
              readonly
            />
            <p class="text-body-1 text-bold">
              {{ $t("review.stars", { amount: reviewsInfo.averageRating }) }}
            </p>
          </span>
        </span>
        <span class="container-header--amount">
          <p class="text-body-1 text-bold">
            {{ $t("review.r_amount", { amount: reviewsInfo.reviewsAmount }) }}
          </p>
        </span>
      </div>
      <base-separator class="span-separator" />
      <div class="container-stars">
        <span
          v-for="item in reviewsInfo.stars"
          :key="item.title"
          class="container-stars--filter"
          :class="{ 'filters-disabled': item.percentage == 0 }"
        >
          <base-navigator-filters-checkbox
            :checked="starsFilterMap[item.title]"
            :label-classess="['text-body-1', 'text-bold']"
            display-on-first-load
            type="24"
            :checkbox-label="`${item.title} ${$t('review.stars')}`"
            @click="handleCheckboxClick(item.title, item.percentage)"
          />

          <div class="progress">
            <div
              class="progress--bar"
              :style="[
                {
                  width: `${item.percentage > 100 ? 100 : Math.trunc(item.percentage)}%`,
                },
              ]"
            />
          </div>
          <p class="text-body-1 text-regular" style="margin-left: auto">
            {{ item.percentage }}%
          </p>
        </span>
      </div>
      <base-separator :vertical="!$device.isMobile" />
      <div class="container-scroll">
        <span v-for="(item, index) in unitReviews">
          <base-reviews-single
            :date="item.createdAt"
            :rating="convertServerStars(item.rating)"
            :text="item.text"
            :user-name="
              item.user?.username ??
              item.user?.firstName + ' ' + item.user?.lastName
            "
            :user-avatar="item.user.avatarFile?.url || defaultAvatar"
            :is-my-own="item.user.id === useUserStore.id"
          />
          <base-separator
            v-if="index + 1 !== unitReviews.length"
            style="margin-top: 16px"
          />
        </span>
        <base-button
          v-if="!isLastPage && unitReviews.length >= 5"
          :text="$t('review.more')"
          variant="dark_ghost"
          size="ls"
          style="margin-right: auto"
          :loading="isLoading"
          @click="getMoreReviews"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ReviewResponseDTO } from "~/services/swagger/Api";
import starGray from "~/assets/icons/base/ReviewStarGray.svg";
import starSelected from "~/assets/icons/base/ReviewStar.svg";
import starHalf from "~/assets/icons/base/ReviewStarHalf.svg";
import defaultAvatar from "~/assets/icons/avatars/Unknown.svg";
import {
  convertServerStars,
  calculateAverage,
  getServerStars,
} from "~/utilities/helpers/review/convertServerRating";
import { unitPageStore } from "~/store/unitPage";
import { apiGet } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import { debounce } from "lodash";
import { userStore } from "~/store/user";
import { userNames } from "~/utilities/constants/userNames";

interface IProps {
  unitId: string;
}

const props = defineProps<IProps>();
const useUnitpageStore = unitPageStore();
const UNIT = useUnitpageStore.createUnit();
const useUserStore = userStore();
const offset = ref(0);
const isLoading = ref(false);
const isLastPage = ref(false);

const starsFilterMap = reactive<{ [key: number]: boolean }>({
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
});

const isMyOwnReview = () => {};

const isFiltersActive = ref(false);

const reviewsInfo = computed(() => {
  const allNums = unitReviews.value.map((item) =>
    convertServerStars(item.rating)
  );
  const reviewsAmount = unitReviews.value.length;

  const stars = Array.from({ length: 5 }, (_, index) => {
    const star = index + 1;
    const count = allNums.filter((rating) => rating === star).length;
    const percentage =
      parseFloat(((count / reviewsAmount) * 100).toFixed(1)) || 0;

    return { title: star, percentage, checked: false };
  }).reverse();

  return {
    averageRating: calculateAverage(allNums),
    reviewsAmount,
    stars,
  };
});

const handleCheckboxClick = (index: number, percent: number) => {
  starsFilterMap[index] = !starsFilterMap[index];
  debounceGetFilteredReviews();
};

const debounceGetFilteredReviews = debounce(() => {
  getFilteredReviews();
}, 300);

const getFilteredReviews = async () => {
  const activeFilters = Object.keys(starsFilterMap).filter(
    (key) => starsFilterMap[+key]
  );
  try {
    const res = await apiGet({
      url: getRoute({ endpont: "get_unit_reviews", id: props.unitId }),
      query: {
        limit: 10,
        offset: 0,
        rating: activeFilters.map((item) => getServerStars(parseInt(item))),
        isUserReview: true,
      },
    });
    isFiltersActive.value = Boolean(activeFilters.length);
    const reviews = res?.data.value as ReviewResponseDTO[];
    unitReviews.value = reviews;
  } catch (error) {
    console.log("error: ", error);
  }
};

const unitReviews = ref(
  await UNIT.getReviews(
    props.unitId,
    {
      limit: 10,
      offset: 0,
    },
    true
  )
);

if (unitReviews.value.length < 10) {
  isLastPage.value = true;
}

const getMoreReviews = async () => {
  isLoading.value = true;
  offset.value = offset.value + 10;
  const activeFilters = Object.keys(starsFilterMap).filter(
    (key) => starsFilterMap[+key]
  );
  const res = await apiGet({
    url: getRoute({ endpont: "get_unit_reviews", id: props.unitId }),
    query: {
      limit: 10,
      offset: offset.value,
      rating: activeFilters.map((item) => getServerStars(parseInt(item))),
      isUserReview: true,
    },
  });
  const newReviews = res?.data.value as ReviewResponseDTO[];
  newReviews.forEach((item) => {
    if (!item?.user?.firstName && !item?.user?.firstName) {
      const el = userNames[Math.floor(Math.random() * userNames.length)];
      item.user.firstName = el.firstName;
      item.user.lastName = el.lastName;
    }
  });
  unitReviews.value.push(...newReviews);
  isLoading.value = false;
  if (newReviews?.length < 10) {
    isLastPage.value = true;
  }
};
</script>

<style scoped lang="scss">
.base-leave-review__block {
  position: relative;
  width: 100%;
  padding: 24px 16px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
  &--noreview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .base-icon {
      margin-bottom: 8px;
    }
  }
  &--container {
    position: relative;
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 400px 2px 1fr;
    .span-separator {
      grid-column: span 3;
    }
    .container-header {
      grid-column: span 3;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      &--title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .star-flex {
          display: flex;
          align-items: center;
          gap: 8px;
          p {
            margin-top: 3px;
          }
        }
      }
      &--amount {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    .container-stars {
      display: flex;
      width: 100%;
      max-width: 400px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      // .filters-disabled {
      //   * {
      //     color: var(--black-monochrome-60);
      //     cursor: default;
      //   }
      // }
      &--filter {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        .badge-wrapper {
          width: fit-content;
        }
        .progress {
          width: 260px;
          height: 10px;
          border-radius: 20px;
          background: var(--gray-monochrome-40);
          overflow: hidden;
          &--bar {
            height: 100%;
            border-radius: 20px;
            background: var(--purple-main);
            transition: 0.5s;
          }
        }
      }
    }
    .container-scroll {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      span {
        display: block;
        width: 100%;
      }
    }
  }
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .base-leave-review__block {
    margin-left: 24px;
    margin-right: 24px;
    max-width: calc(100dvw - 48px);
    &--container {
      .span-separator {
        grid-column: span 1;
      }
      .container-header {
        grid-column: span 1;
        gap: 0px;
      }
      grid-template-columns: 1fr;
      .container-stars {
        &--filter {
          .progress {
            width: calc(100% - 20px);
            // width: 150px;
          }
        }
      }
    }
  }
}
</style>

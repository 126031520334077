<template>
  <form
    class="subscription"
    :class="{ animated: subscriptionSent }"
    @submit.prevent="sendSubscription"
  >
    <base-icon
      name="mail"
      size="20px"
      color="var(--violet-main)"
      class="animate__animated"
      :class="{ animate__zoomOut: subscriptionSent }"
    />
    <input
      v-model="emailInput"
      type="email"
      maxlength="30"
      required
      placeholder="user@example.com"
      class="font--b4 animate__animated"
      :class="{ animate__zoomOut: subscriptionSent }"
    >
    <base-input-button
      class="animate__animated submit"
      :class="{ animate__zoomOut: subscriptionSent }"
      >{{ props.text }}</base-input-button
    >
    <p
      v-if="subscriptionSent"
      class="dm-sans font--b3 animate__animeted animate__fadeIn is-done"
    >
      {{ props.animText }}
    </p>
  </form>
</template>

<script setup lang="ts">
import getRoute from "~/utilities/configs/apiMap";
import { apiPost } from "~/services/api";

const props = defineProps({
  text: {
    type: String,
    required: false,
    default: "Notify Me",
  },
  animText: {
    type: String,
    required: false,
    default: "Subscribed!",
  },
});

const emailInput = ref("");
const subscriptionSent = ref(false);
const sendSubscription = async () => {
  subscriptionSent.value = true;
  const { $recaptcha } = useNuxtApp();
  const token = await $recaptcha.execute("login");
  const endpoint = getRoute({ endpont: "mail_list" });
  apiPost({
    url: endpoint,
    body: { address: emailInput.value },
    headers: { recaptcha: token },
  });
};
</script>

<style scoped lang="scss">
.subscription {
  position: relative;
  //max-width: 380px;
  width: 100%;
  height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7px 10px 16px;
  border-radius: 40px;
  box-shadow: var(--shadow-base);
  background: linear-gradient(
    to right,
    var(--violet-light) 50%,
    var(--white-contrast) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 2s ease;
  input {
    width: 100%;
    max-width: 220px;
    outline: none;
    border: none;
    background: var(--white-contrast);
    color: var(--black-monochrome-60);
    padding-left: 8px;
    &::placeholder {
      color: var(--gray-monochrome);
    }
  }
  .is-done {
    position: absolute;
    padding: 0 10px 0 16px;
    left: 0;
    width: 100%;
    text-align: center;
    color: var(--white-contrast);
    cursor: default;
  }
  .submit{
    padding-left: 27px;
    padding-right: 27px;
    font-size: 14px;
  }
}
.animated {
  background-position: left bottom;
}

@media (max-width: 42rem) {
  .subscription {
    width: 100%;
    max-width: 345px;
    height: 50px;
    justify-content: flex-start;
    padding: 6px 6px 6px 16px;
    .base-icon {
      width: 40px;
    }
    input {
      width: 100%;
      padding-left: 10px;
    }
  }
}
</style>

<template>
  <div>
    <p
      class="text-body-1 text-bold text-right"
      :class="[
        props.sub ? 'text-black-monochrome-60' : 'text-black-monochrome',
      ]"
    >
      {{ BASE_CURRENCY.symbol }}
      {{
        formatPrice(props.financeUSD, true, isLocalCurrency, {
          price: props.finance,
          currency: currentCurrency.code,
        }) || 0
      }}
    </p>
    <NativeCurrency
      v-if="props.currency !== BASE_CURRENCY.code"
      :currency-value="props.finance"
      :currency="props.currency"
      devide
    />
  </div>
</template>

<script setup lang="ts">
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import { formatPrice } from "~/utilities/helpers/format-data/number";

const props = defineProps<{
  financeUSD?: number;
  finance?: number;
  currency?: string;
  sub?: boolean;
}>();

const isLocalCurrency = computed(
  () => currentCurrency.value.code !== BASE_CURRENCY.value.code
);

const currentCurrency = computed(() => getCurrentCurrency(props.currency));
</script>

<template>
  <section class="user__reviews">
    <div class="reviews-filter">
      <profile-reviews-status-badge
        v-for="item in reviewFilters"
        size="big"
        :is-active="item == currentReviewFilter"
        :type="item ?? 'all'"
        @click="handleFilterChange(item)"
      />
    </div>
    <div v-if="loader" class="loader-wrapper">
      <div v-for="review in [1, 2]" class="review-item">
        <div class="review-item__content">
          <q-skeleton width="208px" height="100px" />
          <div class="mobile-wrapper">
            <div class="review-body">
              <div class="review-body__title">
                <q-skeleton width="180px" height="25px" />

                <q-skeleton width="240px" height="19px" />
              </div>
              <div class="review-body__stars">
                <span class="stars-item">
                  <q-skeleton width="185px" height="25px" />
                </span>
                <q-skeleton width="68px" height="16px" />
              </div>
            </div>
            <nav class="review-controls">
              <q-skeleton type="circle" size="32px" />
              <q-skeleton type="circle" size="32px" />
            </nav>
          </div>
        </div>
        <base-separator />
      </div>
    </div>
    <div
      v-else-if="!noneReviews"
      class="content-wrapper animate__animated animate__fadeIn"
    >
      <div v-for="review in userReviews" class="review-item">
        <div class="review-item__content">
          <q-img
            :src="review.unit?.files[0]?.file?.url"
            class="review-img"
            :width="!$device.isMobile ? '208px' : '345px'"
            :height="!$device.isMobile ? '100px' : '216px'"
          />
          <div class="mobile-wrapper">
            <div class="review-body">
              <div class="review-body__title">
                <profile-reviews-status-badge
                  size="small"
                  :type="review.status"
                />
                <p class="text-subtitle text-bold">
                  {{ review.unit?.name || "Not provided =(" }}
                </p>
                <span class="location-flex">
                  <base-icon
                    name="base/MapPin"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                  <p class="text-body-2 text-black-monochrome-60">
                    {{
                      truncateString(review.unit?.location, 50) ||
                      "Not provided =("
                    }}
                  </p>
                </span>
              </div>
              <div class="review-body__stars">
                <span class="stars-item">
                  <q-rating
                    v-model="review.numRating"
                    max="5"
                    size="24px"
                    color="#F59914"
                    :icon="`img:${starGray}`"
                    :icon-selected="`img:${starSelected}`"
                    :icon-half="`img:${starHalf}`"
                    no-dimming
                    readonly
                  />
                  <p class="text-body-1 text-bold" style="margin-top: 3px">
                    {{ convertServerStars(review.rating) }} stars
                  </p>
                </span>
                <span class="stars-date text-body-3">{{
                  getRelativeTime(review.createdAt)
                }}</span>
              </div>
              <div class="review-body__text">
                <p class="text-body-2">{{ review.text }}</p>
              </div>
              <div v-if="review.status==='Rejected'">
                <p class="font--b5 text-negative-sec">{{ review.rejectText }}</p>
              </div>
            </div>
            <nav class="review-controls">
              <button
                class="review-controls__button"
                @click="handleChangeReview(review)"
              >
                <base-icon name="base/Edit" size="20px" filled />
              </button>
              <button
                class="review-controls__button"
                @click="deleteReview(review.id)"
              >
                <base-icon name="base/Trash" size="20px" />
              </button>
            </nav>
          </div>
        </div>
        <base-separator />
      </div>
    </div>
    <div v-else-if="noneReviews" class="placeholder">
      <search-page-no-objects :img="noReviews">
        <template #default>
          <h3 class="text-head-2 text-bold text-black-monochrome">
            {{ $t("profile.p_no_reviews.no_props") }}
          </h3>
          <p class="text-body-1 mobile-font--b2 text-black-monochrome">
            {{ $t("profile.p_no_reviews.browse") }}
          </p>
          <base-button
            :text="$t('profile.p_no_favorite.directory')"
            variant="dark"
            size="sg"
            icon-name="base/Right"
            icon-position="right"
            icon-size="20px"
            @click="goSearch"
          />
        </template>
      </search-page-no-objects>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ReviewResponseDTO } from "~/services/swagger/Api";
import { useAdminStore } from "~/store/admin";
import { truncateString } from "~/utilities/helpers/strings/truncateString";
import starGray from "~/assets/icons/base/ReviewStarGray.svg";
import starSelected from "~/assets/icons/base/ReviewStar.svg";
import starHalf from "~/assets/icons/base/ReviewStarHalf.svg";
import { getRelativeTime } from "~/utilities/helpers/format-data/date";
import {
  convertServerStars,
} from "~/utilities/helpers/review/convertServerRating";
import noReviews from "~/assets/icons/base/ReviewPlaceholder.svg";
import { Api } from "~/services/swagger/Api";
import { Notification } from "~/services/notifications/toast";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { userStore } from "~/store/user";

interface IProps {
  reviews: ReviewResponseDTO[];
}

interface ExtendedReviewResponseDTO extends ReviewResponseDTO {
  numRating: number;
}

const props = defineProps<IProps>();
const adminStore = useAdminStore();
const localePath = useLocalePath();
const apiClient = new Api();
const callPopUp = PopUpServices();
const useUserStore = userStore();

const loader = ref(true);
const userReviews = ref<ExtendedReviewResponseDTO[]>([]);
const noneReviews = ref(false);

const getUserReviews = async (filter?: (typeof reviewFilters)[number]) => {
  loader.value = true;
  const updatedReviews: ExtendedReviewResponseDTO[] | undefined =
    props.reviews?.map((item) => {
      return {
        ...item,
        numRating: convertServerStars(item.rating),
      };
    });
  userReviews.value = updatedReviews ?? [];
  if (filter) {
    userReviews.value = userReviews.value.filter(
      (item) => item.status == filter
    );
  }
  noneReviews.value = userReviews.value.length === 0;

  setTimeout(() => {
    loader.value = false;
  }, 500);
};

const goSearch = () => {
  return navigateTo(localePath("/directory"));
};

const deleteReview = async (id: string) => {
  try {
    await apiClient.api.reviewsControllerDelete2(id);
  } catch (error) {
    const errorNotify = new Notification({
      type: "error",
      message: "Something went wrong, please try later.",
    });
    errorNotify.createNewToast();
  }
};

const handleChangeReview = async (review: ExtendedReviewResponseDTO) => {
  const res = await callPopUp.callPopUp({
    componentName: "base-reviews-leave-review-popup",
    persistent: false,
    data: {
      userName: useUserStore.currentUser.username,
      rating: review.numRating,
      propId: review.unit?.id,
      propImgs: review.unit?.files,
      propName: review.unit?.name || "this property",
      reviewText: review.text,
      reviewId: review.id,
      isUpdate: true,
    },
  });
  // if (res) {
  //   rating.value = convertServerStars(res.rating);
  //   manualHasReview.value = true;
  //   emit("update-block", {
  //     review: res.review,
  //     user: useUserStore.currentUser,
  //   });
  // }
};

const reviewFilters = [null, "Moderating", "Published", "Rejected", "Deleted"];

const currentReviewFilter = ref<(typeof reviewFilters)[number]>(null);

const handleFilterChange = (val: (typeof reviewFilters)[number]) => {
  currentReviewFilter.value = val;
  getUserReviews(val);
};

onMounted(() => {
  getUserReviews();
});
</script>

<style scoped lang="scss">
.user__reviews {
  .reviews-filter {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;
    max-width: 100%;
  }
  .content-wrapper,
  .loader-wrapper {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    width: 100%;
    height: fit-content;
    gap: 24px;
  }
  .review-item {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    width: 100%;
    height: fit-content;
    gap: 24px;
    &__content {
      display: grid;
      grid-template-columns: 208px 1fr;
      align-items: start;
      width: 100%;
      height: fit-content;
      gap: 16px;
      .review-img {
        border-radius: 8px;
      }
      .mobile-wrapper {
        display: grid;
        grid-template-columns: 1fr 48px;
        align-items: center;
        width: 100%;
        height: fit-content;
        gap: 16px;
      }
      .review-body {
        display: grid;
        align-items: flex-start;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 100%;
        row-gap: 9px;
        &__title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 6px;
          p {
            white-space: nowrap;
          }
          .location-flex {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        &__stars {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 7px;
          .stars-item {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
        &__text {
          grid-column: span 2;
          justify-self: flex-start;
        }
      }
      .review-controls {
        display: flex;
        width: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        margin-left: 16px;
        align-self: flex-start;
        &__button {
          @include drop-button-styles;
          display: flex;
          height: 32px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 50%;
          background-color: var(--gray-monochrome-40);
          transition: scale 0.3s ease;
          will-change: scale;
          &:hover {
            scale: 1.05;
          }
          &:active {
            scale: 0.95;
          }
        }
      }
    }
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .user__reviews {
    .reviews-filter {
      max-width: 350px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none; 
      }
    }
    .review-item {
      &__content {
        grid-template-columns: 1fr;
        .mobile-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .review-body {
            display: flex;
            flex-direction: column;
            width: fit-content;
            &__stars {
              align-items: flex-start;
            }

            &__title {
              p {
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
</style>

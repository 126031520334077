import { Notify, type QNotifyCreateOptions } from "quasar";
import type VueI18n from "vue-i18n";
import { QSpinnerGears } from "quasar";

Notify.setDefaults({
  position: "top-right",
  classes: "notification",
  badgeClass: "notification--badge",
  timeout: 3000,
  html: true,
  actions: [
    {
      icon: "close",
      size: "small",
      color: "black",
      round: true,
      handler: () => {},
    },
  ],
});

Notify.registerType("system", {
  classes: "notification--system",
});

Notify.registerType("compare", {
  position: "bottom-right",
  timeout: 20000,
  group: "compare",
  badgeStyle: { display: "none" },
  classes: "notification--compare",
});

Notify.registerType("error", {
  classes: "notification--error",
});

Notify.registerType("demo-portfolio", {
  classes: "notification--demo-portfolio",
  position: "bottom",
  badgeStyle: { display: "none" },
  message: `It'is demo portfolio now`,
  timeout: 0,
});

Notify.registerType("token-portfolio", {
  spinner: QSpinnerGears,
  classes: "notification--demo-portfolio",
  position: "bottom",
  badgeStyle: { display: "none" },
  timeout: 0,
});

export class Notification {
  args: QNotifyCreateOptions | undefined;
  constructor(args: QNotifyCreateOptions | undefined) {
    this.args = args;
  }

  createNewToast(message?: string) {
    const newArgs: Record<string, any> = {};
    if (message || this.args?.message) {
      newArgs.message = message || this.args?.message;
    }
    if (this.args?.actions) {
      Notify.setDefaults({
        actions: [],
      });
    }
    return Notify.create({
      ...newArgs,
      ...this.args,
    });
  }
  createCompareToast(c: {
    has: number;
    all?: number;
    path: string;
    t: VueI18n.ComposerTranslation;
  }) {
    c.all = c.all ?? 4;
    const loc = window.location.origin;
    return Notify.create({
      message: `${c.t("compare.toast", { amount: c.has, more: c.t(`${c.has === c.all ? c.t("compare.cant_add") : c.t("compare.can_add", { amount: c.all - c.has })}`) })} <a
                href="${loc + c.path}"
                target="_blank"
    >${c.t("search_card.fr_compare-go")}</a>`,
      ...this.args,
    });
  }
}

import type { UnitResponseDTO } from "~/services/swagger/Api";
import type { IEstateFinance } from "~/types/unit-item-finance";

export interface ExtendedEstatePayloadDTO
  extends Omit<UnitResponseDTO, "finance"> {
  finance: IEstateFinance;
}

const unitObj: ExtendedEstatePayloadDTO = {
  countryId: undefined,
  location: undefined,
  coords: [],
  minimumInvestment: undefined,
  currency: undefined,
  price: undefined,
  name: undefined,
  description: undefined,
  area: undefined,
  ownershipStatus: undefined,
  ownershipText: undefined,
  stage: "complete",
  irr: undefined,
  apr: undefined,
  utilities: undefined,
  currentUse: undefined,
  finance: {
    projectTotalIncome: {
      value: 0,
      subfields: {
        projectedRentalIncome: 0,
        projectionAppreciation: 0,
      },
    },
    totalInvestmentValue: {
      value: 0,
      subfields: {
        underlyingAssetPrice: 0,
        legalFees: 0,
        platformFees: 0,
        auditFees: 0,
        registrationTax: 0,
        daoRegistrationFees: 0,
        initialMaintenanceReserve: 0,
        operatingReserve: 0,
      },
    },
    annualCashFlow: 0,
    monthCashFlow: 0,
    annualGrossIncome: 0,
    maintainingCosts: {
      value: 0,
      subfields: {
        renovationCosts: 0,
        propertyManagement: 0,
        maintenanceReserve: 0,
        utilities: 0,
        propertyUpgrades: 0,
      },
    },
    otherFees: 0,
  } ,
  bathroom: undefined,
  bedroom: undefined,
  blockedField: [],
  referralLink: {
    id: "",
    createdAt: "",
    updatedAt: "",
    expiredAt: undefined,
    providerName: "",
    link: "",
    description: undefined,
    default: undefined,
  },
  translations: undefined,
  // tokenId: "",
  documents: [],
  partners: [],
  rating: 0,
  tags: [],
  token: {
    name: undefined,
    symbol: undefined,
    decimals: undefined,
    totalSupply: undefined,
    circulatingSupply: undefined,
    availableSupply: undefined,
    chainId: undefined,
    address: undefined,
    icon: undefined,
    currency: undefined,
    price: undefined,
    issuer: {
      name: "",
      description: "",
      url: "",
      logo: "",
      industry: "",
      foundingYear: 0,
    },
    jurisdiction: undefined,
    description: undefined,
    providerId: undefined,
  },
  timeline: [],
  files: [],
  contract: {
    // id: undefined,
    // createdAt: undefined,
    // updatedAt: undefined,
    type: "ERC20",
    chainId: undefined,
    address: undefined,
    // description: undefined,
    // icon: undefined,
    // jurisdiction: undefined,
    // issuerId: undefined,
    // url: undefined,
    ERC20: {
      name: undefined,
      symbol: undefined,
      decimals: undefined,
    },
    // ERC1155: undefined,
    // chain: undefined,
    // issuer: {
    //   id: undefined,
    //   name: undefined,
    //   description: undefined,
    //   url: undefined,
    //   industry: undefined,
    //   foundingYear: undefined,
    //   region: undefined,
    //   locality: undefined,
    //   street: undefined,
    //   telephone: undefined,
    //   coords: undefined,
    //   logoFile: undefined,
    //   country: undefined,
    // },
  },
  tokenomics: {
    // currency: "",
    price: undefined,
    // priceUSD: "",
    maxSupply: undefined,
    totalSupply: undefined,
    circulatingSupply: undefined,
    availableSupply: undefined,
  },
};

export const getUnitEmptyData = (): ExtendedEstatePayloadDTO => {
  return unitObj;
};

export const unitSubtypes: string[] = [
  "Apartment",
  "House",
  "Townhouse",
  "Condominium",
  "Other",
];

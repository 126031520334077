<template>
  <section class="legal">
    <div class="legal__title">
      <h1 class="text-head-1 text-bold text-sora" v-html="$t('legal.terms.page_title')"/>
      <h2 class="text-body-2 text-regular">
        {{
          $t("legal.terms.page_update_date", {
            date: new Intl.DateTimeFormat("en-US").format(new Date())
          })
        }}
      </h2>
    </div>
    <section class="legal__info">
      <legal-sidebar
        v-if="!$device.isMobile"
        ref="sideBarRef"
        :titles="headers"
        @click-title="handleClickTitle"
      />
      <base-separator
        v-if="!$device.isMobile"
        vertical
        :style="{ height: sideBarNode?.sideBarRef?.clientHeight + 'px' }"
      />
      <div class="text">
        <span id="0" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora">{{ $t("legal.common.intro") }}</h5>
          <p class="text-body-1" v-html="$t('legal.terms.paragraphs.0.text')"/>
        </span>
        <span id="1" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.1')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.1.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.0')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.2.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.1')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.3.text')"
            />
          </span>
        </span>
        <span id="2" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.2')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.4.text')"
            />
            <ul class="ul-style ul-style--num text-body-1" v-html="$t('legal.terms.ul.2')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.5.text')"
            />
          </span>
        </span>
        <span id="3" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.3')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.6.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.3')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.7.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.4')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.9.text')"
            />
          </span>
        </span>
        <span id="4" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.4')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.10.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.5')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.11.text')"
            />
          </span>
        </span>
        <span id="5" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.5')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.12.text')"
            />
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.13.text')"
            />
          </span>
        </span>
        <span id="6" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.6')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.14.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.6')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.15.text')"
            />
          </span>
        </span>
        <span id="7" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.7')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.16.text')"
            />
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.17.text')"
            />
          </span>
        </span>
        <span id="8" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.8')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.18.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.7')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.19.text')"
            />
          </span>
        </span>
        <span id="9" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.9')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.20.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.terms.ul.8')"/>
          </span>
        </span>
        <span id="10" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.10')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.21.text')"
            />
          </span>
        </span>
        <span id="11" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.11')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.22.text')"
            />
          </span>
        </span>
        <span id="12" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.terms.headers.12')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.terms.paragraphs.23.text', {email:'hi@rwa-estate.com '})"
            />
          </span>
        </span>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
const { tm } = useI18n()

const sideBarNode = useTemplateRef("sideBarRef")

const handleClickTitle = (val: string) => {
  if (val) {
    const element = document.getElementById(val)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

const headers = computed(() => {
  const headersList = tm("legal.terms.nav") as unknown as {
    title: string
  }[]
  return headersList ?? []
})
</script>

<style scoped lang="scss"></style>

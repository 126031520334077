<template>
  <div class="admin-page">
    <div class="admin-container">
      <div>
        <base-button
          :text="$t('search_filters.fr_sort')"
          :size="$device.isMobile ? 'xs' : 'xss'"
          variant="light_bordered"
          icon-name="base/Sliders"
          :icon-size="$device.isMobile ? '18px' : '16px'"
        />
        <q-menu
          class="sorting-menu"
          anchor="bottom left"
          self="top left"
          :offset="[0, 15]"
        >
          <div class="menu">
            <h3 class="font--b4 text-black-monochrome" style="font-weight: 700">
              Sort
            </h3>

            <div v-for="(item, index) in sortingFilters" class="list-container">
              <ul>
                <li>
                  <button @click="sortReviews(item)">
                    <p class="font--b4 text-black-monochrome">
                      {{ item.status }}
                    </p>
                    <base-icon
                      v-if="item.status === currentSort?.status"
                      size="22px"
                      name="base/Done"
                      filled
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </q-menu>
      </div>
      <section ref="reviewContainerRef" class="content">
        <span
          v-for="(item, index) in filteredReviewsArray"
          :key="index"
          class="reviews"
          :class="{ 'reviews--active': currentActive === index }"
        >
          <span class="reviews-badge">
            <q-badge
              outline
              :color="getBadgeColor(item.status)"
              :label="item.status"
            />
          </span>

          <base-reviews-single
            :text="item.text"
            :date="item.createdAt"
            :rating="convertServerStars(item.rating)"
            :user-avatar="item.user?.avatarFile?.url"
            :user-name="`${item.user.firstName} ${item.user.lastName}`"
          />
          <span class="reviews-control">
            <q-fab
              color="amber"
              text-color="black"
              icon="keyboard_arrow_left"
              direction="left"
              @show="currentActive = index"
            >
              <q-fab-action
                v-if="item.status === 'Moderating'"
                color="green-3"
                text-color="black"
                label="Accept"
                @click="sendAccept(index)"
              />
              <q-fab-action color="primary" text-color="black" label="Reject">
                <q-popup-proxy>
                  <div style="display: flex; gap: 20px">
                    <q-input v-model="rejectText" :maxlength="250" />
                    <q-btn
                      label="Reject"
                      color="primary"
                      dense
                      :disable="!rejectText"
                      @click="rejectReview(index)"
                    />
                  </div>
                </q-popup-proxy>
              </q-fab-action>
              <q-fab-action
                color="deep-orange-3"
                text-color="black"
                label="Delete"
                @click="deleteReview(index)"
              />
              <q-fab-action
                color="purple-8"
                text-color="black"
                label="Check property"
                @click="checkProperty(index)"
              />
            </q-fab>
          </span>
          <base-separator style="margin-top: 16px; display: block" />
        </span>
        <img src="" style="width: 40px; height: 40px" alt="" >
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/store/admin";
import type {
  ModerateReviewDTO,
  ReviewResponseDTO,
} from "~/services/swagger/Api";
import { convertServerStars } from "~/utilities/helpers/review/convertServerRating";
import { apiGet } from "~/services/api";

definePageMeta({
  layout: "admin-edit",
  middleware: "admin-auth-middleware",
});

useHead({
  title: "Reviews",
  meta: [
    {
      name: "description",
      content: "Admin page.",
    },
  ],
});

const adminStore = useAdminStore();
const $q = useQuasar();
const reviewContainerRef = ref<HTMLElement | null>(null);
const reviewsArray = ref<ReviewResponseDTO[]>([]);
const currentSort = ref<ModerateReviewDTO>();
const currentActive = ref<number | null>();
const filteredReviewsArray = ref<ReviewResponseDTO[]>([]);
const rejectText = ref("");
const sortingFilters = [
  { status: "New" },
  { status: "Moderating" },
  { status: "Rejected" },
  { status: "Published" },
  { status: "Deleted" },
] as ModerateReviewDTO[];

const sortReviews = (filter: ModerateReviewDTO) => {
  currentSort.value = filter;
  if (filter.status) {
    fetchAllProps({ status: [filter.status] });
  }
};

const getBadgeColor = (status: ModerateReviewDTO["status"]) => {
  switch (status) {
    case "New":
      return "green-8";
    case "Moderating":
      return "primary";
    case "Published":
      return "positive";
    case "Rejected":
      return "accent";
    case "Deleted":
      return "negative";
  }
};

const fetchAllProps = async (filters?: Record<string, any>) => {
  $q.loading.show();
  const filter = filters ?? {};
  const reviews = await apiGet({
    url: "/api/reviews",
    query: { limit: 1000, ...filter },
    headers: {
      Authorization: `Bearer ${useCookie("acc_token").value}`,
    },
  });

  if (!reviews?.data.value) {
    $q.loading.hide();
    reviewsArray.value = [];
    filteredReviewsArray.value = [];
    return;
  }
  reviewsArray.value = reviews.data.value?.rows;
  filteredReviewsArray.value = [...reviewsArray.value];
  $q.loading.hide();
};

const sendAccept = async (index: number) => {
  const res = await adminStore.acceptReview({
    reviewId: filteredReviewsArray.value[index].id,
  });
  if (res) {
    filteredReviewsArray.value[index].status = res;
    currentActive.value = null;
  }
};

const deleteReview = async (index: number) => {
  const res = await adminStore.deleteReview({
    reviewId: filteredReviewsArray.value[index].id,
  });
  if (res) {
    reviewsArray.value = reviewsArray.value.filter((_, i) => i !== index);
    filteredReviewsArray.value = [...reviewsArray.value];
    currentActive.value = null;
  }
};

const rejectReview = async (index: number) => {
  const res = await adminStore.rejectReview({
    reviewId: filteredReviewsArray.value[index].id,
    text: rejectText.value,
  });
  if (res) {
    reviewsArray.value[index].status = "Rejected";
    currentActive.value = null;
    rejectText.value = "";
  }
};

const checkProperty = (index: number) => {
  window.location;
  window.open(
    window.location.origin +
      "/property/" +
      filteredReviewsArray.value[index].unit.sanitizeName,
    "_blank"
  );
};

onMounted(() => {
  fetchAllProps();
});
</script>

<style scoped lang="scss">
.admin-page {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
}

.admin-container {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
}

.content {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  column-gap: 30px;
  max-height: 600px;
  overflow-y: scroll;
  padding: 10px;
  width: 100%;
  .reviews {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px;
    transition: background-color 0.3s ease;
    will-change: background-color;
    &--active {
      background-color: var(--white--contrast-72);
      border-radius: 12px;
      box-shadow: var(--shadow-light);
    }
    &:hover {
      .reviews-control {
        display: block;
      }
    }
    &-control {
      display: none;
      position: absolute;
      right: 0;
      top: 30%;
    }
    &-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      .q-badge {
        width: fit-content;
      }
    }
  }
}
</style>
<style lang="scss">
.sorting-menu {
  border-radius: 24px;
  border: 1px solid var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
  background: var(--Monochrome-White_contrast, #f3f7fe);
  padding: 24px;
  height: auto;
  width: 316px;
  max-height: 252px;
  .menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    display: flex;
    width: 100%;
    height: 100%;
    h3 {
      margin-bottom: 6px;
    }
    .list-container {
      display: grid;
      gap: 12px;
      width: 100%;
      ul {
        display: grid;
        gap: 12px;
        padding-left: 25px;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          width: 100%;
          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: transparent;
            outline: none;
            border: none;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}
</style>

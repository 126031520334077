<template>
  <div class="steps">
    <div class="steps__title text-head-1 text-bold text-sora">
      {{ $t("main.m_steps_title") }}
    </div>
    <div class="step">
      <div class="step__text">
        <div class="step__number text-sora">01</div>
        <div class="step__title text-head-2 text-bold text-sora">
          {{ $t("main.m_step_first_title") }}
        </div>
        <div class="step__desc text-subtitle">
          {{ $t("main.m_step_first_text") }}
        </div>
      </div>
      <div class="step__img step__img--1">
        <NuxtImg src="/img/main/step-1.png" alt="" />
      </div>
    </div>
    <div class="step step--reverse">
      <div class="step__text">
        <div class="step__number text-sora text-bold">02</div>
        <div class="step__title text-head-2 text-bold text-sora">
          {{ $t("main.m_step_second_title") }}
        </div>
        <div class="step__desc text-subtitle">
          {{ $t("main.m_step_second_text") }}
        </div>
      </div>
      <div class="step__img step__img--2">
        <NuxtImg src="/img/main/step-2.png" alt="" />
        <div class="buy-tokens">
          <div class="get-in-touch">
            <div class="info">
              <p class="text-subtitle text-bold text-black-monochrome">
                {{ $t("search_card.fr_ready") }}
              </p>
            </div>
            <base-button
              variant="prime"
              :text="$t('search_card.fr_contact')"
              size="sg"
              icon-name="base/Right"
              icon-position="right"
              text-size="landing"
              bold-text
              @click="goSearch"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="step">
      <div class="step__text">
        <div class="step__number text-sora text-bold">03</div>
        <div class="step__title text-head-2 text-bold text-sora">{{ $t("main.m_step_third_title") }}</div>
        <div class="step__desc text-subtitle">
          {{ $t("main.m_step_third_text") }}
        </div>
      </div>
      <div class="step__img step__img--3">
        <NuxtImg src="/img/main/step-3.png" alt="" />
        <div class="example">
          <unit-page-info-block :property-info="propertyInfo" type="estates"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const localePath = useLocalePath();

const propertyInfo = {
  name: "Sobha Hearland II Villas",
  location: "1020 Bloomingdale Ave",
  coords: {
    lat: 40.7128,
    lng: -74.006,
  },
  priceUSD: 5,
  price: 213240000,
  currency: "USD",
  minimumInvestmentUSD: 50,
  minimumInvestment: 50,
  rating: 4.8,
  token: {
    priceUSD: 1500,
    price: 10,
    availableSupplyPercentage: 76,
  },
  irr: 20,
  apr: 8,
  finance: {
    projectTotalIncome: {
      subfields: {
        projectionAppreciation: 20,
      },
    },
  },
};

const goSearch = () => {
  return navigateTo(localePath("/directory"));
};
</script>
<style scoped lang="scss">
.steps {
  // background: linear-gradient(45deg, #fff 45%, #566285 80%);
  max-width: 1302px;
  width: 100%;
  margin: auto;
  margin-top: 72px;
  padding-top: 75px;
  border-radius: 40px;
  background-image: url("~/assets/img/main/bg1.svg");
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  &__title {
    text-align: center;
    margin-bottom: 70px;
  }
}
.step {
  display: flex;
  max-width: 1102px;
  width: 100%;
  margin: auto;
  gap: 40px;
  margin-bottom: 130px;
  &__text {
    padding-left: 55px;
  }
  &__number {
    font-size: 80px;
    margin-bottom: 40px;
    font-weight: 700;
  }
  &__title {
    margin-bottom: 12px;
  }

  &__img {
    position: relative;
    max-width: 463px;
    min-width: 463px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &--reverse {
    flex-direction: row-reverse;
    .step__text {
      padding-left: 20px;
    }
  }
}
.step:last-child {
  margin-bottom: 44px;
}
.buy-tokens {
  position: absolute;
  right: -6px;
  bottom: -37px;
  width: 265px;
  height: 157px;
  overflow: hidden;
  border-radius: 16px;
  .base-button--sg {
    padding: 0 40px;
  }
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  width: 266px;
  height: 157px;
  padding: 16px;
  min-height: 157px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  align-self: flex-end;
  background-image: url("~/assets/img/linear.svg");

  background-repeat: no-repeat;
  pointer-events: none;
  .info {
    p {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
.example {
  position: absolute;
  top: -60px;
  right: -30px;
  transform: scale(0.8);
}

@media (max-width: 75rem) {
  .steps {
    padding-top: 44px;
    margin-top: 56px;
    &__title {
      font-size: 22px;
      margin-bottom: 30px;
    }
  }
  .step {
    max-width: 329px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0 15px;
    &__text {
      padding-left: 55px;
    }
    &__number {
      font-size: 32px;

      margin-bottom: 21px;
      line-height: 1.2;
    }
    &__title {
      font-size: 22px;
      margin-bottom: 12px;
    }
    &__desc {
      font-size: 14px;
    }
    &__text {
      padding-left: 0px;
    }
    &__img--1 {
      max-width: 100%;
      min-width: auto;
      width: 100%;
      margin: auto;
    }
    &__img--2 {
      max-width: 273px;
      min-width: 273px;
      margin-right: auto;
    }
    &__img--3 {
      max-width: 273px;
      min-width: 273px;
      margin-right: auto;
    }
    &--reverse {
      flex-direction: column;
      margin-bottom: 55px;
      .step__text {
        padding-left: 0;
      }
    }
  }
  .buy-tokens {
    position: absolute;
    right: -44px;
    bottom: -13px;

    width: 209px;
    height: 108px;
    font-size: 20px;

    border-radius: 16px;
    .base-button--sg {
      padding: 0 30px;
      height: 32px;
    }
  }

  .get-in-touch {
    display: flex;
    flex-direction: column;
    width: 209px;
    height: 108px;
    padding: 8px;
    min-height: 108px;

    .info {
      p {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
  .example {
    position: absolute;
    top: -120px;
    right: -80px;
    transform: scale(0.55);
  }
}

@media (max-width: 59rem) {
  @include mobile-fonts;
}
@media (max-width: 25rem) {
  .buy-tokens {
    right: -5px;
  }
  .example {
    right: -62px;
  }
}
</style>

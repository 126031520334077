import { formatPrice } from "~/utilities/helpers/format-data/number";
import { Api, type UnitResponseDTO } from "../swagger/Api";
import { convertServerStars } from "~/utilities/helpers/review/convertServerRating";
import { unitPageStore } from "~/store/unitPage";

const apiClient = new Api();

function calculateRating(reviews: number[]): number {
  if (reviews.length === 0) return 0;
  const totalScore = reviews.reduce((sum, rating) => sum + rating, 0);
  return parseFloat((totalScore / reviews.length).toFixed(1));
}

export const updateUnitPageSeo = async (
  unitPageData: UnitResponseDTO,
  pageId: string
) => {
  const getUnitPhotoLink =
    unitPageData?.files.find((photo) => photo.type === "image")?.file.url ||
    "https://www.rwa-estate.com/hero.png";
  const descriptions =
    "Browse RWA Estate's tokenized real estate listings. Discover property prices, investment returns, token availability, and more. Explore trending properties, value growth, and investment opportunities with real-time data.";
  const descriptionUnit = `Explore ${unitPageData?.name} on RWA Estate. View property details, investment calculator, and token availability for purchase`;
  const title = `Invest in ${unitPageData?.name}${unitPageData?.minimumInvestmentUSD ? " from " + formatPrice(unitPageData?.minimumInvestmentUSD, true) : ""}$ - Tokenized Real Estate`;

  const gatherReviews = async () => {
    const useUnitpageStore = unitPageStore();
    const UNIT = useUnitpageStore.createUnit();
    const reviews = await UNIT.getReviews(unitPageData.id, {
      limit: 50,
      offset: 0,
    });
    if (!reviews?.length) return {};
    const providers = await apiClient.api.providersControllerList();
    if (!providers.data) return {};
    if (!unitPageData?.reviews?.length) return {};
    const currentProvider = providers.data.find(
      (item) => item.id === unitPageData.provider.id
    );
    if (!currentProvider) return {};

    const updatedReviews = reviews?.map((review: any) => ({
      "@type": "Review",
      author: {
        "@type": "Person",
        name: review.user?.firstName || "Anonymous",
      },
      datePublished: review.createdAt || new Date().toISOString(),
      reviewBody: review.text || "No review text available",
      reviewRating: {
        "@type": "Rating",
        ratingValue: convertServerStars(review.rating),
      },
    }));

    const providerUnit = {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: currentProvider.name,
      image: currentProvider.logoFile?.url ?? "",
      address: {
        "@type": "PostalAddress",
        addressCountry: {
          "@type": "Country",
          name: currentProvider.country?.name || "Not provided",
        },
        addressRegion: currentProvider.region || "Not provided",
        addressLocality: currentProvider.locality || "Not provided",
        streetAddress: currentProvider.street || "Not provided",
      },
      description: title,
      telephone: currentProvider.telephone || "0",
      geo: {
        "@type": "GeoCoordinates",
        "@context": "http://schema.org",
        latitude: currentProvider.coords?.[0] ?? 0,
        longitude: currentProvider.coords?.[1] ?? 0,
      },
      aggregateRating: {
        "@type": "AggregateRating",
        worstRating: 1,
        bestRating: 5,
        reviewCount: updatedReviews?.length ?? 0,
        ratingValue: calculateRating(
          reviews.map((q) => convertServerStars(q.rating))
        ),
      },
      review: updatedReviews,
    };

    return providerUnit;
  };
  const pdoviderObj = await gatherReviews();
  useSeoMeta({
    ogTitle: title,
    ogDescription: descriptionUnit,
    keywords: Array.from(
      new Set([
        ...(unitPageData?.name?.split(" ") || []),
        "Tokenized Real Estate Investment",
        "Property Investment",
        "Tokenized Property",
        "Real Estate Tokens for Purchase",
        `Investment Property in ${unitPageData?.location || "Unknown Location"}`,
      ])
    ).join(", "),
    ogImage: getUnitPhotoLink,
    ogUrl: "https://www.rwa-estate.com/property/" + pageId,
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: descriptionUnit,
    twitterImage: getUnitPhotoLink,
  });

  useHead({
    title: title,
    meta: [
      {
        name: "robots",
        content: "index, follow",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: `https://www.rwa-estate.com/property/${unitPageData?.sanitizeName}`,
      },
    ],
    script: [
      {
        type: "application/ld+json",
        children: [
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "RWA Estate",
            address: "Not provided",
            description: descriptions,
            url: "https://www.rwa-estate.com/",
            logo: "https://www.rwa-estate.com/favicon.ico",
            image: "https://www.rwa-estate.com/hero.png",
            telephone: "0",
          },
          {
            "@context": "http://schema.org",
            "@type": "RealEstateAgent",
            name: "RWA Estate",
            logo: "https://www.rwa-estate.com/favicon.ico",
            image: "https://www.rwa-estate.com/hero.png",
            url: "https://www.rwa-estate.com/",
            telephone: "0",
            email: "hi@rwa-estate.com",
            priceRange: "$$$",
            address: "Not provided",
          },
          pdoviderObj,
        ],
      },
    ],
  });
};

<template>
  <div class="compare-units">
    <span v-if="props.data.compare" class="compare-units--title">
      The ones you pick will update, but the rest will not.
    </span>
    <span v-else class="compare-units--title">
      Nothing to review. This is probably a bug.
      <button @click="hardStatusSwitch">Hard switch to moderating</button>
    </span>
    <q-option-group
      v-model="acceptedFields"
      :options="compare"
      option-value="title"
      color="green"
      type="checkbox"
    >
      <template #label="opt">
        <div class="compare-units--flexbox">
          <span
            ><strong>{{ opt.title }}</strong></span
          >
          <span v-if="opt.title !== 'files'">
            <span class="text-red-5">{{ opt.old }}</span>
            <span> => </span>
            <span class="text-teal">{{ opt.new }}</span>
          </span>
          <span v-else class="compare-units--flexbox">
            <span class="compare-units--flexbox">
              <img v-for="i in opt.old" :src="i?.file?.url" alt="" />
            </span>
            <span> => </span>
            <span class="compare-units--flexbox">
              <img v-for="i in opt.new" :src="i?.file?.url" alt="" />
            </span>
          </span>
        </div>
      </template>
    </q-option-group>
    <span v-if="props.data.compare" class="compare-units--controls">
      <base-button
        v-close-popup
        text="Cancel"
        size="xss"
        variant="light_bordered"
        class="add-item-btn"
      />
      <base-button
        text="Approve updates"
        size="xss"
        variant="prime"
        class="add-item-btn"
        :loading="isApproveLoading"
        @click="sendApprove"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";

interface IProps {
  data: {
    compare: { [key: string]: { old: any; new: any } };
    unit: any;
    unitId: string;
  };
}
const props = defineProps<IProps>();

const acceptedFields = ref<string[]>([]);
const isApproveLoading = ref(false);
const usePopupCervices = PopUpServices();

const compare = computed(() => {
  return Object.keys(props.data.compare ?? {}).map((key) => ({
    title: key,
    ...props.data.compare[key],
  }));
});

const hardStatusSwitch = async () => {
  await props.data.unit.updatePropertyStatus(props.data.unitId, "moderating");
  window.location.reload();
};

const sendApprove = async () => {
  isApproveLoading.value = true;
  await props.data.unit.approveChanges(props.data.unitId, acceptedFields.value);
  isApproveLoading.value = false;
  usePopupCervices.$state.popUpRef?.hide();
};
</script>

<style scoped lang="scss">
.compare-units {
  width: 500px;
  height: fit-content;
  border-radius: 12px;
  padding: 15px;
  background-color: var(--white-monochrome);
  box-shadow: var(--shadow-base);
  display: grid;
  align-items: center;
  justify-items: flex-start;
  gap: 15px;
  &--title {
    justify-self: center;
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      @include drop-button-styles;
      border-radius: 12px;
      padding: 4px 8px;
      background-color: var(--purple-main);
      color: var(--white-monochrome);
    }
  }
  &--flexbox {
    display: flex;
    gap: 15px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  &--controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    .base-button {
      width: 100%;
    }
  }
}
</style>

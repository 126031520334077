<template>
  <section class="holders-popup">
    <button v-close-popup class="close-butt hover-rotate">
      <Suspense>
        <base-icon name="base/Close" size="100%" />
      </Suspense>
    </button>
    <q-tabs v-model="currentTab" @update:model-value="handleTabsChange">
      <q-tab
        v-for="tab in tabs"
        :key="tab.label"
        :name="tab.label"
        :label="tab.label"
        :disable="tab.disable"
      />
    </q-tabs>
    <div v-if="currentTab === 'Description'" class="holders-popup__partner">
      <q-tabs v-model="currentSubTab" @update:model-value="handleSubTabsChange">
        <q-tab
          v-for="tab in currentSubTabs.subtabs"
          :key="tab.label"
          :name="tab.label"
          :label="tab.label"
          :disable="tab.disable"
          ripple
          no-caps
        />
      </q-tabs>
      <div v-if="currentSubTab === 'Original'" class="holders-popup__partner">
        <editor
          v-model="description"
          api-key="v5n069t1i4u2b85xtio3jrmx4gktki4qys7iaksiafi1fzzq"
          tag-name="div"
        />
        <q-btn
          label="Save"
          :color="'secondary'"
          :disable="loading"
          :loading="loading"
          @click="patchUnitDescription"
        />
      </div>
      <div
        v-if="currentSubTab === 'Translations'"
        class="holders-popup__partner"
      >
        <q-select
          v-model="currentTranslation"
          standout
          :options="['en', 'es', 'ru', 'de']"
          label="Language"
          emit-value
          @update:model-value="getTranslatedDescription"
        />
        <editor
          v-if="currentTranslation"
          v-model="description"
          api-key="v5n069t1i4u2b85xtio3jrmx4gktki4qys7iaksiafi1fzzq"
          tag-name="div"
        />
        <q-btn
          label="Save"
          :color="'secondary'"
          :disable="loading"
          :loading="loading"
          @click="patchUnitDescription"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UnitResponseDTO } from "~/services/swagger/Api";
import { useAdminStore } from "~/store/admin";
import getRoute from "~/utilities/configs/apiMap";
import { apiGet } from "~/services/api";
import editor from "@tinymce/tinymce-vue";
import { Notification } from "~/services/notifications/toast";

type Tabs = "Description" | "Other";

type SubTabs = "Original" | "Translations";

interface TabsArray {
  label: Tabs;
  disable: boolean;
  subtabs: {
    label: SubTabs;
    value?: string;
    disable: boolean;
    changeAction?: () => void;
  }[];
  value?: string;
  changeAction?: () => void;
  isAlert?: boolean;
}

const route = useRoute();
const adminStore = useAdminStore();
const UNIT = adminStore.getUnitClass();

const description = ref("");

const currentTranslation = ref("");

const loading = ref(false);

const currentUnit = ref<UnitResponseDTO>();

const currentSubTabs = computed(
  () => tabs.find((item) => item.label === currentTab.value)!
);

const tabs: TabsArray[] = reactive([
  {
    subtabs: [
      {
        label: "Original",
        disable: false,
        value: "",
        changeAction: () => {
          description.value = "";
          nextTick(() => {
            description.value =
              tabs.find((item) => item.label === "Description")?.subtabs[0]
                .value ?? "";
          });
        },
      },
      {
        label: "Translations",
        disable: false,
        value: "",
        changeAction: () => {
          currentTranslation.value = "";
        },
      },
    ],
    label: "Description",
    disable: false,
    isAlert: true,
  },
  {
    subtabs: [],
    label: "Other",
    disable: true,
  },
]);

const currentTab = ref<Tabs>("Description");

const currentSubTab = ref<SubTabs>();

const handleTabsChange = (val: Tabs) => {
  const el = tabs.find((item) => item.label === val);
  if (typeof el?.changeAction === "function") {
    el.changeAction();
  }
};

const handleSubTabsChange = (val: SubTabs) => {
  const el = tabs.find((item) => item.label === currentTab.value);
  const subEl = el?.subtabs.find((item) => item.label === val);
  if (typeof subEl?.changeAction === "function") {
    subEl.changeAction();
  }
};

const getTranslatedDescription = async (lang?: string) => {
  loading.value = true;
  try {
    const url = getRoute({
      endpont: `get_unit`,
      id: route.params.id as string,
    });
    const token = useCookie("acc_token");

    const headers = {
      Authorization: `Bearer ${token.value}`,
      "accept-language": lang ?? "en",
    };
    if (!lang) {
      delete headers["accept-language"];
    }

    const res = await apiGet({
      url: url,
      headers: { ...headers },
    });
    currentUnit.value = res?.data.value as UnitResponseDTO;
    description.value = currentUnit.value.description ?? "None from server";
    if (!lang) {
      applyOriginalDes(currentUnit.value);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const applyOriginalDes = (unit: UnitResponseDTO) => {
  const des = tabs
    .find((item) => item.label === "Description")
    ?.subtabs.find((item) => item.label === "Original");
  if (des) {
    des.value = unit.description;
  }
};

const patchUnitDescription = async () => {
  const sucToast = new Notification({
    type: "system",
  });
  let accept = true;
  if (currentSubTab.value === "Original") {
    accept = confirm("It will drop all translations. Are you sure?");
    if (!accept) return;
    await UNIT.updatePropertyDescription(
      currentUnit.value!.id,
      description.value
    );
    sucToast.createNewToast("Succsess");

    return;
  }
  await UNIT.updatePropertyTranslation(currentUnit.value!.id, {
    lang: currentTranslation.value,
    text: description.value,
  });
  sucToast.createNewToast("Succsess");
};

// const TEMP_FLAG = ref(true);

onMounted(() => {
  getTranslatedDescription();
});
</script>

<style scoped lang="scss">
.holders-popup {
  position: relative;
  min-width: 600px;
  max-width: 100dvw;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--white-monochrome);
  box-shadow: var(--shadow-base);
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 20px;
  overflow: hidden;
  &__issuer,
  &__partner {
    width: 100%;
    display: grid;
    gap: 10px;
  }
  .add-item-btn {
    width: 100%;
  }
}

.close-butt {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 28px;
  height: 28px;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: var(--Monochrome-White, #e9ebf8);
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>

<template>
  <div class="benefits">
    <div class="benefits__title text-head-1 text-bold text-sora">{{ $t("main.m_benefits_title") }}</div>
    <div class="benefit-items">
      <div class="benefit">
        <NuxtImg src="/img/main/benefit-1.png" class="benefit__img--1" alt="" />

        <div class="benefit__bottom">
          <div class="benefit__title text-subtitle text-bold">
            {{ $t("main.m_benefit_first_title") }}
          </div>
          <div class="benefit__desc text-body-1">{{ $t("main.m_benefit_first_text") }}</div>
        </div>
      </div>
      <div class="benefit">
        <NuxtImg src="/img/main/benefit-2.png" class="benefit__img--2" alt="" />
        <div class="benefit__bottom">
          <div class="benefit__title text-subtitle text-bold">
            {{ $t("main.m_benefit_second_title") }}
          </div>
          <div class="benefit__desc text-body-1">
            {{ $t("main.m_benefit_second_text") }}
          </div>
        </div>
      </div>
      <div class="benefit">
        <NuxtImg src="/img/main/benefit-3.png" class="benefit__img--3" alt="" />
        <div class="benefit__bottom">
          <div class="benefit__title text-subtitle text-bold">
            {{ $t("main.m_benefit_third_title") }}
          </div>
          <div class="benefit__desc text-body-1">{{ $t("main.m_benefit_third_text") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.benefits {
  max-width: 1302px;
  width: 100%;
  margin: auto;
  margin-top: 78px;
  border-radius: 40px;
  padding: 95px 0 100px;
  background-image: url(~/assets/img/main/bg2.png);
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  border: 5px solid var(--Monochrome-Gray_40, #9EA7B966);
  &__title {
    text-align: center;
    margin-bottom: 55px;
  }
}
.benefit-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 984px;
  width: 100%;
  margin: auto;
}
.benefit {
  position: relative;
  display: flex;
  align-items: flex-end;
  position: relative;
  border: 8px solid var(--white-contrast);
  backdrop-filter: blur(14px);
  box-shadow: 4px 4px 12px 0px #12121229;

  border-radius: 24px;
  height: 224px;
  padding: 16px 16px 16px 16px;
  img {
    position: absolute;
  }
  &__img--1 {
    right: 4px;
    top: -20px;
    width: 33%;
    height: auto;
  }
  &__img--2 {
    top: -19px;
    right: 4px;
    width: 28%;
    height: auto;
  }
  &__img--3 {
    top: -20px;
    right: 2px;
    width: 33%;
    height: auto;
  }
  &__title {
    max-width: 70%;
    margin-bottom: 2px;
  }
}
@media (max-width: 63rem) {
  .benefits {
    margin-top: 59px;
    padding: 45px 0 45px;
    &__title {
      font-size: 22px;
      margin-bottom: 28px;
    }
  }
  .benefit-items {
    grid-template-columns: repeat(1, 1fr);
    gap: 29px;
  }
  .benefit {
    max-width: 313px;
    margin: auto;
    padding: 16px 34px 13px 16px;
  }
}
</style>

<template>
  <div class="promo">
    <div class="promo__title text-head-1 text-bold text-sora">
      {{ $t("main.m_promo_title") }}
    </div>
    <div class="promo__desc text-subtitle">{{ $t("main.m_promo_desc") }}</div>
    <div class="promo-items">
      <div class="promo-items__item">
        <div class="promo-items__item-icon">
          <base-icon name="base/Check" size="26px" filled />
        </div>
        <div class="promo-items__item-title text-body-1 text-bold">
          {{ $t("main.m_promo_1") }}
        </div>
      </div>
      <div class="promo-items__item">
        <div class="promo-items__item-icon">
          <base-icon name="base/Check" size="26px" filled />
        </div>
        <div class="promo-items__item-title text-body-1 text-bold">
          {{ $t("main.m_promo_2") }}
        </div>
      </div>
      <div class="promo-items__item">
        <div class="promo-items__item-icon">
          <base-icon name="base/Check" size="26px" filled />
        </div>
        <div class="promo-items__item-title text-body-1 text-bold">
          {{ $t("main.m_promo_3") }}
        </div>
      </div>
      <div class="promo-items__item">
        <div class="promo-items__item-icon">
          <base-icon name="base/Check" size="26px" filled />
        </div>
        <div class="promo-items__item-title text-body-1 text-bold">
          {{ $t("main.m_promo_4") }}
        </div>
      </div>
    </div>
    <div class="promo__img">
      <NuxtImg src="/img/main/promo.png" class="" alt="" />
    </div>
    <div class="promo__button">
      <base-button
        variant="dark_ghost"
        :text="$t('main.m_promo_btn')"
        size="lg"
        bold-text
        text-size="landing"
        class="btn-portfolio"
        @click.stop="goToPortfolio"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const localePath = useLocalePath();

const goToPortfolio = async () => {
  return navigateTo({
    path: localePath("/portfolio"),
  });
};
</script>
<style scoped lang="scss">
.promo {
  max-width: 1302px;
  width: 100%;
  margin: auto;
  padding: 74px 0 0;
  &__title {
    text-align: center;
    margin-bottom: 20px;
  }
  &__desc {
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 31px;
  }
  &__img {
    max-width: 855px;
    margin: 0 auto 30px;
  }
  &__button {
    display: flex;
    justify-content: center;
  }
}
.promo-items {
  display: flex;
  gap: 60px;
  max-width: fit-content;
  margin: 0 auto 27px;
  flex-wrap: wrap;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }
  &__item-icon {
    margin-bottom: 12px;
  }
}
@media (max-width: 63rem) {
  .promo {
    margin-top: 59px;
    padding: 45px 0 45px;
    &__title {
      font-size: 22px;
      margin-bottom: 20px;
      text-align: left;
    }
    &__desc {
      font-size: 14px;
      margin-bottom: 31px;
      text-align: left;
    }
    &__img {
      max-width: 345px;
      margin: 0 auto 30px;
      margin-bottom: 28px;
      img {
        max-width: 336px;
        height: auto;
      }
    }
    &__button {
      button {
        width: 100%;
        height: 48px;
      }
    }
  }
  .promo-items {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    display: flex;
    gap: 39px;
    margin: 0 auto 35px;
    flex-wrap: wrap;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
    }
    &__item-icon {
      margin-bottom: 0;
      margin-right: 12px;
    }
    &__item-title {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>

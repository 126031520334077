<template>
  <div v-if="dataComputed?.length" class="portfolio-inv-chart">
    <span class="title">
      <p class="text-body-2">Total Investment</p>
      <!-- <nav class="filters">
        <button
          v-for="item in filters"
          :class="[
            'filters__item',
            { 'filters__item--active': item.emit === currentFilter },
          ]"
          @click="handleFilterClick(item.emit)"
        >
          {{ item.label }}
        </button>
      </nav> -->
    </span>
    <div class="chart-container">
      <canvas ref="chartCanvas" />
    </div>
  </div>
  <search-page-no-objects v-else style="max-width: 652px; height: 312px">
    <template #default>
      <span style="max-width: 362px; text-align: center">
        <h3
          class="text-head-2 text-bold mobile-font--h4 text-black-monochrome text-sora"
        >
          {{ $t("profile.p_no_portfolio.no_chart") }}
        </h3>
      </span>
    </template>
  </search-page-no-objects>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import type { TotalInvestmentChartDTO } from "~/services/swagger/Api";
// Регистрация компонентов Chart.js
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler
);

interface IProps {
  chart: TotalInvestmentChartDTO[] | undefined;
}

const props = defineProps<IProps>();

const emit = defineEmits(["filter-change"]);

const chartCanvas = ref<HTMLCanvasElement | null>(null);

const labelsComputed = computed(() => {
  return props.chart?.map((item) => {
    const date = new Date(item.date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year.toString().slice(2, 4)}`;
  });
});

const dataComputed = computed(() => {
  return props.chart?.map((item) => item.total || 0);
});

const chartData = ref({
  labels: [...(labelsComputed.value ?? [])],
  datasets: [
    {
      label: "Investment Growth",
      data: [...(dataComputed.value ?? [])],
      fill: true,
      borderColor: "rgba(103, 66, 208, 0.8)",
      borderWidth: 2,
      tension: 0.5,
      pointRadius: 0,
      backgroundColor: null,
    },
  ],
});

const chartOptions = ref({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      position: "right",
      grid: {
        color: "rgba(200, 200, 200, 0.2)",
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
});

// interface Filter {
//   label: string;
//   emit: "week" | "month" | "year";
// }

// const filters: Filter[] = [
//   { label: "Week", emit: "week" },
//   { label: "Month", emit: "month" },
//   { label: "Year", emit: "year" },
// ];

// const currentFilter = ref<Filter["emit"]>("month");

// const handleFilterClick = (val: Filter["emit"]) => {
//   currentFilter.value = val;
//   emit("filter-change", val);
// };

onMounted(() => {
  const canvas = chartCanvas.value as HTMLCanvasElement;
  if (canvas) {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.clientHeight);
      gradient.addColorStop(0, "rgba(103, 66, 208, 0.8)");
      gradient.addColorStop(1, "rgba(243, 247, 254, 0.8)");
      chartData.value.datasets[0].backgroundColor = gradient;
      // Создаем график
      nextTick(() => {
        new ChartJS(canvas, {
          type: "line",
          data: chartData.value,
          options: chartOptions.value,
        });
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.portfolio-inv-chart {
  padding: 24px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  max-width: 652px;
  width: 100%;
  height: 312px;
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 24px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .filters {
      display: flex;
      align-items: flex-end;
      gap: 6px;
      &__item {
        @include drop-button-styles;
        display: flex;
        height: 24px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid;
        background-color: transparent;
        border-color: var(--gray-monochrome-40);
        color: #121212;
        font-family: "DM Sans";
        font-size: 12px;
        font-weight: 400;
        transition:
          border-color 0.3s ease,
          background-color 0.3s ease;
        will-change: border-color, background-color;
        &--active {
          border-color: var(--black-monochrome-60);
          background-color: var(--white-monochrome);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
  .chart-container {
    position: relative;
    width: 600px;
    height: 210px;
    canvas {
      max-height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .portfolio-inv-chart {
    max-width: 345px;
    .chart-container {
      width: 300px;
    }
  }
}
</style>

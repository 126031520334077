<template>
  <div class="unit-block-wrapper unit-info-block">
    <slot name="mobilt-swiper" />
    <span class="title-info">
      <h5 class="text-subtitle text-bold text-sans">
        {{ props.propertyInfo?.name ?? $t("search_card.fr_noname") }}
      </h5>
      <span class="title-info-loc">
        <span class="location">
          <button @click="openGoogleMap(props.propertyInfo?.coords)">
            <base-icon name="base/MapPin" size="16px" />
          </button>
          <button
            class="text-body-2 text-sans text-regular text-black-monochrome-60 loc-butt"
            :class="{ 'loc-butt': props.propertyInfo?.location }"
            @click="openGoogleMap(props.propertyInfo?.coords)"
          >
            {{ props.propertyInfo?.location ?? $t("search_card.fr_loc") }}
          </button>
        </span>
        <span class="rating" @click="sendRating(props.propertyInfo?.rating)">
          <base-icon name="base/StarFull" size="13px" color="#F59914" />
          <p v-if="props.propertyInfo?.rating" class="text-body-2 text-sans text-regular">
            {{ props.propertyInfo?.rating }}
          </p>
          <p v-else class="text-body-2 text-sans text-regular">-</p>
        </span>
      </span>
    </span>
    <q-separator class="separator" />
    <span class="list-gridbox">
      <span class="list-flexbox">
        <span class="wrapper-flex">
          <p class="text-body-1 text-sans text-regular">
            {{ getPriceTitle }}
          </p>
        </span>
        <span class="text-body-1 text-sans text-bold" style="display: flex; align-items: center">
          <p v-if="isLocalCurrency">≈</p>

          {{
            BASE_CURRENCY.symbol +
            formatPrice(props.propertyInfo?.priceUSD, true, isLocalCurrency, {
              price: props.propertyInfo?.price,
              currency: props.propertyInfo?.currency,
            })
          }}
        </span>
      </span>
      <span v-if="isLocalCurrency" class="list-flexbox">
        <span class="wrapper-flex" style="margin-left: auto">
          <span class="text-body-2 text-sans text-regular text-black-monochrome-60">
            {{
              currentCurrency.symbol + formatPrice(props.propertyInfo?.price)
            }}
          </span>
          <base-tooltip
            :tooltip-text="`${$t('search_card.fr_operates', { currency: currentCurrency.code })}<br> ${$t('search_card.fr_rate')} `"
            style="margin-bottom: 1px"
          >
            <template #icon>
              <suspense>
                <base-icon
                  name="base/Info"
                  size="16px"
                  color="var(--black-monochrome-60)"
                />
              </suspense> </template
          ></base-tooltip>
        </span>
      </span>
    </span>
    <span class="list-gridbox">
      <span class="list-flexbox">
        <span class="wrapper-flex">
          <p class="text-body-1 text-sans text-regular">
            {{ $t("search_filters.fr_price") }}
          </p>
        </span>
        <span class="text-body-1 text-sans text-bold" style="display: flex; align-items: center">
          <p v-if="isLocalCurrency">≈</p>

          {{
            BASE_CURRENCY.symbol +
            formatPrice(
              props.propertyInfo?.tokenomics?.priceUSD,
              false,
              isLocalCurrency,
              {
                price: props.propertyInfo?.tokenomics?.price,
                currency: props.propertyInfo?.currency,
              }
            )
          }}
        </span>
      </span>
      <span v-if="isLocalCurrency" class="list-flexbox">
        <span class="wrapper-flex" style="margin-left: auto">
          <span style="display: flex" class="text-body-2 text-sans text-regular text-black-monochrome-60">
            {{
              currentCurrency.symbol +
              formatPrice(props.propertyInfo?.tokenomics?.price)
            }}
          </span>
          <base-tooltip
            :tooltip-text="
              $t('search_card.fr_operates', { currency: currentCurrency.code })
            "
            style="margin-bottom: 1px"
          >
            <template #icon>
              <suspense>
                <base-icon
                  name="base/Info"
                  size="16px"
                  color="var(--black-monochrome-60)"
                />
              </suspense> </template
          ></base-tooltip>
        </span>
      </span>
    </span>
    <span class="list-gridbox">
      <span class="list-flexbox">
        <span class="wrapper-flex">
          <p class="text-body-1 text-sans text-regular">
            {{ $t("search_filters.fr_min_invest-long") }}
          </p>
        </span>
        <span class="text-body-1 text-sans text-bold" style="display: flex; align-items: center">
          <p v-if="isLocalCurrency">≈</p>

          {{
            BASE_CURRENCY.symbol +
            formatPrice(
              props.propertyInfo?.minimumInvestmentUSD,
              false,
              isLocalCurrency,
              {
                price: props.propertyInfo?.minimumInvestment,
                currency: props.propertyInfo?.currency,
              }
            )
          }}
        </span>
      </span>
      <span v-if="isLocalCurrency" class="list-flexbox">
        <span class="wrapper-flex" style="margin-left: auto">
          <span style="display: flex" class="text-body-2 text-sans text-regular text-black-monochrome-60">
            {{
              currentCurrency.symbol +
              formatPrice(props.propertyInfo?.minimumInvestment)
            }}
          </span>
          <base-tooltip
            :tooltip-text="
              $t('search_card.fr_operates', { currency: currentCurrency.code })
            "
            style="margin-bottom: 1px"
          >
            <template #icon>
              <suspense>
                <base-icon
                  name="base/Info"
                  size="16px"
                  color="var(--black-monochrome-60)"
                />
              </suspense> </template
          ></base-tooltip>
        </span>
      </span>
    </span>
    <q-separator class="separator" />
    <span v-if="props.type !== 'lands'" class="list-flexbox">
      <span class="wrapper-flex">
        <p class="text-body-1 text-sans text-regular">IRR</p>
        <base-tooltip :tooltip-text="$t('finance.IRR')">
          <template #icon>
            <suspense>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </suspense> </template
        ></base-tooltip>
      </span>
      <p v-if="props.propertyInfo?.irr" class="text-body-1 text-sans text-bold">
        {{ formatPercentage(props.propertyInfo?.irr) }}%
      </p>
      <base-apr-irr v-else @click="openIssuer"/>
    </span>
    <span v-if="props.type !== 'lands'" class="list-flexbox">
      <span class="wrapper-flex">
        <p class="text-body-1 text-sans text-regular">APR</p>
        <base-tooltip :tooltip-text="$t('finance.APR')">
          <template #icon>
            <suspense>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </suspense> </template
        ></base-tooltip>
      </span>
      <p v-if="props.propertyInfo?.apr" class="text-body-1 text-sans text-bold">
        {{ formatPercentage(props.propertyInfo?.apr) }}%
      </p>
      <base-apr-irr v-else @click="openIssuer"/>
    </span>

    <span class="list-flexbox">
      <span class="wrapper-flex">
        <p class="text-body-1 text-sans text-regular">
          {{ $t("search_card.fr_growth") }}
        </p>
      </span>
      <p class="text-body-1 text-sans text-bold">
        {{
          formatPercentage(
            props.propertyInfo?.finance?.projectTotalIncome?.subfields
              ?.projectionAppreciation
          )
        }}%
      </p>
    </span>
    <q-separator class="separator" />
    <span class="list-flexbox">
      <span class="wrapper-flex">
        <p class="text-body-1 text-sans text-regular">
          {{ $t("search_card.fr_tokens_avail") }}
        </p>
      </span>
      <p class="text-body-1 text-sans text-bold">
        {{ formatPercentage(isTokenAvailableSupplyPercentage) }}%
      </p>
    </span>
  </div>
</template>

<script setup lang="ts">
import openGoogleMap from "~/utilities/helpers/map/openGoogleMap";
import {
  formatPrice,
  formatPercentage,
} from "~/utilities/helpers/format-data/number";
import { getCurrentCurrency, BASE_CURRENCY } from "~/composables/CURRENCIES";

import type { UnitResponseDTO } from "~/services/swagger/Api";
import type { PropertyTypes } from "~/components/base/navigator/types";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import eventBus from "~/utilities/composables/eventBus";

interface IProps {
  propertyInfo: UnitResponseDTO;
  type: PropertyTypes;
}

const props = defineProps<IProps>();
const { t } = useI18n();

const isLocalCurrency = computed(
  () => props.propertyInfo?.currency !== BASE_CURRENCY.value.code
);

const isTokenAvailableSupplyPercentage = computed(() => {
  if (
    props.propertyInfo.tokenomics?.availableSupplyPercentage &&
    !isNaN(
      parseInt(props.propertyInfo.tokenomics?.availableSupplyPercentage.toString())
    )
  ) {
    return props.propertyInfo.tokenomics?.availableSupplyPercentage <= 100
      ? props.propertyInfo.tokenomics?.availableSupplyPercentage 
      : 100;
  }
});

const currentCurrency = computed(() =>
  getCurrentCurrency(props.propertyInfo?.currency)
);

const getPriceTitle = computed(() => {
  switch (props.type) {
    case "estates":
      return t("search_card.fr_price");
    case "lands":
      return t("search_card.fr_price_land");
  }
});

const openIssuer = () => {
  redirectByProviderName({
    providerName: props.propertyInfo?.provider?.name,
    providerLink: props.propertyInfo?.provider?.url,
    utmLink: props.propertyInfo?.referralLink?.link,
    externalUrl: props.propertyInfo?.externalUrl,
    source: "directory__open_ref",
    images: props.propertyInfo?.files,
    logoFile: props.propertyInfo?.provider?.logoFile?.url,
  });
};

const sendRating = (rating: number | undefined) =>{
  eventBus.emit("send-rating", rating);
}
</script>

<style scoped lang="scss">
.unit-info-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  max-width: 314px;
  height: fit-content;
  .separator {
    width: 100%;
  }
  .title-info {
    width: 100%;
    display: grid;
    gap: 4px;
    align-items: center;
    justify-items: flex-start;
    &-loc {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 15px;
      .location {
        display: flex;
        align-items: center;
        gap: 4px;
        button {
          @include drop-button-styles;
        }
        .loc-butt {
          max-width: 198px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .rating {
        display: flex;
        align-items: center;
        gap: 6px;
        line-height: 1;
        cursor: pointer;
      }
    }
  }
  .list-gridbox {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: 4px;
  }
  .list-flexbox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    .wrapper-flex {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 75rem) {
  .unit-info-block .title-info-loc .location .loc-butt{
    max-width: 230px;
  }
}
</style>

import { BASE_LOCALE } from "~/utilities/constants/LOCALES";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";

export const formatPrice = (
  value: number | string | null | undefined,
  abbreviate?: boolean,
  convert?: boolean,
  isOrigin?: { price: number | undefined; currency: string | undefined },
  customFraction?: number
): string => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    isNaN(Number(value))
  ) {
    return "0.0";
  }

  let numericValue = Number(value);
  const abbNumber = HUGE_CURRENCY.includes(BASE_CURRENCY.value.code)
    ? 10000
    : 100000000;
  const sameOrigin = isOrigin?.currency === BASE_CURRENCY.value.code;

  if (convert && !sameOrigin) {
    numericValue *= BASE_CURRENCY.value.rate;
  } else if (sameOrigin) {
    numericValue = isOrigin?.price ?? numericValue;
  }

  if (abbreviate && numericValue > abbNumber) {
    return abbreviateNumber(numericValue);
  }
  const fraction = customFraction
    ? customFraction
    : numericValue >= 10000
      ? 0
      : 1;

  const formatOptions = {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
    roundingMode: "trunc",
  };

  return new Intl.NumberFormat(BASE_LOCALE, formatOptions).format(numericValue);
};

export const formatPercentage = (
  value: number | string | undefined | null
): string => {
  if (value === undefined || value === null) return "0.0";

  let numericValue: number;

  if (typeof value === "string") {
    numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  } else {
    numericValue = value;
  }
  if (numericValue >= 100) {
    return "100";
  }

  return numericValue.toFixed(1);
};

export const formatCurrency = (value: number, currency: string) => {
  return new Intl.NumberFormat(BASE_LOCALE, {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
  }).format(value);
};

export const abbreviateNumber = (
  num: number | string | null | undefined,
  minAbbreviate: "K" | "M" | "B" | "T" | "Q" | "Qi" | "Sx" = "K"
): string => {
  if (!num) return "0";
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  const units = [
    { value: 1e21, symbol: "Sx" },
    { value: 1e18, symbol: "Qi" },
    { value: 1e15, symbol: "Q" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  const minValue =
    units.find((unit) => unit.symbol === minAbbreviate)?.value || 1;

  for (const { value, symbol } of units) {
    if (num >= value && value >= minValue) {
      return (num / value).toFixed(1).replace(/\.0$/, "") + symbol;
    }
  }

  return num.toString();
};

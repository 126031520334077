<template>
  <section
    :class="['base-faq', { open: isOpen }]"
    v-bind="attrs"
    @click.stop="toggleDetails"
  >
    <div class="base-faq--flex">
      <h4 ref="headerRef" class="text-subtitle">
        <slot name="title" />
      </h4>
      <button
        :class="['plusminus', { active: isOpen }]"
        @click.stop="toggleDetails"
      />
    </div>
    <div ref="detailsRef" class="base-faq--details">
      <p class="text-body-1 mobile-font--b5">
        <slot name="details" />
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAttrs } from "vue"

const attrs = useAttrs()
const isOpen = ref(false)
const headerRef = ref<HTMLElement | undefined>()
const detailsRef = ref<HTMLElement | undefined>()

const headerHeight = computed(() => {
  if (headerRef.value && headerRef.value instanceof HTMLElement) {
    return headerRef.value.offsetHeight + 32 + "px" //+ padding
  }
})

const detailsHeight = computed(() => {
  if (
    detailsRef.value &&
    headerRef.value &&
    detailsRef.value instanceof HTMLElement &&
    headerRef.value instanceof HTMLElement
  ) {
    return (
      detailsRef.value.offsetHeight +
      headerRef.value.offsetHeight +
      20 +
      32 +
      "px"
    ) //details + header + gap + padding
  }
})

function toggleDetails() {
  isOpen.value = !isOpen.value
}
</script>

<style scoped lang="scss">
.base-faq {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: auto;
  height: auto;
  max-height: v-bind(headerHeight);
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: var(--shadow-light);
  background: var(--white-contrast);
  cursor: pointer;
  &--flex {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600 !important;
    .plusminus {
      position: relative;
      width: 22px;
      height: 22px;
      cursor: pointer;
      padding: 0;
      margin: 0;
      outline: none;
      background-color: transparent;
      border: none;
      &.active {
        &:before {
          transform: translatey(-50%) rotate(-90deg);
          opacity: 0;
        }
        &:after {
          transform: translatey(-50%) rotate(0);
        }
      }

      &:before,
      &:after {
        content: "";
        display: block;
        background-color: var(--black-monochrome);
        position: absolute;
        top: 50%;
        left: 0;
        transition: 0.35s;
        width: 22px;
        height: 2px;
        border-radius: 10px;
      }

      &:before {
        transform: translatey(-50%);
      }

      &:after {
        transform: translatey(-50%) rotate(90deg);
      }
    }
  }
  h4 {
    word-break: normal;
    color: var(--black-monochrome);
    cursor: default;
  }
  &--details {
    word-break: normal;
    color: var(--black-monochrome-60);
    cursor: default;
  }
}
.open {
  max-height: v-bind(detailsHeight);
}

@media (max-width: 32rem) {
  @include mobile-fonts;
}
@media (max-width: 88rem) {
  .base-faq {
    padding: 20px 24px 42px;
  }
  .font--b2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
  }
}
</style>

<template>
  <div class="list-flexbox">
    <span class="wrapper-flex" style="margin-left: auto">
      <span class="font--b5 text-black-monochrome-60">
        {{ formattedValue }}
      </span>
      &nbsp;
      <base-tooltip
        :tooltip-text="`${$t('search_card.fr_operates', { currency: currentCurrency.code })}<br> ${$t('search_card.fr_rate')}`"
        style="margin-bottom: 1px"
      >
        <template #icon>
          <suspense>
            <base-icon
              name="base/Info"
              size="16px"
              color="var(--black-monochrome-60)"
            />
          </suspense>
        </template>
      </base-tooltip>
    </span>
  </div>
</template>

<script setup lang="ts">
import { formatPrice } from "~/utilities/helpers/format-data/number";
import { getCurrentCurrency } from "~/composables/CURRENCIES";

interface IProps {
  currencyValue: number | undefined;
  currency: string | undefined;
  devide?: boolean;
}

const props = defineProps<IProps>();

const currentCurrency = computed(() => getCurrentCurrency(props.currency));

const calculateFormattedValue = (value: number | undefined): string => {
  const calculatedValue = value;

  if (isNaN(calculatedValue)) {
    return `${currentCurrency.value.symbol} 0.00`;
  }

  return `${currentCurrency.value.symbol} ${formatPrice(calculatedValue)}`;
};

const formattedValue = computed(() =>
  calculateFormattedValue(props.currencyValue)
);
</script>

<style scoped>
.list-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper-flex {
  display: flex;
  align-items: center;
}
</style>

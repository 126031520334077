<template>
  <div class="portfolio-wrapper">
    <div class="portfolio-wrapper__header">
      <span class="title">
        <span class="title text-subtitle text-regular">Portfolio</span>
      </span>
      <base-button
        v-if="isAddTokenButt"
        text="Add token"
        :variant="'dark_ghost'"
        size="sg"
        icon-name="base/PlusPrime"
        icon-position="left"
        icon-size="20px"
        icon-filled
        class="animate__animated animate__fadeIn"
        @click="handleAddToken"
      />
    </div>

    <profile-portfolio
      ref="profilePortfolioRef"
      @start-portfolio="handleShowButton"
    />
  </div>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { ProfilePortfolio } from "#components";

const usePopUpServices = PopUpServices();

const isAddTokenButt = ref(false);

const profilePortfolioRef = ref<typeof ProfilePortfolio>();

const handleShowButton = (showButt: boolean) => {
  isAddTokenButt.value = showButt;
};

const handleAddToken = async () => {
  const res = await usePopUpServices.callPopUp({
    componentName: "portfolio-add-token-popup",
    persistent: false,
    data: {},
  });
  if (res) {
    profilePortfolioRef.value?.initPortfolio();
  }
};
</script>

<style scoped lang="scss">
.portfolio-wrapper {
  display: grid;
  align-items: flex-start;
  justify-items: center;
  padding-top: 38px;
  padding-bottom: 100%;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  max-width: 991px;
  gap: 45px;
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .title {
      width: 100%;
      border-bottom: 1px solid var(--gray-40);
      display: flex;
      justify-content: space-between;
      span {
        display: block;
        width: fit-content;
        height: 100%;
        border-bottom: 2px solid var(--violet-main);
        padding-bottom: 10px;
        cursor: default;
      }
    }
  }
}
@media (max-width: 65rem) {
  .portfolio-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>

<template>
  <main class="main-content">
    <main-hero />
    <!-- <main-trending /> -->
    <!-- <main-new-offers /> -->
    <!-- <main-find /> -->
    <main-steps />
    <main-featured />
    <main-benefits />
    <main-promo />
    <main-tokenization />
    <main-f-a-q />
  </main>
</template>

<script setup lang="ts">
import { updateCommonSeo } from "~/services/SEO/updateCommonSeo";

const mainHero = defineAsyncComponent(
  () => import("~/components/Main/Hero.vue")
);

const mainSteps = defineAsyncComponent(
  () => import("~/components/Main/Steps.vue")
);
const mainBenefits = defineAsyncComponent(
  () => import("~/components/Main/Benefits.vue")
);
const mainPromo = defineAsyncComponent(
  () => import("~/components/Main/Promo.vue")
);

const mainFAQ = defineAsyncComponent(() => import("~/components/Main/FAQ.vue"));

definePageMeta({
  layout: "default",
});

updateCommonSeo();


onMounted(async ()=>{
})
</script>

<style scoped lang="scss">
.main-content {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  max-width: 1302px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <div v-if="props.tokenDistribution?.countries?.length" class="portfolio__investments">
    <span class="font--b1 portfolio__investments--span">
      {{ props.tokenDistribution?.countries?.length }} Countries
    </span>
    <div class="investments">
      <Suspense>
        <q-tabs dense class="custom-hot-tabs" align="justify">
          <q-tab
            v-for="country in props.tokenDistribution?.countries"
            :key="country.countryId"
            :name="country.countryId"
            no-caps
            :class="{ 'active-tab': currentCountryTab === country.countryId }"
            @click="handleCountryTabClick(country.countryId)"
          >
            <span class="flag-flex">
              <base-icon
                :name="`flags/${countryNames[country.countryId].toLowerCase().replace(/ /g, '_')}`"
                filled
                :size="!$device.isMobile ? '20px' : '16px'"
              />
              <p
                class="font--b5 mobile-font--b7"
                :class="{
                  'font--b4-semi-bold': currentCountryTab === country.countryId,
                }"
              >
                {{ countryNames[country.countryId] }}
              </p>
            </span>
            <p class="font--b4-semi-bold mobile-font--b7">
              ${{ formatPrice(country.totalTokens) }}
            </p>
          </q-tab>
        </q-tabs>
      </Suspense>
      <base-separator />
    </div>
  </div>
  <div v-if="props.tokenDistribution?.providers?.length" class="portfolio__investments">
    <p class="font--b1 portfolio__investments--span">
      {{ $t('unit_page.un_partners',props.tokenDistribution?.providers?.length) }}
    </p>
    <div class="investments">
      <Suspense>
        <q-tabs dense class="custom-hot-tabs" align="justify">
          <q-tab
            v-for="provider in props.tokenDistribution?.providers"
            :key="provider.id"
            :name="provider.id"
            no-caps
            :class="{ 'active-tab': currentPartnerTab === provider.id }"
            @click="handlePartnerTabClick(provider.id)"
          >
            <span class="flag-flex">
              <img :src="provider.logoFile" alt="" >
            </span>
            <p class="font--b4-semi-bold mobile-font--b7">
              ${{ formatPrice(provider.totalTokens) }}
            </p>
          </q-tab>
        </q-tabs>
      </Suspense>
      <base-separator />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TokenDistribution } from "~/components/profile/portfolio/index.vue";
import { countryNames } from "~/utilities/constants/countryCodes";
import { formatPrice } from "~/utilities/helpers/format-data/number";

interface IProps {
  tokenDistribution: TokenDistribution | null;
}

const props = defineProps<IProps>();

const currentCountryTab = ref("");
const currentPartnerTab = ref("");

const handleCountryTabClick = (val: string) => {
  currentCountryTab.value = val;
};

const handlePartnerTabClick = (val: string) => {
  currentPartnerTab.value = val;
};

</script>

<style scoped lang="scss">
.portfolio__investments {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  &--span {
    min-width: 110px;
  }
  p {
    white-space: nowrap;
  }
  .investments {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    :deep(.custom-hot-tabs) {
      width: 100%;
      .q-tabs__content {
        gap: 12px;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .q-tab__indicator {
        background: transparent;
      }
      .active-tab {
        .q-tab__indicator {
          opacity: 1;
          background: var(--violet-main);
        }
      }

      .q-tab__content {
        gap: 8px;
        padding: 0;
      }
    }
    :deep(.custom-hot-tabs) {
      .q-tab {
        display: flex;
        max-width: 150px;
        padding: 8px 0px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 16px 16px 0px 0px;
        background: transparent;
        .flag-flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          img {
            height: 17px;
          }
        }
      }
      .active-tab {
        background: var(--white-contrast);
      }
    }
  }
}
</style>

<template>
  <div class="base-reviews__item">
    <span v-if="props.isMyOwn" class="my-review">
      <base-icon size="12px" filled name="base/Pinned" />
      <p class="font--b6">{{ $t('review.pinned') }}</p>
    </span>
    <div class="base-reviews__item-head">
      <span class="user">
        <q-avatar size="48px">
          <q-img
            :src="props.userAvatar || defaultAvatar"
            fit="cover"
            style="max-width: 48px; height: 48px"
          />
        </q-avatar>

        <h5 class="text-body-1 text-bold user-name">
          {{ props.userName || "Unknown user" }}
        </h5>
      </span>
      <span class="stars">
        <span class="stars-item">
          <q-rating
            v-model="rating"
            max="5"
            size="24px"
            color="#F59914"
            :icon="`img:${starGray}`"
            :icon-selected="`img:${starSelected}`"
            :icon-half="`img:${starHalf}`"
            no-dimming
            readonly
          />
          <p class="text-body-1 text-bold" style="margin-top: 3px">
            {{ $t("review.stars", { amount: rating }) }}
          </p>
        </span>
        <span class="stars-date text-body-3 text-regular">{{
          getRelativeTime(props.date)
        }}</span>
      </span>
    </div>
    <div class="base-reviews__item-body">
      <p class="text-body-2 text-regular">
        {{ props.text }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import starGray from "~/assets/icons/base/ReviewStarGray.svg";
import starSelected from "~/assets/icons/base/ReviewStar.svg";
import starHalf from "~/assets/icons/base/ReviewStarHalf.svg";
import { getRelativeTime } from "~/utilities/helpers/format-data/date";
import defaultAvatar from "~/assets/icons/avatars/Unknown.svg";

interface IProps {
  text: string;
  date: string;
  rating: number;
  userName?: string;
  userAvatar?: string;
  isMyOwn?: boolean;
}

const props = defineProps<IProps>();

const rating = ref(props.rating);
</script>

<style scoped lang="scss">
.base-reviews__item {
  position: relative;
  width: 100%;
  max-width: 510px;
  height: fit-content;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  gap: 12px;
  p,
  h5 {
    cursor: default;
  }
  .my-review {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }
  &-head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 9px;
      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
      }
    }
    .stars {
      display: grid;
      align-items: center;
      justify-items: flex-end;
      gap: 7px;
      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      &-date {
      }
    }
  }
  &-body {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: 8px;
    h5 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }
    p {
      word-break: normal;
    }
  }
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .base-reviews__item {
    &-head {
      flex-direction: column;
      align-items: flex-start;
      .stars {
        display: flex;
      }
    }
  }
}
</style>

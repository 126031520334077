<template>
  <div class="wrapper" :style="{ height: getPopUpH }">
    <section v-if="!isSentPopUp" class="base-leave-review__popup">
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
      <div class="base-leave-review__popup--header">
        <h5 class="title-underlined text-head-2 text-bold text-black-monochrome">
          {{ $t("review.r_leave") }}
        </h5>
      </div>
      <div class="base-leave-review__popup--rating">
        <span class="rating-house">
          <q-img
            v-for="(img, index) in imgArray"
            :src="img.file.url"
            :alt="noImage"
            :class="[
              `rating-house--img${index}`,
              { 'rating-house--alone': imgArray.length === 1 },
            ]"
          />
        </span>
        <p class="text-body-1 text-bold">
          {{
            $t("review.r_rate", {
              name: props.data.propName,
            })
          }}
        </p>
        <q-rating
          v-model="rating"
          max="5"
          size="40px"
          :icon="`img:${LeaveReviewGray}`"
          :icon-selected="`img:${LeaveReviewActive}`"
          no-dimming
          @update:model-value="
            (value: 1 | 2 | 3 | 4 | 5) => {
              $nextTick(() => {
                rating = value || 1;
              });
            }
          "
        />
      </div>
      <base-separator />
      <div class="base-leave-review__popup--user">
        <span v-if="!useUserStore.isInSystem" class="gridbox base-input">
          <label class="font--b3-m text-black-monochrome"
            >{{ $t("review.r_name") }}<sup>*</sup></label
          >
          <q-input
            ref="userNameRef"
            v-model="review.userName"
            :placeholder="$t('login.log_type')"
            outlined
            type="text"
            :maxlength="20"
            lazy-rules
            :rules="[
              (val: string | any[]) =>
                val.length >= 3 || $t('errors.login.chars_3'),
            ]"
            @update:model-value="
              (val: string | number | null) => {
                $nextTick(() => {
                  review.userName = onlyLettersAndSpaces(val);
                });
              }
            "
          />
        </span>
        <span class="gridbox base-textarea">
          <label class="text-body-1 text-black-monochrome">
            <p>
              {{ $t("review.r_review") }}
            </p>
            <p class="text-black-monochrome-60">(optional)</p>
          </label>
          <q-input
            v-model="review.userReview"
            :placeholder="$t('review.r_feed')"
            outlined
            rows="2"
            type="textarea"
            :maxlength="300"
            lazy-rules
          />
        </span>
      </div>
      <div class="base-leave-review__popup--submit">
        <base-button
          :text="$t('footer.f_submit')"
          size="lg"
          variant="dark"
          :loading="buttLoading"
          @click="handleLeaveReview"
        />
      </div>
    </section>
    <section v-else class="base-leave-review__popup popup-moderating">
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
      <div class="base-leave-review__popup--header">
        <h5 class="title-underlined font--h4">
          {{ $t("review.r_left_feedback") }}
        </h5>
      </div>
      <q-rating
        v-model="rating"
        max="5"
        size="20px"
        :icon="`img:${star}`"
        :icon-selected="`img:${starFull}`"
        no-dimming
        readonly
      />
      <p class="font--b4-semi-bold">{{ $t("review.r_under") }}</p>
      <span class="popup-moderating--controls">
        <base-button
          v-close-popup
          :text="$t('review.r_stay')"
          size="lg"
          variant="dark"
        />
        <base-button
          :text="$t('review.r_go_main')"
          size="lg"
          variant="light_bordered"
          @click="navigateToMainPage"
        />
      </span>
    </section>
  </div>
</template>

<script setup lang="ts">
import LeaveReviewGray from "~/assets/icons/base/LeaveReviewGray.svg";
import LeaveReviewActive from "~/assets/icons/base/LeaveReviewActive.svg";
import starFull from "~/assets/icons/base/StarFull.svg";
import star from "~/assets/icons/base/Star.svg";
import { userStore } from "~/store/user";
import type { CreateReviewDTO } from "~/services/swagger/Api";
import { getServerStars } from "~/utilities/helpers/review/convertServerRating";
import type { ItemFiles } from "~/types/search-page-items";
import noImage from "~/assets/img/no-image.svg";
import { onlyLettersAndSpaces } from "~/utilities/helpers/format-data/onlyLettersAndSpaces";
import { showStarsConfetti } from "~/utilities/helpers/treats/confetti";

interface IProps {
  data: {
    userName: string;
    rating: 1 | 2 | 3 | 4 | 5;
    propId: string;
    propName: string;
    propImgs: ItemFiles[];
    reviewText?: string;
    reviewId?: string;
    isUpdate?: boolean;
  };
}

const props = defineProps<IProps>();
const emit = defineEmits(["hide_popup"]);
const userNameRef = ref<HTMLElement | null>(null);
const localePath = useLocalePath();

const useUserStore = userStore();

const rating = ref<1 | 2 | 3 | 4 | 5>(props.data.rating || 1);
const buttLoading = ref(false);
const isSentPopUp = ref(false);
const isUpdateFlag = computed(() => props.data?.isUpdate);

const imgArray = computed(() =>
  props.data?.propImgs?.length
    ? props.data?.propImgs?.slice(0, 3)
    : [{ file: { url: noImage } }]
);

const getPopUpH = computed(() => {
  return !isSentPopUp.value && !useUserStore.isInSystem
    ? "655px"
    : useUserStore.isInSystem && !isSentPopUp.value
      ? "560px"
      : "350px";
});

const review = reactive({
  userName: "",
  userReview: "",
});

const navigateToMainPage = () => {
  return navigateTo(localePath("/"));
};

const handleLeaveReview = async () => {
  if (isUpdateFlag.value) {
    handleUpdateReview();
    return;
  }
  const obj: CreateReviewDTO = {
    text: review.userReview,
    rating: getServerStars(rating.value),
  };
  if (!useUserStore.isInSystem) {
    userNameRef.value?.validate();
    if (userNameRef.value?.hasError) return;
    obj.username = review.userName;
  }

  buttLoading.value = true;
  const res = await useUserStore
    .leaveReview(props.data.propId, obj)
    .finally(() => {
      buttLoading.value = false;
    });
  if (res) {
    isSentPopUp.value = true;
    showStarsConfetti();
    setTimeout(() => {
      const newObj = { ...obj, review: res };
      emit("hide_popup", "ok", newObj);
    }, 2000);
  }
};

const checkIsUpdateReview = () => {
  if (props.data.reviewText) {
    review.userReview = props.data.reviewText;
  }
};

const handleUpdateReview = async () => {
  if (!props.data.reviewId) return;
  const obj: CreateReviewDTO = {
    text: review.userReview,
    rating: getServerStars(rating.value),
  };
  buttLoading.value = true;
  const res = await useUserStore
    .updateReview(props.data.propId, props.data.reviewId, obj)
    .finally(() => {
      buttLoading.value = false;
    });
  if (res) {
    const newObj = { ...obj, review: res };
    emit("hide_popup", "ok", newObj);
  }
};

onMounted(() => {
  checkIsUpdateReview();
});
</script>

<style scoped lang="scss">
.wrapper {
  transition: height 0.3s ease;
  will-change: height;
  border: 1px solid var(--gray-monochrome);
  background: var(--white-contrast);
  border-radius: 24px;
}

.base-leave-review__popup {
  position: relative;
  display: flex;
  width: 496px;
  // height: fit-content;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  text-align: center;

  &--header {
    position: relative;
    .title-underlined {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        bottom: -1px;
        left: 0;
        background-color: var(--violet-main);
      }
    }
  }
  &--rating {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 16px;
    .rating-house {
      display: grid;
      grid-template-columns: 80px 336px;
      gap: 16px;
      justify-content: center;
      align-items: center;
      &--alone {
        width: 332px !important;
        height: 158px !important;
        grid-column: span 2;
        justify-self: center;
      }
      &--img0,
      &--img1,
      &--img2 {
        object-fit: cover;
        border-radius: 8px;
      }
      &--img0,
      &--img2 {
        width: 80px;
        height: 65px;
      }
      &--img1 {
        grid-row: span 2;
        width: 332px;
        height: 158px;
      }
    }
    :deep(.q-rating__icon--hovered) {
      transform: scale(1.1);
    }
  }
  &--user {
    width: 100%;
    .gridbox {
      width: 100%;
      display: grid;
      gap: 6px;
      label {
        white-space: nowrap;
        display: flex;
        gap: 3px;
        align-items: center;
      }
      sup {
        color: red;
      }
    }
    :deep(.gridbox) {
      .q-field--outlined .q-field__control {
        border-radius: 16px;
      }
      .q-field__native {
        color: var(--Monochrome-Black, #121212);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      .q-field--error .q-field__bottom {
        color: var(--Color-Secondary-Negative, #ef4444);
        padding-left: 0px;
        font-family: "DM Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        padding: 6px 12px 0px;
        text-align: left;
      }
    }
    :deep(.base-textarea) {
      .q-field--outlined .q-field__control {
        border-radius: 16px;
      }
      .q-field__native {
        color: var(--Monochrome-Black, #121212);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      .q-field--error .q-field__bottom {
        color: var(--Color-Secondary-Negative, #ef4444);
        padding-left: 0px;
        font-family: "DM Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        padding: 6px 12px 0px;
        text-align: left;
      }
    }
    :deep(.base-input) {
      .q-field--outlined .q-field__control {
        max-height: 48px;
        &:after {
          max-height: 48px;
        }
      }
      .q-field__marginal {
        max-height: 48px;
      }
      .q-field__native {
        max-height: 48px;
      }
      .q-field__control-container {
        max-height: 48px;
      }
    }
  }
  &--submit {
    width: 100%;
    .base-button {
      width: 100%;
    }
  }
  .close-butt {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 28px;
    height: 28px;
    margin: 0;
    outline: none;
    border: none;
    border-radius: 50%;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background: var(--white-monochrome);
    cursor: pointer;
  }
}
.popup-moderating {
  align-items: center;
  gap: 32px;
  &--controls {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 16px;
    .base-button {
      width: 100%;
    }
  }
}
@media (max-width: 59rem) {
  .base-leave-review__popup {
    width: calc(100vw - 48px);
    &--rating {
      .rating-house {
        grid-template-columns: 80px 210px;
        &--img1 {
          width: 205px;
        }
      }
    }
  }
}
</style>

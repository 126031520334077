<template>
  <section class="main-featured">
    <span
      class="main-featured__title text-head-1 text-bold text-sora mobile-font--h6"
      >{{ $t("main.m_featured") }}</span
    >
    <section class="main-featured__hot">
      <span class="text-subtitle mobile-font--b5"
        >{{ $t("search_filters.fr_search_by") }}:
        <span class="text-bold mobile-font--b4-bold">{{
          $t("main.m_hot")
        }}</span></span
      >
      <Suspense>
        <q-tabs dense class="custom-hot-tabs" align="justify">
          <q-tab
            v-for="tab in hotTabs"
            :key="tab.code"
            :name="tab.code"
            no-caps
            :class="{ 'active-tab': currentHotTab === tab.code }"
            @click="handleHotTabClick(tab.code)"
          >
            <span class="flag-flex">
              <base-icon
                :name="`flags/${tab.name.toLowerCase().replace(/ /g, '_')}`"
                filled
                :size="!$device.isMobile ? '20px' : '16px'"
              />
              <p class="text-body-2 text-bold mobile-font--b7">
                {{ tab.name }}
              </p>
            </span>
            <span class="flag-flex house-flex" style="gap: 6px">
              <base-icon
                name="base/House"
                :size="!$device.isMobile ? '20px' : '16px'"
              />
              <p class="text-body-2 mobile-font--b7">{{ tab.count }}</p>
            </span>
          </q-tab>
        </q-tabs>
      </Suspense>
    </section>
    <section class="main-featured__partners">
      <span class="text-subtitle"
        >{{ $t("search_filters.fr_search_by") }}:
        <span class="text-bold">{{ $t("main.m_partns") }}</span></span
      >
      <q-tabs dense class="custom-hot-tabs" align="justify">
        <q-tab
          v-for="tab in partnersTabs"
          :name="tab.id"
          no-caps
          :class="{ 'active-tab': currentPartnerTab === tab.id }"
          @click="handlePartnerTabClick(tab.id)"
        >
          <NuxtImg :id="tab.name" :src="tab.logo" :alt="tab.name" />
        </q-tab>
      </q-tabs>
    </section>
    <section
      v-if="!skeletonLoader"
      class="main-featured__cards animate__animated animate__fadeIn"
    >
      <div v-for="card in cards" :key="card.id" class="card">
        <base-search-card-new
          :estate="card"
          hide-local-currency
          property-type="estates"
          size="suggestions"
        />
      </div>
    </section>
    <section
      v-else
      class="main-featured__cards animate__animated animate__fadeIn"
    >
      <div v-for="card in [0, 1, 2]" :key="card" class="card">
        <q-skeleton height="425px" width="314px" />
      </div>
    </section>
    <base-button
      :text="$t('main.m_view_more')"
      variant="dark"
      size="sl"
      bold-text
      text-size="landing"
      style="margin-top: 8px"
      @click="navigateToSearch"
    />
  </section>
</template>

<script setup lang="ts">
import { filtersStore } from "~/store/search_filters";
import { countryNames } from "~/utilities/constants/countryCodes";
import { useAdminStore } from "~/store/admin";
import getRoute from "~/utilities/configs/apiMap";
import { apiPost } from "~/services/api";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";

interface PartnerTab {
  name: string;
  logo: string | undefined;
  id: string;
  url: string;
}

const useFiltersStore = filtersStore();
const adminStore = useAdminStore();
const localePath = useLocalePath();

const currentHotTab = ref();
const currentPartnerTab = ref();
const previousPartner = ref();
const cards = ref<UpdatedUnitResponseDTO[]>([]);
const skeletonLoader = ref(true);
const currentQuery = ref<Record<string, string[]>>({});

const hotTabs = computed(() => {
  return Object.entries(useFiltersStore.$state.currentStats?.country || {})
    .filter(([code, count]) => code !== "null" && count > 0)
    .map(([code, count]) => ({
      code,
      name: countryNames[code] || "Unknown",
      count: count as number,
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);
});
const partnersTabs = ref<PartnerTab[]>([]);
const getUnits = async (filter: Record<string, string[]>) => {
  skeletonLoader.value = true;
  const url = getRoute({ endpont: "post_units" });
  const body = {
    limit: 3,
    order: { viewsForAllTime: "desc" },
    status: ["published", "sold_out"],
    ...filter,
  };
  const data = await apiPost({
    url: url,
    body: body,
    headers: { "Cache-Control": "no-cache" },
    noCache: true,
  }).finally(() => {
    setTimeout(() => {
      skeletonLoader.value = false;
      previousPartner.value = currentPartnerTab.value;
    }, 500);
  });
  if (!data.data?.value) return;
  const response = data?.data?.value as {
    count: number | null;
    rows: UpdatedUnitResponseDTO[];
  };
  cards.value = response.rows.sort(
    (a, b) =>
      (b.status === "published" ? 1 : 0) - (a.status === "published" ? 1 : 0)
  );
};

const getPartners = async () => {
  const data = await adminStore.getProviders();
  partnersTabs.value = data.map((item) => {
    return {
      logo: item.logoFile?.url,
      id: item.id,
      name: item.name,
      url: item.url,
    };
  });
};

const handleHotTabClick = (code: string) => {
  currentHotTab.value = code;
  currentPartnerTab.value = "";
  currentQuery.value = { countryId: [code] };

  getUnits(currentQuery.value);
};

const handlePartnerTabClick = (id: string) => {
  currentPartnerTab.value = id;
  currentHotTab.value = "";
  currentQuery.value = { providerIds: [id] };
  getUnits(currentQuery.value);
};

const navigateToSearch = () => {
  let key = null;
  Object.keys(currentQuery.value).forEach((k) => {
    key = k;
  });
  navigateTo(
    localePath(
      `/directory?${key ? `${key}[0]=${currentQuery.value[key]}` : ""}`
    )
  );
};

watch(hotTabs, () => {
  if (hotTabs.value.length) {
    getUnits({});
    getPartners();
  }
});
</script>

<style scoped lang="scss">
.main-featured {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 76px;
  gap: 32px;
  :deep(.custom-hot-tabs) {
    .q-tabs__content {
      gap: 12px;
      flex-wrap: wrap;
      justify-content: center;
    }
    .q-tab__indicator {
      background: transparent;
    }
    .active-tab {
      .q-tab__indicator {
        opacity: 1;
        background: var(--violet-main);
      }
    }

    .q-tab__content {
      gap: 8px;
      padding: 0;
    }
  }
  &__hot,
  &__partners {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &__hot {
    margin-top: 8px;
    border-bottom: 1px solid var(--gray-monochrome-40);
    :deep(.custom-hot-tabs) {
      .q-tab {
        display: flex;
        width: 188px;
        padding: 8px 0px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 16px 16px 0px 0px;
        background: transparent;
        .flag-flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
        }
      }
      .active-tab {
        background: var(--white-contrast);
      }
    }
  }
  &__partners {
    :deep(.custom-hot-tabs) {
      .q-tab {
        display: flex;
        width: 120px;
        max-width: 120px;
        height: 48px;
        padding: 8px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px 16px 0px 0px;
        background: transparent;
      }
    }
    max-width: 990px;
  }
  &__cards {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
}
@media (max-width: 75rem) {
  @include mobile-fonts;
  .main-featured {
    margin-top: 72px;
    &__hot {
      :deep(.custom-hot-tabs) {
        max-width: calc(100dvw - 48px);
        overflow: scroll;

        .q-tab {
          padding: 8px 12px;

          .flag-flex {
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }
          .house-flex {
            flex-direction: row;
          }
        }
        .q-tabs__content {
          flex-wrap: nowrap;
          justify-content: flex-start;
        }
      }
    }
    &__partners {
      :deep(.custom-hot-tabs) {
        .q-tab {
          max-width: 80px;
        }
      }
    }

    &__cards {
      max-width: calc(100dvw - 48px);
      overflow: scroll;
      justify-content: flex-start;
    }
  }
}
</style>

<template>
  <div
    v-if="iconAndColorMap.replaceTitle || currentSubtype"
    :class="[
      'base-property-tag',
      {
        'base-property-tag--round':
          props.isRound || (!iconAndColorMap.replaceTitle && !currentSubtype),
      },
    ]"
    :title="iconAndColorMap.replaceTitle ?? currentSubtype"
  >
    <suspense>
      <base-icon
        :name="`unit-subtypes/${iconAndColorMap.icon}`"
        filled
        size="16px"
      />
    </suspense>
    <p
      v-if="!props.isRound && (iconAndColorMap.replaceTitle || currentSubtype)"
      lass="text-body-3 text-bold"
    >
      {{ iconAndColorMap.replaceTitle ?? currentSubtype }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { UnitResponseDTO } from "~/services/swagger/Api";
import { unitSubtypes } from "~/utilities/helpers/admin/common";

interface Props {
  isRound?: boolean;
  propertySubtype?: UnitResponseDTO["subtype"];
}

const props = defineProps<Props>();

const currentSubtype = computed(() =>
  unitSubtypes.find(
    (item) => item.toLowerCase() === props.propertySubtype?.toLowerCase()
  )
);

const iconAndColorMap = computed(() => {
  switch (props.propertySubtype) {
    case "Apartment":
      return {
        icon: "apartment",
      };
    case "Condominium":
      return {
        icon: "apartment",
        replaceTitle: "Condo",
      };
    case "House":
      return {
        icon: "house",
      };
    case "Townhouse":
      return {
        icon: "townhouse",
      };
    default:
      return {
        icon: "other",
      };
  }
});
</script>

<style scoped lang="scss">
.base-property-tag {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 20px;
  background-color: var(--green-sec);
  transition: scale 0.3s ease;
  will-change: scale;
  &:hover {
    scale: 1.1;
  }
  &--round {
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
  }
}
</style>

<template>
  <unit-page
    :is-calc="
      !!unitData?.finance?.projectTotalIncome?.subfields
        ?.projectedRentalIncome &&
      !!unitData?.finance?.projectTotalIncome?.subfields?.projectionAppreciation
    "
    is-timeline
    v-bind="$attrs"
  >
    <template #bubbles>
      <div class="unit-bubbles">
        <span v-for="bubble in bubbles" v-show="bubble.isBubble" class="bubble">
          <base-icon
            :name="bubble.iconName"
            color="var(--black-monochrome)"
            size="17px"
          />
          <p class="text-body-2 text-regular mobile-font--b4">
            {{ bubble.label }}
          </p>
        </span>
      </div>
    </template>
    <template #finance="{ unitData }">
      <unit-page-finance-block-estate :unit-page-data="unitData" />
    </template>
    <template #infoBlock="{ unitData, imageArray }">
      <unit-page-info-block :property-info="unitData" type="estates">
        <template v-if="$device.isMobile" #mobilt-swiper>
          <mobile-swiper-gallery
            class="base-serach-card--mobile-gallery"
            style="max-width: 100%"
            :go-to-unit="unitData?.sanitizeName"
          >
            <div v-for="file in imageArray">
              <img
                :src="getThumbnail(file, 'thumbnail_470x370')"
                alt="property photo"
              >
            </div>
          </mobile-swiper-gallery>
        </template>
      </unit-page-info-block>
    </template>
    <template #compareBlock>
      <unit-page-compare-block
        :property-info="unitData"
        property-type="estate"
      />
    </template>
    <template #suggs="{ suggsArr }">
      <base-suggested-estates
        v-if="unitData"
        :estates="suggsArr"
        type="estates"
      />
    </template>
  </unit-page>
</template>

<script setup lang="ts">
import { unitPageStore } from "~/store/unitPage";
import type { PropertyBubblesBase } from "../types";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";

const useUnitpageStore = unitPageStore();
const unitPage = defineAsyncComponent(
  () => import("~/components/unit-page/unit/index.vue")
);
const unitData = computed(() => useUnitpageStore.$state.unitData);
const { t, locale } = useI18n();

const createdDate = computed(() => {
  if (unitData.value?.createdAt) {
    const date = new Date(unitData.value.createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${day}.${month}.${year}`;
  }
  return "";
});

const bubbles = computed<PropertyBubblesBase[]>(() => [
  {
    isBubble: true,
    label: `${unitData.value?.bedroom ?? 0} ${t("search_filters.fr_bed")}${getS(unitData.value?.bedroom)}`,
    iconName: "base/Bed",
  },
  {
    isBubble: true,
    label: `${unitData.value?.bathroom ?? 0} ${t("search_filters.fr_bath")}${getS(unitData.value?.bathroom)}`,
    iconName: "base/Toilet",
  },

  {
    isBubble: true,
    label: `${convertArea(unitData.value?.area ?? 0, AREA_UNITS.m2, AREA_UNITS[BASE_AREA_UNIT.value.code]) ?? 0} ${BASE_AREA_UNIT.value.code}`,
    iconName: "base/Cube",
  },
  {
    isBubble: Boolean(unitData.value?.createdAt),
    label: `${t("unit_page.added_date", {
      value: createdDate.value,
    })}`,
    iconName: "base/Clock",
  },
]);

const getS = (value: number | null | undefined): string => {
  return value === 1 ? "" : locale.value == "en" ? "s" : "";
};
</script>

<style scoped lang="scss">
@import url(~/assets/styles/unit-page/index.scss);
</style>

<template>
  <transition-group name="list" tag="section" mode="out-in" class="map-sidebar">
    <base-search-card-new
      v-for="card in computedItems"
      :key="card.id"
      :estate="card"
      hide-local-currency
      property-type="estates"
      size="suggestions"
    />
  </transition-group>
</template>

<script setup lang="ts">
import type { UnitResponseDTO } from "~/services/swagger/Api";

interface Props {
  items: UnitResponseDTO[];
}

const props = defineProps<Props>();

const computedItems = computed(() => props.items ?? []);
</script>

<style scoped lang="scss">
.map-sidebar {
  position: absolute;
  left: 0;
  z-index: 99999;
  width: fit-content;
  min-width: 340px;
  height: calc(100dvh - 66px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition:
    transform 0.5s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.4s ease;
}

.list-leave-active {
  position: absolute;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(-20px) scale(0.95);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(20px) scale(0.95);
}

.list-move {
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.map-sidebar::-webkit-scrollbar {
  width: 0px;
}

.map-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.map-sidebar::-webkit-scrollbar-thumb {
  background: transparent;
}

.map-sidebar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@supports not selector(::-webkit-scrollbar) {
  .map-sidebar {
    scrollbar-width: thin;
    scrollbar-color: transparent;
  }
}
</style>

<template>
  <div class="carousel">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="['carousel-item', getPositionClass(index)]"
    >
      <base-search-card-new
        v-if="!skeletonLoader"
        class="carousel-card animate__animated animate__fadeIn"
        :estate="item"
        property-type="estates"
        size="search"
        hide-local-currency
        @click.capture="handleInnerClick(index, $event)"
      />
      <div v-else class="skeletons">
        <q-skeleton
          width="100%"
          height="100%"
          style="background-color: rgb(243 247 254 / 50%)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiPost } from "~/services/api";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import getRoute from "~/utilities/configs/apiMap";

const items = ref<UpdatedUnitResponseDTO[]>([0, 1, 2]);
const skeletonLoader = ref(true);

const currentIndex = ref(0);

const handleInnerClick = (index: number, event: MouseEvent | TouchEvent) => {
  if (index !== currentIndex.value) {
    event?.preventDefault();
    event?.stopPropagation();
    moveToCenter(index);
  }
};

const getPositionClass = (index: number) => {
  const diff =
    (index - currentIndex.value + items.value.length) % items.value.length;

  if (diff === 0) return "center";
  if (diff === 1) return "right";
  if (diff === 2) return "left";

  return "hidden";
};

const moveToCenter = (index: number) => {
  currentIndex.value = index;
};

const getHeroUnits = async () => {
  const fetchUnits = async (filterValueGrowth = true) => {
    const url = getRoute({ endpont: "post_units" });
    const filters: Record<string, any> = {
      limit: filterValueGrowth ? 3 : 10,
      order: { viewsForAllTime: "desc" },
      status: ["published"],
      "tokenomics.availableSupply": { gte: 0.1 },
      "tokenomics.priceUSD": { gte: 0.1 },
    };

    if (filterValueGrowth) {
      filters.valueGrowth = { gte: 0.1 };
    }

    const data = await apiPost({
      url,
      body: filters,
      headers: { "Cache-Control": "no-cache" },
      noCache: true,
    });

    return (
      (data?.data?.value as {
        count: number | null;
        rows: UpdatedUnitResponseDTO[];
      }) || { rows: [] }
    );
  };

  const response = await fetchUnits(true);

  if (response.rows.length < 3) {
    const additionalResponse = await fetchUnits(false);
    const missingCount = 3 - response.rows.length;
    const existingIds = new Set(response.rows.map((item) => item.id));
    const uniqueAdditionalRows = additionalResponse.rows.filter(
      (item) => !existingIds.has(item.id)
    );

    response.rows.push(...uniqueAdditionalRows.slice(0, missingCount));
  }

  items.value = [...response.rows];

  setTimeout(() => {
    skeletonLoader.value = false;
  }, 500);
};

onMounted(() => {
  getHeroUnits();
});
</script>

<style scoped lang="scss">
.carousel {
  position: relative;
  width: 515px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .carousel-item {
    position: absolute;
    height: 100%;
    transition:
      transform 0.6s ease,
      z-index 0.3s ease;
    cursor: pointer;
    .skeletons {
      width: 225px;
      height: 390px;
    }

    :deep(.carousel-card) {
      width: 225px;
      padding: 12px;
      .base-button {
        height: 36px;
      }
      .search-img {
        max-height: 136px;

        .tags {
          display: none;
        }
      }
      .search-info {
        position: relative;
        .info-dynamic {
          gap: 0;
          .order-2,
          .order-3,
          .order-4 {
            display: none;
            position: absolute;
          }
          .order-1 {
            margin-bottom: 8px;
          }
          .order-7 {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.carousel-item .block {
  width: 100%;
  height: 100%;
  background-color: red;
  border-radius: 10px;
}

.carousel-item.center {
  z-index: 3;
  transform: translateX(0) scale(1);
}

.carousel-item.right {
  z-index: 2;
  transform: translateX(65%) scale(0.8);
}

.carousel-item.left {
  z-index: 2;
  transform: translateX(-65%) scale(0.8);
}

.carousel-item.hidden {
  z-index: 1;
  transform: translateX(100%) scale(0.6);
  opacity: 0;
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .carousel {
    width: 315px;
    height: 245px;
    .carousel-item {
      :deep(.carousel-card) {
        .add-favorite {
          right: 10px;
          z-index: 2;
          width: 20px;
          height: 20px;
        }
        padding: 7px;
        width: 137px;
        gap: 7.2px;
        .search-img {
          max-width: 122px;
          max-height: 82px;
        }
        .search-info {
          gap: 3.5px;
          &-title {
            .title-wrapper {
              &-butt {
                font-size: 9.5px;
                font-weight: 500;
                line-height: unset;
              }
              .location {
                gap: 1px;
                .base-icon {
                  width: 9px;
                  height: 9px;
                  svg {
                    width: 9px;
                    height: 9px;
                  }
                }
                &-butt {
                  p {
                    font-size: 8.3px;
                    font-weight: 400;
                    line-height: unset;
                  }
                }
              }
            }
          }
          .list-gridbox {
            .list-flexbox {
              p {
                font-size: 8px;
                font-weight: 400;
              }
              span {
                font-size: 8px;
                font-weight: 600;
                line-height: unset;
              }
            }
          }
          .info-dynamic {
            .list-flexbox {
              p {
                font-size: 8px;
                font-weight: 400;
              }

              p:nth-child(2) {
                font-size: 8px;
                font-weight: 600;
                line-height: unset;
              }
            }
            .order-1 {
              margin-bottom: 5px;
            }
          }
        }
        .search-controls {
          .base-button {
            height: 21.5px;
            padding: 7.2px 19.2px;
            p {
              font-size: 8.386px;
              font-weight: 400;
              line-height: unset;
            }
          }
        }
      }
    }
  }
}
</style>

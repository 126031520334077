<template>
  <span class="custom-select-label custom-select-ellipsis">
    <span class="select-dot rotateable">
      <base-icon name="base/MapPin" size="14px" color="var(--white-contrast)" />
    </span>
    <p
      :class="[
        'font--b4',
        props.locationFilter.length
          ? 'text-black-monochrome'
          : 'text-gray-monochrome',
      ]"
    >
      {{
        props.locationFilter.length > 1
          ? $t("search_filters.fr_mult")
          : props.locationFilter.length === 1
            ? props.countryNames[locationFilter[0]]
            : $t("main.m_loc")
      }}
    </p>
  </span>
  <base-icon
    name="base/ChevronDown"
    size="18px"
    color="var(--gray-monochrome)"
    :rot="props.currentSelectCard === 1 ? '180deg' : ''"
    class="arrow"

  />
  <transition name="fade">
    <div v-if="props.currentSelectCard === 1" class="select__card temp-card-no-checkbox" style="left: 0;" @click.stop>
      <div class="wrapper">
        <span class="custom-select-label">
          <span class="select-dot">
            <base-icon
              name="base/MapPin"
              size="14px"
              color="var(--white-contrast)"
            />
          </span>
          <p class="font--b2 text-black-monochrome">
            {{ $t("search_filters.fr_by_coun") }}
          </p>
        </span>

        <span class="cards" >
          <div class="checkbox-grid">
            <div v-for="(item, index) in props.contryArr" class="checkbox-wrapper">
              <input
                type="checkbox"
                name="customCheckbox"
                :checked="props.locationFilter.includes(contryArr[index].code)"
              >
              <label
                for="customCheckbox"
                @click.stop="props.pickLocationCheckbox(index)"
                ><p class="font--b6 text-black-monochrome">
                  {{ item.amount }}
                </p></label
              >
              <p
                class="font--b4 text-black-monochrome-60"
                @click.stop="props.pickLocationCheckbox(index)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </span>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { LocationFilterProps } from "~/components/base/custom-select/types";

const props = defineProps<LocationFilterProps>();
</script>

<style scoped lang="scss">
@import url(~/assets/styles/custom-select/index.scss);
</style>

<template>
  <section
    ref="userSettingsRef"
    class="user-settings"
    :style="{ height: '675px' }"
  >
    <div class="user-settings__header">
      <h5 class="text-head-2 text-bold text-black-monochrome text-sora">
        Settings
      </h5>
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
    </div>
    <div class="user-settings__body">
      <nav class="navigation">
        <button
          v-for="(item, index) in settigsNavigation"
          class="text-body-1 text-black-monochrome navigation-item"
          :class="{ 'navigation-item-active': index === currentTab }"
          @click="onTabClick(item)"
        >
          {{ item }}
        </button>
      </nav>
      <q-separator vertical />
      <div class="tab-swiper">
        <swiper
          :slides-per-view="1"
          :space-between="30"
          :allow-touch-move="false"
          :style="{ maxHeight: getMaxTabHeight + 'px' }"
          @swiper="onSwiper"
          @slide-change="onSlideChange"
        >
          <swiper-slide>
            <div class="tab-item">
              <div class="tab-item-profile">
                <span class="user-img">
                  <p class="text-body-2 text-bold text-black-monochrome">
                    {{ $t("profile.p_profile_picture") }}
                  </p>
                  <span class="user-img-upload">
                    <q-avatar rounded size="64px">
                      <q-img
                        style="width: 64px; height: 64px; border-radius: 50%"
                        :src="userData.avatar ? userData.avatar : defaultAvatar"
                        alt="avatar"
                      />
                    </q-avatar>
                    <Suspense>
                      <base-button
                        :text="$t('profile.p_upload')"
                        size="sg"
                        icon-position="left"
                        variant="dark_ghost"
                        icon-name="base/Upload"
                        for="images-upload"
                      />
                    </Suspense>
                    <input
                      id="images-upload"
                      type="file"
                      class="file-input"
                      accept="image/png, image/jpeg, image/webp"
                      @change="handleFilesChange($event, 'image')"
                    >
                  </span>
                </span>
                <span class="gridbox">
                  <label class="text-body-2 text-bold text-black-monochrome">{{
                    $t("login.log_username")
                  }}</label>
                  <q-input
                    v-model="userData.name"
                    maxlength="20"
                    outlined
                    type="text"
                  />
                </span>
                <span class="gridbox">
                  <label class="text-body-2 text-bold text-black-monochrome">{{
                    $t("profile.p_first_name")
                  }}</label>
                  <q-input
                    v-model="userData.firstName"
                    maxlength="15"
                    outlined
                    type="text"
                  />
                </span>
                <span class="gridbox">
                  <label class="text-body-2 text-bold text-black-monochrome">{{
                    $t("profile.p_last_name")
                  }}</label>
                  <q-input
                    v-model="userData.lastName"
                    maxlength="15"
                    outlined
                    type="text"
                  />
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="tab-item">
              <base-coming-soon />
              <div class="tab-item-notifications">
                <span v-for="item in userNotifArray" class="notif-item">
                  <span class="flexbox">
                    <p class="text-subtitle text-bold text-black-monochrome">
                      {{ item.label }}
                    </p>
                    <label class="switch">
                      <input v-model="item.isActive" type="checkbox" >
                      <span class="slider" />
                    </label>
                  </span>
                  <p class="text-body-1 text-black-monochrome">
                    {{ item.description }}
                  </p>
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="tab-item">
              <span class="logout">
                <h5 class="text-head-2 text-bold text-black-monochrome">
                  {{ $t("profile.p_logout_confirmation") }}
                </h5>
                <p class="text-body-1 text-black-monochrome">
                  {{ $t("profile.p_logout_message") }}
                </p>
                <base-button
                  v-close-popup
                  style="margin-top: 55px"
                  :text="$t('profile.p_cancel')"
                  size="lg"
                  variant="dark_ghost"
                  @click="showStarsConfetti(3)"
                />
                <base-button
                  :text="$t('profile.p_logout')"
                  size="lg"
                  variant="prime"
                  @click="useUserStore.userSignOut()"
                />
              </span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      class="user-settings__controls animate__animated"
      :class="{
        animate__fadeOut: currentTab === 2,
        animate__fadeIn: currentTab !== 2,
      }"
    >
      <base-button
        v-close-popup
        :text="$t('profile.p_cancel')"
        size="lg"
        variant="dark_ghost"
      />
      <base-button
        :text="$t('profile.p_save_changes')"
        size="lg"
        variant="prime"
        :loading="updateUserLoader"
        @click="debouncedUpdateUser"
      />
      
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import type { UpdateUserDTO, UserResponseDTO } from "~/services/swagger/Api";
import defaultAvatar from "~/assets/img/user/defaultAvatar.svg";
import { Api } from "~/services/swagger/Api";
import { userStore } from "~/store/user";
import { debounce } from "lodash";
import { showStarsConfetti } from "~/utilities/helpers/treats/confetti";

interface IProps {
  data: UserResponseDTO;
}

interface INotify {
  label: string;
  value: string;
  isActive: boolean;
  description: string;
}

const props = defineProps<IProps>();
const emit = defineEmits();

const apiClient = new Api();

const currentUser = computed(() => props.data);
const useUserStore = userStore();
const { t } = useI18n();
const updateUserLoader = ref(false);

const currentTab = ref(0);
const settigsNavigation = ref<string[]>([
  t("profile.p_edit_profile"),
  t("profile.p_notifications"),
  t("profile.p_logout"),
]);
const userNotifArray = ref<INotify[]>([
  {
    label: "Email notifications",
    value: "subscriptionNews",
    isActive: false,
    description: `Stay updated with the latest information and receive timely updates about your preferences. Never miss an important email again.`,
  },
  {
    label: "Price alert",
    value: "",
    isActive: false,
    description: `Get notified as soon as prices change. Whether you're tracking property values or product discounts, we'll keep you informed.`,
  },
  {
    label: "New property alert",
    value: "",
    isActive: false,
    description: `Be the first to know when a new property that matches your criteria is listed. Stay ahead in your property search.`,
  },
]);

const userData = reactive({
  name: "",
  firstName: "",
  lastName: "",
  avatar: "",
  avatarId: "",
});

const swiperInst = ref<typeof Swiper | null>(null);
const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};
const onSlideChange = (swiper: { activeIndex: number }) => {
  if (typeof swiper?.activeIndex !== "number") return 0;
  currentTab.value = swiper.activeIndex;
};

const onTabClick = (tabName: string | undefined) => {
  if (!tabName) return;
  const tabIndex = settigsNavigation.value.indexOf(tabName);
  if (swiperInst.value && tabIndex !== -1) {
    currentTab.value = tabIndex;
    swiperInst.value.slideTo(tabIndex);
  }
};

const getMaxTabHeight = computed(() => {
  switch (currentTab.value) {
    case 0:
      return 497;
    case 1:
      return 400;
    case 2:
      return 490;
    case 3:
      return 330;
    default:
      return 493;
  }
});


const datherUserData = () => {
  if (!currentUser.value) return;
  userData.name = currentUser.value.username || "";
  userData.firstName = currentUser.value.firstName || "";
  userData.lastName = currentUser.value.lastName || "";
  userData.avatar = currentUser.value.avatarFile?.url || "";
  userNotifArray.value.forEach((item) => {
    if (item.value === "subscriptionNews") {
      item.isActive = Boolean(currentUser.value.settings?.subscriptionNews);
    }
  });
};

const updateUser = () => {
  const updatedUser: UpdateUserDTO = {
    username: userData.name,
    firstName: userData.firstName,
    lastName: userData.lastName,
    avatar: userData.avatarId,
  };
  Object.keys(updatedUser).forEach((key) => {
    const k = key as keyof UpdateUserDTO;
    if (!updatedUser[k]) {
      updatedUser[k] = "";
    }
    if (k === "avatar" && !updatedUser[k]) {
      delete updatedUser[k];
    }
  });

  updateUserData(updatedUser);
};

const updateUserData = async (updatedUser: UpdateUserDTO) => {
  try {
    updateUserLoader.value = true;
    const res = await useUserStore.updateUserData(updatedUser);
    if (res?.ok) {
      updatedUser.avatar = res.data.avatarFile?.originUrl;
      emit("hide_popup", "ok", updatedUser);
    }
  } catch (error) {
    console.log("error: ", error);
  } finally {
    updateUserLoader.value = false;
  }
};

const debouncedUpdateUser = debounce(() => {
  updateUser();
}, 300);

const handleFilesChange = async (event: Event, type: "image" | "document") => {
  const token = useCookie("acc_token");
  const input = event.target as HTMLInputElement;
  if (input.files?.length) {
    const filesArray = Array.from(input.files);
    try {
      const data = await apiClient.api.filesControllerUploadFile(
        { file: filesArray[0] },
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      );
      if (!data.ok) return;
      userData.avatarId = data.data.id;
      userData.avatar = data.data.url;
    } catch (error) {
      alert(error?.error?.message || error?.message);
    }
  }
};

onMounted(() => {
  datherUserData();
});
</script>

<style scoped lang="scss">
.user-settings {
  position: relative;
  display: inline-flex;
  min-width: 532px;
  height: auto;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 24px;
  border: 1px solid var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
  background: var(--Monochrome-White_contrast, #f3f7fe);
  transition: height 0.2s ease;
  overflow: hidden;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__body {
    display: grid;
    grid-template-columns: 136px 1px 1fr;
    gap: 24px;
    .navigation {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
      &-item {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        transition:
          background-color 0.3s ease,
          font-weight 0.3s ease;
        &-active {
          background: var(--Color-Secondary-Light-lilac, #e5e3fb);
          font-weight: 600;
        }
      }
    }
    .tab-swiper {
      .swiper {
        width: fit-content;
        max-width: 281px;
        height: 100%;
        .tab-item {
          position: relative;
          background: var(--Monochrome-White_contrast, #f3f7fe);
          width: 100%;
          height: 100%;
          :deep(.q-field__bottom) {
            padding-top: 5px;
          }
          &-profile {
            display: flex;
            width: fit-content;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 100%;
            .base-button {
              width: 100%;
            }
            .user-img {
              display: grid;
              align-items: center;
              justify-items: flex-start;
              width: 100%;
              gap: 8px;
              &-upload {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 24px;
              }
            }
            .gridbox {
              width: 100%;
              display: grid;
              gap: 4px;
              sup {
                font-family: "Cabinet Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                color: var(--negative-sec);
                top: -0.2em;
              }
            }
            .recover-pass {
              width: fit-content;
              cursor: pointer;
              outline: none;
              padding: 0;
              border: none;
              background: transparent;
              text-decoration: underline;
              font-weight: 700;
            }
          }
          .inner-swiper {
            position: relative;
            gap: 0;
            &-header {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              widows: 100%;
              gap: 12px;
              margin-bottom: 16px;
              .back-butt {
                position: static;
                width: 24px;
                height: 24px;
              }
            }
            &-tryagain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 3px;
              margin-top: 5px;
            }
          }
          &-notifications {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            .notif-item {
              width: 100%;
              display: grid;
              align-items: center;
              justify-items: flex-start;
              gap: 8px;
              .flexbox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .switch {
                  position: relative;
                  display: inline-block;
                  width: 50px;
                  height: 24px;
                  input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    &:checked + .slider {
                      background-color: var(--white-monochrome);
                      border: 1px var(--violet-main) solid;
                    }
                    &:checked + .slider:before {
                      -webkit-transform: translateX(26px);
                      -ms-transform: translateX(26px);
                      transform: translateX(26px);
                      background-color: var(--violet-main);
                    }
                  }
                  .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--white-monochrome);
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    border-radius: 34px;
                    border: 1px var(--gray-monochrome) solid;
                    &:before {
                      position: absolute;
                      content: "";
                      height: 16px;
                      width: 16px;
                      left: 4px;
                      bottom: 3.5px;
                      background-color: var(--gray-monochrome);
                      -webkit-transition: 0.4s;
                      transition: 0.4s;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
          .logout {
            display: grid;
            align-items: center;
            justify-items: flex-start;
            gap: 16px;
            .base-button {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: auto;
    .base-button {
      width: 100%;
    }
  }
}

.file-input {
  width: 50%;
  height: 60%;
  opacity: 0;
  position: absolute;
  left: 46%;
  cursor: pointer;
  margin-bottom: 0;
}
.close-butt {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 28px;
  height: 28px;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: var(--Monochrome-White, #e9ebf8);
  cursor: pointer;
}
</style>
<style lang="scss">
.q-field--outlined .q-field__control {
  border-radius: 16px;
  max-height: 54px;
  &:after {
    max-height: 54px;
  }
}
.q-field__native {
  color: var(--Monochrome-Black, #121212);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-height: 54px;
}
.q-field__control-container {
  max-height: 54px;
}
</style>

import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/main-site/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
<template>
  <div class="base-tooltip">
    <slot name="icon" />
    <q-tooltip
      class="custom-tooltip"
      :anchor="anchor "
      :self="self"
      v-bind="$attrs"
    >
      <p class="font--b5" v-html="props.tooltipText"/>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  tooltipText: string;
  position?: "left" | "right";
}

const props = withDefaults(defineProps<IProps>(), {
  position: "left",
});

const anchor = computed(()=>props.position === 'left'?'center left':'center right');
const self = computed(()=>props.position === 'left'?'center right':'center left')


</script>

<style lang="scss">
.base-tooltip {
  max-width: 16px;
  max-height: 16px;
  position: relative;
  display: inline-block;
}
</style>

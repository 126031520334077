<template>
  <section class="add-token">
    <header>
      <div class="title">
        <span class="text-subtitle text-bold title__text">Add unit</span>
        <Suspense>
          <base-button
            no-shadow
            round
            :variant="'filled_ghost-noborder'"
            icon-filled
            icon-size="20px"
            icon-name="base/Close"
            @click="handleClosePopUp"
          />
        </Suspense>
      </div>
      <base-search-desktop
        :search="searchInput"
        remove-icon
        @typing="debouncedSearch"
      />
      <base-separator />
    </header>
    <main>
      <div class="units-list">
        <p class="text-body-3 text-bold text-gray-base">Unit</p>
        <div
          v-if="!skeletonLoader && units"
          ref="scrollTargetRef"
          class="units-list__scroll"
        >
          <q-infinite-scroll
            :offset="100"
            :scroll-target="scrollTargetRef"
            @load="fetchPaginationUnits"
          >
            <div
              v-for="(item, index) in units"
              :key="item.id"
              class="property"
              :class="{ 'property--picked': currentUnitIndex === index }"
              @click="handleUnitClick(index, item)"
            >
              <div class="property__flex">
                <div class="provider">
                  <span class="issuer-tag">
                    <img :src="item.provider?.logoFile?.url" alt="provider" >
                  </span>
                  <p class="text-body-3">{{ item.name }}</p>
                </div>
                <Suspense>
                  <base-button
                    :is-spin-anim="!item.isManual"
                    no-shadow
                    round
                    :variant="item.isManual ? 'prime' : 'light'"
                    icon-size="20px"
                    :icon-name="`base/${item.isManual ? 'ChevronDown' : 'Plus'}`"
                    @click.stop="runTokenAddWorkflow(index, item)"
                  />
                </Suspense>
              </div>
            </div>
            <template #loading>
              <div v-if="!isLastPage" class="row justify-center q-my-md">
                <q-spinner-dots size="40px" />
              </div>
            </template>
          </q-infinite-scroll>
        </div>
        <div v-else style="display: grid; gap: 10px">
          <q-skeleton
            v-for="(q, index) in Array.from({ length: 8 })"
            :key="index"
            style="border-radius: 12px"
            width="100%"
            height="42px"
          />
        </div>
      </div>
      <base-separator vertical />
      <Suspense>
        <!-- <TransitionGroup name="fade"> -->
        <swiper
          v-if="!runAddWorkflow"
          class="animate__aminated animate__fadeIn"
          :slides-per-view="1"
          :space-between="30"
          loop
          :allow-touch-move="false"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="(unit, index) in units" :key="index">
            <base-search-card-new
              :estate="unit"
              :size="'search'"
              is-portfolio
              hide-local-currency
            />
          </swiper-slide>
        </swiper>
        <div v-else class="animate__aminated animate__fadeIn token-workflow">
          <span class="gridbox">
            <label class="text-body-1 text-black-monochrome"
              >Tokens amount</label
            >
            <q-input
              v-model="newToken.balance"
              maxlength="20"
              outlined
              type="text"
              @update:model-value="
                () => {
                  $nextTick(() => {
                    const newVal = onlyNumbers(newToken.balance, true);
                    newToken.balance = newVal ?? '';
                  });
                }
              "
            />
          </span>
          <span class="gridbox">
            <label class="text-body-1 text-black-monochrome"
              >Price per token</label
            >
            <q-input
              v-model="newToken.price"
              maxlength="20"
              outlined
              type="text"
              @update:model-value="
                () => {
                  $nextTick(() => {
                    const newVal = onlyNumbers(newToken.price, true);
                    newToken.price = newVal ?? '';
                  });
                }
              "
            />
          </span>
          <base-button
            :disabled="!newToken.balance || !newToken.price || addTokenLoader"
            :loading="addTokenLoader"
            :text="buttonText"
            :variant="'prime'"
            style="margin-top: auto"
            @click.stop="debounceAddToken"
          />
        </div>
        <!-- </TransitionGroup> -->
      </Suspense>
    </main>
  </section>
</template>

<script setup lang="ts">
import { apiPost } from "~/services/api";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import getRoute from "~/utilities/configs/apiMap";
import { debounce } from "lodash";
import { userStore } from "~/store/user";
import type {
  PortfolioManualTokenResponseDTO,
  UpsertPortfolioManualTokenDTO,
} from "~/services/swagger/Api";
import { Notification } from "~/services/notifications/toast";
import { Swiper, SwiperSlide } from "swiper/vue";
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import "swiper/css";
import "swiper/css/pagination";

interface ManualPortfolioUnits extends UpdatedUnitResponseDTO {
  isManual?: boolean;
  manualId?: string;
}

const emit = defineEmits(["hide_popup"]);

const useUserStore = userStore();
const PORTFOLIO = useUserStore.getPortfolioClass();
const currentUnitIndex = ref(0);
const skeletonLoader = ref(true);
const units = ref<ManualPortfolioUnits[]>([]);
const currentUnit = ref<ManualPortfolioUnits | undefined>(undefined);
const offset = ref(0);
const isLastPage = ref(false);
const swiperInst = ref<typeof Swiper | null>(null);
const scrollTargetRef = ref<HTMLElement>();
const searchInput = ref("");
const isPortfolioChanged = ref(false);
const runAddWorkflow = ref(false);
const addTokenLoader = ref(false);
const newToken = reactive({
  balance: "",
  price: "",
});
const userTokens = ref<PortfolioManualTokenResponseDTO[]>([]);

const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};

const handleClosePopUp = () => {
  emit("hide_popup", "ok", isPortfolioChanged.value);
};

const dropNewToken = () => {
  newToken.balance = "";
  newToken.price = "";
};

const buttonText = computed(() =>
  useUserStore.isInSystem ? "Add token" : "Authorize & Add token"
);

const getUnits = async (search?: string, replace?: boolean) => {
  try {
    const url = getRoute({ endpont: "post_units" });
    const data = await apiPost({
      url: url,
      body: { limit: 10, offset: offset.value, search },
    });
    const resRows = data.data?.value?.rows ?? ([] as UpdatedUnitResponseDTO[]);
    isLastPage.value = resRows < 10;
    if (!replace) {
      units.value?.push(...resRows);
    } else {
      units.value = resRows;
    }
    userTokens.value?.forEach((item) => {
      const index = units.value.findIndex((q) => q.id === item.unit.id);
      if (index !== -1) {
        units.value[index].isManual = true;
        units.value[index].manualId = item.id;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchPaginationUnits = async (_, done: Function) => {
  if (isLastPage.value) {
    done();
    return;
  }
  offset.value = offset.value + 10;
  await getUnits();
  done();
};

const initialLoading = async () => {
  skeletonLoader.value = true;
  await getUnits();
  await getManualTokens();
  skeletonLoader.value = false;
};

const getManualTokens = async () => {
  const res = await PORTFOLIO.getManualTokens();
  if (!res) return;
  res?.forEach((item) => {
    const index = units.value.findIndex((q) => q.id === item.unit.id);
    if (index !== -1) {
      units.value[index].isManual = true;
      units.value[index].manualId = item.id;
    }
  });
  userTokens.value = res;
};

const handleUnitClick = (index: number, item: UpdatedUnitResponseDTO) => {
  runAddWorkflow.value = false;
  currentUnit.value = item;
  currentUnitIndex.value = index;
  swiperInst.value!.slideTo(index);
  dropNewToken();
};

const debouncedSearch = debounce((val: string) => {
  handleSearch(val);
}, 300);

const debounceAddToken = debounce(() => {
  addToken(currentUnit.value);
}, 500);

const handleSearch = (val: string) => {
  offset.value = 0;
  searchInput.value = val;
  getUnits(val, true);
};

const runTokenAddWorkflow = (index: number, unit: ManualPortfolioUnits) => {
  currentUnit.value = unit;
  currentUnitIndex.value = index;
  if (unit.isManual) {
    addToken(unit);
    runAddWorkflow.value = false;
    return;
  }
  runAddWorkflow.value = true;
  dropNewToken();
  newToken.price = unit.tokenomics.priceUSD ?? "";
};

const addToken = async (unit?: ManualPortfolioUnits) => {
  if (!useUserStore.isInSystem) {
    emit("hide_popup");
    const res = await useUserStore.loginUserWeb3();
    if (!res) return;
  }
  if (!unit) return;
  isPortfolioChanged.value = true;
  if (unit.isManual) {
    const toast = createTokenActionToast(unit.name ?? "");
    unit.isManual = false;
    await PORTFOLIO.deletePortfolioToken(unit.manualId);
    toast({ timeout: 1000 });
    unit.manualId = undefined;
    return;
  }
  addTokenLoader.value = true;
  const tokenData: UpsertPortfolioManualTokenDTO = {
    unitId: unit.id,
    balance: newToken.balance.toString(),
    price: parseFloat(newToken.price),
  };

  const res = await PORTFOLIO.addPortfolioToken(tokenData);
  unit.isManual = true;
  unit.manualId = res?.id;
  addTokenLoader.value = false;
  runAddWorkflow.value = false;
};

const updatePortfolio = () => {
  if (isPortfolioChanged.value) {
    PORTFOLIO.updateWholePortfolio();
  }
};

const tokenActionToast = new Notification({
  type: "token-portfolio",
  actions: [],
  group: false,
});

const createTokenActionToast = (unitName: string) => {
  return tokenActionToast.createNewToast(`Deleting ${unitName}`);
};

onMounted(() => {
  initialLoading();
});

onBeforeUnmount(() => {
  updatePortfolio();
});
</script>

<style scope lang="scss">
.add-token {
  width: 680px;
  height: fit-content;
  border-radius: 18px;
  background: var(--white-contrast);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  //   overflow: hidden;
  header {
    display: grid;
    width: 100%;
    gap: 17px;
    .base-search {
      display: flex;
      border-radius: 40px;
      border: 1px solid #b8b8b8;
      background: var(--Monochrome-White_contrast, #f3f7fe);
      height: 42px;
      margin: 0;
      &-inner {
        padding: 10px 16px;
        * {
          max-height: 42px;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 18px 0px 5px 0px;
      .base-button {
        width: 28px;
        height: 28px;
      }
      &__text {
        border-bottom: 4px solid var(--violet-main);
      }
    }
  }
  main {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 5px 225px;
    width: 100%;
    height: 100%;
    gap: 5px;
    .units-list {
      padding-right: 7px;
      overflow: hidden;
      display: grid;
      position: relative;
      grid-template-rows: 20px 1fr;
      &__scroll {
        // display: grid;
        padding-right: 10px;
        max-height: 417px;
        overflow: auto;
        .property {
          padding: 8px 8px;
          cursor: pointer;
          &--picked {
            border-radius: 30px;
            background: #fff;
          }
          &__flex {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            .base-button {
              width: 28px;
              height: 28px;
            }
            .provider {
              display: flex;
              align-items: center;
              gap: 10px;
              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;
              }
              .issuer-tag {
                display: flex;
                width: 76px;
                height: 24px;
                padding: 6px 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex-shrink: 0;
                border-radius: 40px;
                border: 1px solid
                  var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
                background: var(--Monochrome-White_contrast, #f3f7fe);
                img {
                  height: 20px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .swiper {
      width: 100%;
      &-active-index {
        position: absolute;
        bottom: 35px;
        right: 9px;
        z-index: 3;
        min-width: 32px;
        background-color: var(--black-monochrome-60);
        padding: 8px;
        border-radius: 8px;
      }
    }
    .base-grid-search-card {
      padding: 0;
      background: transparent;
      box-shadow: none;
      gap: 8px;
      justify-content: flex-start;
    }
    .token-workflow {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .base-button {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.q-field--outlined .q-field__control {
  border-radius: 16px;
  max-height: 42px;
  &:after {
    max-height: 42px;
  }
}
.q-field__native {
  color: var(--Monochrome-Black, #121212);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-height: 42px;
}
.q-field__control-container {
  max-height: 42px;
}
</style>

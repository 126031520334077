<template>
  <section class="base-reviews__leave">
    <div class="base-reviews__leave--right">
      <q-avatar :size="!$device.isMobile ? '40px' : '50px'" class="user-avatar">
        <q-img :src="useUserStore.avatar || defaultAvatar" fit="cover" />
      </q-avatar>
      <base-separator vertical />
      <q-rating
        v-model="rating"
        max="5"
        :size="!$device.isMobile ? '50px' : '40px'"
        :icon="`img:${LeaveReviewGray}`"
        :icon-selected="`img:${LeaveReviewActive}`"
        :readonly="Boolean(isHasReview) || manualHasReview"
        no-dimming
        @update:model-value="handleLeaveReview"
      />
    </div>
    <div class="base-reviews__leave--left">
      <base-button
        :text="$t('review.r_leave')"
        size="sg"
        variant="prime"
        bold-text
        :disabled="Boolean(isHasReview) || manualHasReview"
        @click="handleLeaveReview"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import LeaveReviewGray from "~/assets/icons/base/LeaveReviewGray.svg";
import LeaveReviewActive from "~/assets/icons/base/LeaveReviewActive.svg";
import defaultAvatar from "~/assets/icons/avatars/Unknown.svg";
import type { ReviewResponseDTO } from "~/services/swagger/Api";
import { convertServerStars } from "~/utilities/helpers/review/convertServerRating";
import type { ItemFiles } from "~/types/search-page-items";

interface IProps {
  propId?: string;
  propName?: string;
  propImgs: ItemFiles[];
  reviews?: ReviewResponseDTO[];
}

const props = defineProps<IProps>();
const emit = defineEmits(["update-block"]);

const useUserStore = userStore();
const callPopUp = PopUpServices();

const rating = ref(0);
const isHasReview = computed(() => {
  const userReview = props.reviews?.find(
    (item) => item.user?.id === useUserStore.id
  );
  const newRating = convertServerStars(userReview?.rating);
  rating.value = newRating;
  return userReview;
});

const manualHasReview = ref(false);

// const isHasUnAthorizedReview = () => {
//   const userUid = localStorage.getItem("user_uid");
//   if (!userUid) return false;

// };

const handleLeaveReview = async () => {
  const res = await callPopUp.callPopUp({
    componentName: "base-reviews-leave-review-popup",
    persistent: false,
    data: {
      userName: "",
      rating: rating.value,
      propId: props.propId,
      propImgs: props.propImgs,
      propName: props.propName || "this property",
    },
  });
  if (res) {
    rating.value = convertServerStars(res.rating);
    manualHasReview.value = true;
    emit("update-block", {
      review: res.review,
      user: useUserStore.currentUser,
    });
  }
};
</script>

<style scoped lang="scss">
.base-reviews__leave {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
  &--right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    :deep(.q-rating__icon--hovered) {
      transform: scale(1.1);
    }
    .user-avatar {
      :deep(.q-img) {
        max-width: 40px;
        height: 40px;
      }
    }
  }
  &--left {
    .base-button {
      width: 193px;
    }
  }
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .base-reviews__leave {
    margin-left: 24px;
    margin-right: 24px;
    max-width: calc(100dvw - 48px);
    flex-direction: column;
    gap: 20px;
    &--left {
      .base-button {
        width: 100%;
      }
    }
    &--right {
      gap: 10px;
      .user-avatar {
        :deep(.q-img) {
          max-width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>

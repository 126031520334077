import mitt, { type Emitter } from "mitt";
import type { UnitResponseDTO } from "~/services/swagger/Api";

type Events = {
  "drop-tokenPrice-range": [number, number];
  "drop-minimumInvestment-range": [number, number];
  "drop-irr-range": [number, number];
  "drop-apr-range": [number, number];
  "update-price-range": void;
  "is-compare-badge": boolean;
  "compare-counter": number;
  "change-currency": void;
  "clear-directory-search": void;
  "send-rating": number | undefined;
  // "drop-checkbox-filters": void;
  // "change-filters": any;
  // "reset-filters": void;
  // "update-checkbox-filters": void;
  // "call-fixed-action-butt": {
  //   text: string;
  //   action: () => void;
  //   timeOut?: number;
  //   position: { x: number; y: number };
  // };
  "open-user-from-header": boolean;
  "map-viewport-change": UnitResponseDTO[] | [];
};

const eventBus: Emitter<Events> = mitt<Events>();

export default eventBus;

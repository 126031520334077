<template>
  <section class="base-grid-search-card" :class="[`card-size-${props.size}`]">
    <Suspense>
      <button v-if="showClose" :class="['add-favorite']" @click="closeMapPopup">
        <base-icon
          :name="`base/Close`"
          size="12px"
          color="var(--purple-main)"
        />
      </button>
      <base-favorite-add
        v-else-if="!props.isPortfolio"
        :id="props.estate.id"
        :favorite="props.estate.favorite || isProfilePage"
        :icon-size="'14px'"
        :class="{ 'favorite-add-search': props.size === 'search' }"
        :add-func="UNIT.addToFavorite"
      />
    </Suspense>
    <base-search-card-sold-out-tag v-if="isSold" />

    <div class="search-img">
      <span class="tags">
        <base-tag v-if="tag" :variant="tag" size="small" />
        <span
          v-if="props.estate?.provider?.logoFile?.url"
          v-show="!partnerLogoError"
          class="issuer-logo"
          :title="`Partner: ${props.estate?.provider?.name ?? ''}`"
          @click="handleProviderClick"
        >
          <img
            v-image-error="{
              onImageError: hideParnterLogo,
              onImageLoad: showPartnerLogo,
            }"
            :data-src="props.estate.provider?.logoFile?.url"
            alt="logo"
          >
        </span>
        <base-search-card-property-tag
          :property-subtype="props.estate.subtype"
          is-round
        />
      </span>
      <img
        :src="imageArray"
        alt="img"
        @click="navigateToUnitPage(props.estate.sanitizeName)"
      >
    </div>
    <div class="search-info">
      <div class="search-info-title">
        <span class="title-wrapper">
          <button
            class="text-subtitle text-bold text-black-monochrome title-wrapper-butt"
            :class="[{ 'text-body-2': props.isPortfolio }]"
            @click="navigateToUnitPage(props.estate.sanitizeName)"
          >
            {{ props.estate?.name }}
          </button>
          <span
            class="location"
            :style="{ marginTop: props.isPortfolio ? '10px' : 0 }"
          >
            <button @click="openGoogleMap(props.estate?.coords)">
              <suspense>
                <base-icon name="base/MapPin" size="16px" />
              </suspense>
            </button>
            <button
              class="location-butt"
              style="text-align: left"
              @click="openGoogleMap(props.estate?.coords)"
            >
              <p
                class="text-body-2 text-black-monochrome-60"
                :class="[{ 'text-body-3': props.isPortfolio }]"
              >
                {{ props.estate.location || $t("search_card.fr_loc") }}
              </p>
            </button>
            <span
              class="rating"
              :class="{ pointer: props.estate.rating }"
              @click="
                navigateToReviews(
                  props.estate.sanitizeName,
                  props.estate.rating
                )
              "
            >
              <base-icon
                class="icon-animated"
                name="base/StarFull"
                color="#F59914"
                size="13px"
              />
              <p v-if="props.estate.rating" class="text-body-2">
                {{ props.estate.rating }}
              </p>
              <p v-else class="text-body-2">-</p>
            </span>
          </span>
        </span>
      </div>
      <q-seperator class="separator" />
      <span class="list-gridbox">
        <span class="list-flexbox">
          <p
            class="text-body-1 text-black-monochrome"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{ getPriceTitle }}
          </p>
          <span
            class="text-body-1 text-bold text-black-monochrome"
            style="display: flex; align-items: center"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            <p
              v-if="isLocalCurrency"
              class="text-body-1 text-bold"
              :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
              style="margin-top: 1px"
            >
              ≈
            </p>
            {{
              BASE_CURRENCY.symbol +
              formatPrice(props.estate?.priceUSD, true, isLocalCurrency, {
                price: props.estate?.price,
                currency: props.estate?.currency,
              })
            }}
          </span>
        </span>
        <span
          v-if="isLocalCurrency && !props.hideLocalCurrency"
          class="list-flexbox"
          style="justify-content: flex-end; gap: 4px"
        >
          <span
            style="display: flex"
            class="text-body-2 text-black-monochrome-60"
          >
            {{ currentCurrency.symbol + formatPrice(props.estate?.price) }}
          </span>

          <base-tooltip
            :tooltip-text="`${$t('search_card.fr_operates', { currency: currentCurrency.code })}<br> ${$t('search_card.fr_rate')}`"
            style="margin-bottom: 1px"
          >
            <template #icon>
              <suspense>
                <base-icon
                  name="base/Info"
                  size="16px"
                  color="var(--black-monochrome-60)"
                />
              </suspense> </template
          ></base-tooltip>
        </span>
      </span>
      <q-seperator class="separator" />
      <div class="info-dynamic">
        <q-seperator class="separator separator-vert separator-abs" />

        <span class="list-flexbox order-1">
          <p class="text-body-3 text-black-monochrome">
            {{ $t("search_card.fr_token") }}
          </p>
          <p
            class="text-body-2 text-bold text-black-monochrome"
            style="white-space: nowrap"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{
              BASE_CURRENCY.symbol +
              formatPrice(
                props.estate?.tokenomics?.priceUSD,
                true,
                isLocalCurrency,
                {
                  price: props.estate?.tokenomics?.price,
                  currency: props.estate?.currency,
                }
              )
            }}
          </p>
        </span>
        <span class="list-flexbox order-4">
          <span class="tooltip">
            <p class="text-body-3 text-black-monochrome">
              {{ $t("search_card.fr_min_invest") }}
            </p>
            <base-tooltip
              v-if="isLocalCurrency && !props.hideLocalCurrency"
              :tooltip-text="`${$t('search_card.fr_operates', { currency: currentCurrency.code })}<br> ${$t('search_card.fr_rate')}`"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>

          <span
            class="text-body-2 text-bold text-black-monochrome"
            style="display: flex; align-items: center"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            <p
              v-if="isLocalCurrency"
              class="font--b5-3"
              style="margin-top: 1px"
            >
              ≈
            </p>
            {{
              BASE_CURRENCY.symbol +
              formatPrice(
                props.estate?.minimumInvestmentUSD,
                true,
                isLocalCurrency,
                {
                  price: props.estate?.minimumInvestment,
                  currency: props.estate?.currency,
                }
              )
            }}
          </span>
        </span>

        <span class="list-flexbox order-2">
          <span class="tooltip text-body-3 text-black-monochrome"
            >IRR
            <base-tooltip
              :tooltip-text="$t('finance.IRR')"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <p
            v-if="props.estate?.irr"
            class="text-body-2 text-bold text-black-monochrome"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{ formatPercentage(props.estate?.irr) }}%
          </p>
          <base-apr-irr v-else @click="openIssuer"/>
        </span>
        <span class="list-flexbox order-5">
          <p class="text-body-3 text-black-monochrome">
            {{ $t("search_card.fr_growth") }}
          </p>
          <p
            class="text-body-2 text-bold text-black-monochrome"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{
              formatPercentage(
                props.estate?.finance?.projectTotalIncome?.subfields
                  ?.projectionAppreciation
              )
            }}%
          </p>
        </span>
        <span class="list-flexbox order-3">
          <span class="tooltip text-body-3 text-black-monochrome"
            >APR
            <base-tooltip
              :tooltip-text="$t('finance.APR')"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <p
            v-if="props.estate?.apr"
            class="text-body-2 text-bold text-black-monochrome"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{ formatPercentage(props.estate?.apr) }}%
          </p>
          <base-apr-irr v-else @click="openIssuer"/>
        </span>
        <q-seperator v-if="props.size === 'search'" class="separator order-6" />

        <span class="list-flexbox order-7">
          <p class="text-body-3 text-black-monochrome">
            {{ $t("search_card.fr_tokens_avail_short") }}
          </p>
          <p
            class="text-body-2 text-bold text-black-monochrome"
            :class="[{ 'text-body-3 text-bold': props.isPortfolio }]"
          >
            {{
              formatPercentage(
                props.estate?.tokenomics?.availableSupplyPercentage
              )
            }}%
          </p>
        </span>
      </div>
      <q-seperator v-if="props.size === 'suggestions'" class="separator" />
    </div>
    <nav v-if="!props.isPortfolio" class="search-controls">
      <base-button
        variant="dark_ghost"
        :text="$t('search_card.fr_visit')"
        bold-text
        size="ls"
        @click.stop="navigateToUnitPage(props.estate.sanitizeName)"
      />
      <base-button
        v-if="props.size === 'suggestions'"
        variant="prime"
        :text="$t('search_card.fr_contact')"
        size="ls"
        bold-text
        @click="openIssuer"
      />
    </nav>
  </section>
</template>

<script setup lang="ts">
import openGoogleMap from "~/utilities/helpers/map/openGoogleMap";
import {
  formatPrice,
  formatPercentage,
} from "~/utilities/helpers/format-data/number";
import { getCurrentCurrency, BASE_CURRENCY } from "~/composables/CURRENCIES";
import type { FilterCheckbox, PropertyTypes } from "../../navigator/types";
import { unitPageStore, type UpdatedUnitResponseDTO } from "~/store/unitPage";
import { gtagReachGoal } from "~/services/SEO/goals/createCustomGoal";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";
import { navigateToReviews } from "~/utilities/helpers/review/navigateToReviews";

interface IProps {
  estate: UpdatedUnitResponseDTO;
  size: "suggestions" | "search";
  isPortfolio?: boolean;
  propertyType: PropertyTypes;
  hideLocalCurrency?: boolean;
  showClose?: boolean;
}

const { t } = useI18n();

const localePath = useLocalePath();
const route = useRoute();
const isProfilePage = computed(() => route.path === "/profile");
const useUnitpageStore = unitPageStore();
const UNIT = useUnitpageStore.createUnit();
const isLocalCurrency = computed(
  () => currentCurrency.value.code !== BASE_CURRENCY.value.code
);

const props = defineProps<IProps>();
const emit = defineEmits(["search-country", "click-provider"]);
const partnerLogoError = ref(true);

const hideParnterLogo = () => {
  partnerLogoError.value = true;
};
const showPartnerLogo = () => {
  partnerLogoError.value = false;
};
const isSold = computed(() => props.estate?.status === "sold_out");

const tag = computed(() => {
  if (isSold.value) return "";
  if (props.estate.tags?.length && props.estate.tags[0]) {
    return props.estate.tags[0];
  }
  return "";
});

const currentCurrency = computed(() =>
  getCurrentCurrency(props.estate?.currency)
);

const goalSwitch = () => {
  switch (true) {
    case route.path === "/directory":
      return "directory__open_ref";
    case route.path === "/profile":
      return "profile__open_ref";
    case route.path !== "/profile" && props.size === "suggestions":
      return "suggestions__open_ref";
    default:
      return "directory__open_ref";
  }
};

const openIssuer = () => {
  const goal = goalSwitch();
  gtagReachGoal[goal]({
    value: props.estate?.referralLink?.link,
  });
  redirectByProviderName({
    providerName: props.estate?.provider?.name,
    providerLink: props.estate?.provider?.url,
    utmLink: props.estate?.referralLink?.link,
    externalUrl: props.estate?.externalUrl,
    images: props.estate?.files,
    logoFile: props.estate?.provider?.logoFile?.url,
  });
};

const navigateToUnitPage = (id: string | null) => {
  if (!id) return;
  const route = localePath(`/property/${id}`);
  window.open(window.location.origin + route, "_blank");
};

const getPriceTitle = computed(() => {
  switch (props.propertyType) {
    case "estates":
      return t("search_card.fr_price");
    case "lands":
      return t("search_card.fr_price_land");
    default:
      return t("search_card.fr_price");
  }
});

const imageArray = computed(() => {
  const file = props.estate?.files.find((item) => {
    const hasThumbnails = (item.file?.thumbnails?.length ?? 0) > 0;
    return hasThumbnails;
  });
  const url = getThumbnail(
    file?.file || props.estate?.files?.[0]?.file,
    "thumbnail_200x140"
  );
  return url;
});

const handleProviderClick = () => {
  const provider = props.estate.provider;
  if (!provider || !provider.id) return;
  const filter: FilterCheckbox = {
    label: provider.name,
    count: 0,
    checked: false,
    server_label: provider.id,
  };
  if (route.path !== "/directory") {
    const route = localePath(`/directory?providerIds[0]=${provider.id}`);
    window.open(window.location.origin + route, "_blank");
  } else {
    emit("click-provider", filter);
  }
};

const closeMapPopup = () => {
  emit("close");
};
</script>

<style scoped lang="scss">
.base-grid-search-card {
  position: relative;
  display: inline-flex;
  padding: 12px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
  .favorite-add-search {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    .favorite-add-search {
      opacity: 1;
    }
  }
  .search-img {
    position: relative;
    width: 100%;
    height: 140px;
    border-radius: 12px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .tags {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 999;
      display: flex;
      align-items: center;
      width: fit-content;
      justify-content: flex-start;
      gap: 10px;
      .issuer-logo {
        position: relative;
        display: flex;
        height: 24px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        max-width: 110px;
        border-radius: 40px;
        border: 1px solid var(--gray-40);
        background: var(--white-contrast);
        cursor: pointer;
        img {
          height: 15px;
          width: 100%;
          object-fit: contain;
          display: block;
        }
      }
    }
  }
  .search-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .separator {
      width: 100%;
      height: 1px;
      background-color: var(--gray-40);
      &-vert {
        height: 100%;
        width: 1px;
      }
      &-abs {
        position: absolute;
        left: 50%;
        top: 0;
      }
    }
    &-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 3px;
      width: 100%;
      max-width: 100%;

      .title-wrapper {
        max-width: 100%;
        width: 100%;
        button,
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-butt {
          @include drop-button-styles;
          max-width: 100%;
        }

        .location {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          grid-column: span 2;
          max-width: 100%;
          width: 100%;
          button {
            @include drop-button-styles;
          }
          &-butt {
            display: flex;
            gap: 10px;
            transition: font-weight 0.3s ease;
            max-width: 60%;
            &-pointer {
              &:hover {
                font-weight: bold;
              }
            }
          }
          .rating {
            display: flex;
            gap: 4px;
            cursor: default;
            margin-left: auto;
            line-height: 1;
            align-items: center;
            p {
              line-height: 1;
            }
            &:hover {
              .icon-animated {
                rotate: 1turn;
              }
            }
            .icon-animated {
              transition: rotate 0.5s ease;
            }
          }
          .pointer {
            cursor: pointer;
          }
        }
      }
    }
    .list-gridbox {
      width: 100%;
      display: grid;
      align-items: center;
      justify-items: flex-start;
      gap: 4px;
    }
    .list-flexbox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
    .order-4 {
      :first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 105px;
      }
    }
    .info-dynamic {
      position: relative;
      width: 100%;
      .tooltip {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
  .search-controls {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;
    justify-content: center;
    .base-button {
      width: 100%;
      // max-width: 50%;
      max-width: 139px;
    }
  }
  .add-favorite {
    position: absolute;
    background: var(--white-monochrome);
    right: 20px;
    top: 20px;
    z-index: 2;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 0;
    will-change: scale;
    cursor: pointer;
    &:hover {
      .base-icon {
        animation: pulsate-bck 0.5s ease-in-out infinite both;
        @keyframes pulsate-bck {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
}
.card-size {
  &-suggestions {
    max-width: 314px;
    .search-info {
      .info-dynamic {
        .separator {
          display: block;
        }
        display: grid;
        grid-template-columns: 130px 130px;
        row-gap: 8px;
        column-gap: 32px;
      }
    }
  }
  &-search {
    max-width: 229px;
    .search-info {
      .info-dynamic {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr;

        @for $i from 1 through 7 {
          .order-#{$i} {
            grid-row: #{$i};
          }
        }

        .separator {
          &-abs {
            display: none;
          }
        }
      }
    }
    .search-controls {
      .base-button {
        max-width: 100%;
      }
    }
  }
}
// .add-favorite {
//   cursor: pointer;
//   background: var(--white-monochrome);
//   position: absolute;
//   right: 20px;
//   top: 20px;
//   z-index: 2;
//   width: 24px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
// }
</style>

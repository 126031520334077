<template>
  <button
    v-bind="$attrs"
    ref="baseButtonRef"
    class="base-button"
    :class="[
      `base-button--${props.variant}`,
      `base-button--${props.size}`,
      `base-button--${props.textSize}`,
      `font--${props.font}`,
      {
        'base-button--icon-right': props.iconPosition === 'right',
        'base-button--round': props.round,
      },
      {
        'base-button--spin': props.isSpinAnim,
      },
      {
        'base-button__tabOff':
          props.playTabs &&
          typeof props.disabledTab === 'boolean' &&
          props.disabledTab,
      },
    ]"
    :style="{
      boxShadow:
        props.playTabs || props.noShadow
          ? 'none'
          : '0px 2px 3px 0px rgba(0, 0, 0, 0.2)',
    }"
    @click="handleClick"
  >
    <base-icon
      v-if="props.iconName && !props.loading"
      :size="
        props.iconSize ? props.iconSize : props.size === 'lg' ? '24px' : '18px'
      "
      :name="props.iconName"
      :class="[`base-button--${props.variant}`]"
      :filled="props.iconFilled"
    />
    <slot />
    <span v-if="props.loading" class="base-button__loader"/>
    <p
      v-if="!props.round"
      :style="[
        { visibility: props.loading ? 'hidden' : 'visible' },
        { fontWeight: props.boldText ? 600 : 400 },
      ]"
    >
      {{ props.text }}
    </p>
  </button>
</template>

<script setup lang="ts">
interface Props {
  text?: string;
  textSize?: "main" | "landing";
  boldText?: boolean;
  variant?:
    | "prime"
    | "dark"
    | "light"
    | "light_bordered"
    | "ghost"
    | "dark_ghost"
    | "filled_ghost"
    | "filled_ghost-noborder";
  size?:
    | "xs"
    | "xss"
    | "ls"
    | "sm"
    | "sg"
    | "sl"
    | "lg"
    | "tab"
    | "round-sm"
    | "round-xs"
    | "round-ls";
  disabledTab?: boolean;
  playTabs?: boolean;
  font?: "h1" | "h2" | "h3" | "h4" | "b1" | "b2" | "b3" | "b4" | "b5";
  iconName?: string;
  iconPosition?: "left" | "right";
  round?: boolean;
  iconSize?: string;
  iconFilled?: boolean;
  loading?: boolean;
  noShadow?: boolean;
  isSpinAnim?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: "prime",
  size: "sm",
  font: "b2",
  iconPosition: "left",
  textSize: "main",
});

const emit = defineEmits(["click", "customClick"]);

const baseButtonRef = ref<HTMLButtonElement | null>(null);

const handleClick = (event: MouseEvent) => {
  if (props.loading) return;
  emit("click", event);
  emit("customClick", event);
};

defineExpose({
  baseButtonRef,
});
</script>

<style scoped lang="scss">
$borderWidth: 1px;
$transitionDuration: 0.3s;
$transition: background-color $transitionDuration;

@mixin invisible-border {
  border-color: transparent;
  border-style: solid;
  border-width: $borderWidth;
}

@mixin dark {
  overflow: hidden;
  position: relative;
  @include invisible-border;
  color: var(--white-monochrome);
  background-color: var(--black-monochrome);
  transition:
    border-color 0.3s ease,
    background-color 0.2s ease;
  &::after {
    position: absolute;
    content: "";
    background: transparent;
    height: 100px;
    left: -150%;
    opacity: 0.2;
    top: -20px;
    transform: rotate(35deg);
    transition: left 2s 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: left;
    width: 40px;
    z-index: 2;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-width: 1px;
      border-color: var(--black-monochrome-60);
      background-color: var(--white-monochrome);
      color: var(--black-monochrome);

      &::after {
        left: 120%;
        background: var(--black-monochrome-60);
      }

      .base-icon {
        color: var(--black-monochrome);
      }
    }
  }
}

@mixin prime {
  color: var(--white-monochrome);
  background-color: var(--violet-main);
  border: none;
  transition: $transition;
  &:hover {
    background-color: var(--purple-main);
  }
}

@mixin light {
  @include invisible-border;
  color: var(--black-monochrome);
  background-color: var(--white-monochrome);
  &:hover {
    border-color: var(--black-monochrome-60);
  }
  .base-button__loader {
    color: var(--violet-main);
  }
}
@mixin light_bordered {
  color: var(--black-monochrome);
  background-color: var(--white-contrast);
  border: 1px solid var(--gray-40);
  transition: $transition;
  &:hover {
    background-color: var(--white-transparent);
  }
  .base-button__loader {
    color: var(--violet-main);
  }
}

@mixin ghost {
  color: var(--white-monochrome);
  background-color: transparent;
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--white-monochrome);
  border-style: solid;
  border-width: $borderWidth;
  &:hover {
    background-color: var(--white-transparent);
  }
}

@mixin dark_ghost {
  color: var(--black-monochrome);
  background-color: transparent;
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--black-monochrome);
  border-style: solid;
  border-width: $borderWidth;
  &:hover {
    background-color: var(--gray-monochrome-40);
  }
}

@mixin filled_ghost {
  color: var(--black-monochrome-54);
  background-color: var(--black-monochrome-24);
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--black-monochrome-54);
  border-style: solid;
  border-width: $borderWidth;
}

.base-button {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 28px 8px 28px;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 40px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400;
  &--spin {
    transition: rotate 0.6s ease;
    will-change: rotate;
    &:hover {
      rotate: 1turn;
    }
  }
  &--main {
    font-size: 14px;
  }
  &--landing {
    font-size: 16px;
  }
  &--prime {
    @include prime;
  }
  &--dark {
    @include dark;
  }
  &--light {
    @include light;
  }
  &--ghost {
    @include ghost;
  }
  &--dark_ghost {
    @include dark_ghost;
  }
  &--filled_ghost {
    @include filled_ghost;
  }
  &--light_bordered {
    @include light_bordered;
  }
  &--filled_ghost-noborder {
    @include filled_ghost;
    @include invisible-border;
    transition: scale 0.3s ease;

    &:hover {
      background-color: var(--gray-monochrome-40);
    }
    &:active {
      scale: 0.95;
    }
  }
  &--tab {
    height: 28px;
    font-size: 14px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    padding: 4px 16px 4px 16px;
  }
  &__loader {
    position: absolute;
    // color: #fff;
    font-size: 5px;
    max-width: 100%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
  }

  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow:
        0 -3em 0 0.2em,
        2em -2em 0 0em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
      box-shadow:
        0 -3em 0 0,
        2em -2em 0 0.2em,
        3em 0 0 0,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
      box-shadow:
        0 -3em 0 -0.5em,
        2em -2em 0 0,
        3em 0 0 0.2em,
        2em 2em 0 0,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 0,
        2em 2em 0 0.2em,
        0 3em 0 0em,
        -2em 2em 0 -1em,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    50% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 0em,
        0 3em 0 0.2em,
        -2em 2em 0 0,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow:
        0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 0,
        -2em 2em 0 0.2em,
        -3em 0 0 0,
        -2em -2em 0 -1em;
    }
    75% {
      box-shadow:
        0em -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow:
        0em -3em 0 0,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0,
        -2em -2em 0 0.2em;
    }
  }
  &--xs {
    height: 32px;
    padding: 4px 10px 4px 8px;
  }
  &--xss {
    height: 36px;
    padding: 6px 12px;
    gap: 6px;
  }
  &--ls {
    height: 40px;
    padding: 12px 32px;
  }
  &--sm {
    height: 41px;
  }
  &--sg {
    height: 41px;
    padding: 8px 16px 8px 16px;
  }
  &--sl {
    height: 49px;
  }
  &--lg {
    height: 53px;
  }
  &--round {
    height: 44px;
    padding: 13px;
    border-radius: 50%;
    gap: 0;
  }
  &--round-sm {
    padding: 4px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    gap: 0;
  }
  &--round-xs {
    width: 40px;
    height: 40px;
    padding: 11px;
    border-radius: 50%;
    gap: 0;
  }
  &--round-ls {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    gap: 0;
  }

  &--icon {
    &-right {
      flex-direction: row-reverse;
    }
  }
  &__tabOff {
    will-change: font-weight;
    transition: font-weight 0.3s ease;
    background-color: transparent;
    color: var(--black-monochrome);
    font-weight: 400;
    &:hover {
      color: initial;
      background-color: transparent;
    }
  }
}
.base-icon {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  &::after {
    display: none;
  }
  :deep(.nuxt-icon) {
    svg {
      margin: 0;
    }
  }
}
</style>

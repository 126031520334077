import payload_plugin_ArCOAFXaYQ from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_2O56zDccJD from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_657C48P2PB from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_axcxY4nRv5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MrYunmuanl from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@5.4.14_@types+node@22.13.5_sass@1.85.0_ters_8d9c5e044eaae2c02ddfa89b508d1e1f/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_FhGAFNv5En from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YkiGCMTFde from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dfTPCfpOxV from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4Q0jlhNXrU from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/app/main-site/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_KxZxzkiB8Q from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/main-site/.nuxt/components.plugin.mjs";
import prefetch_client_gWZqaoJhN7 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_VkFpWw3QZB from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SSxIDMYG5o from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_ZZE9uty7n3 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.34.8_vite@5.4.14_@types+node@22.13.5_33c5a8c70b94b2873b2c992309c66486/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_oYkBbA0Sd7 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.34.8_vite@5.4.14_@types+node@22.13.5_33c5a8c70b94b2873b2c992309c66486/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_oS1oT0aPIL from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_2J4fydHLts from "/app/node_modules/.pnpm/nuxt-quasar-ui@2.1.12_@quasar+extras@1.16.17_magicast@0.3.5_quasar@2.17.2/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import rest_api_zNWx1STPxo from "/app/main-site/plugins/rest-api.js";
import gtm_MVzSxF8h1r from "/app/main-site/plugins/gtm.js";
import recaptcha_85gNSCNFUU from "/app/main-site/plugins/recaptcha.ts";
import gtag_bAM3HbiZET from "/app/main-site/plugins/gtag.js";
import error_handler_kEP5FliEXj from "/app/main-site/plugins/error-handler.ts";
import image_error_Tix9NvX1QD from "/app/main-site/plugins/image-error.ts";
import google_auth_nRC6QNHmRt from "/app/main-site/plugins/google_auth.ts";
import sentry_3AyO8nEfhE from "/app/main-site/plugins/sentry.ts";
import init_aSRJeOQ8bB from "/app/node_modules/.pnpm/nuxt-schema-org@3.4.1_@unhead+vue@1.11.19_vue@3.5.13_typescript@5.5.4___magicast@0.3.5__5fcfd2bded908dc8e18c9b5c5d433548/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  payload_plugin_ArCOAFXaYQ,
  revive_payload_client_2O56zDccJD,
  unhead_657C48P2PB,
  router_axcxY4nRv5,
  _0_siteConfig_MrYunmuanl,
  payload_client_FhGAFNv5En,
  navigation_repaint_client_YkiGCMTFde,
  check_outdated_build_client_dfTPCfpOxV,
  chunk_reload_client_4Q0jlhNXrU,
  plugin_zbacBAXhj0,
  plugin_vue3_KxZxzkiB8Q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gWZqaoJhN7,
  switch_locale_path_ssr_VkFpWw3QZB,
  i18n_SSxIDMYG5o,
  siteConfig_ZZE9uty7n3,
  inferSeoMetaPlugin_oYkBbA0Sd7,
  plugin_oS1oT0aPIL,
  plugin_2J4fydHLts,
  rest_api_zNWx1STPxo,
  gtm_MVzSxF8h1r,
  recaptcha_85gNSCNFUU,
  gtag_bAM3HbiZET,
  error_handler_kEP5FliEXj,
  image_error_Tix9NvX1QD,
  google_auth_nRC6QNHmRt,
  sentry_3AyO8nEfhE,
  init_aSRJeOQ8bB
]
<template>
  <aside
    class="nav-container"
    :class="{ 'nav-container--mobile': $device.isMobile }"
  >
    <nav class="base-navigator">
      <sectionv class="rest-nav">
        <q-skeleton v-if="isFilterLoader" class="search-skeleton__filters" />

        <div v-else class="rest-nav-wrapper">
          <div
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.price_range.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('price_range')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_just_price") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('price_range').class"
                  :style="getClassesAndStyles('price_range').style"
                  @click.stop="
                    resetSliderFilter('price_range', 'tokenPrice', [
                      useFiltersStore.currentStats?.token.priceMin!,
                      useFiltersStore.currentStats?.token.priceMax!,
                    ])
                  "
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('price_range').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-price-range-slider
              v-if="!isFilterLoader"
              :max-price="useFiltersStore.priseRange?.priceMax"
              :min-price="useFiltersStore.priseRange?.priceMin"
              :range="useFiltersStore.priseRange?.range"
              style-type="filters"
              :drop-func="dropPrice"
              @emit-range="
                (data) => {
                  updateSliderFilter('price_range', 'tokenPrice', data);
                }
              "
            />
          </div>
          <base-separator />
          <div
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.min_invest.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('min_invest')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_min_invest_navigator") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('min_invest').class"
                  :style="getClassesAndStyles('min_invest').style"
                  @click.stop="
                    resetSliderFilter('min_invest', 'minimumInvestment', [
                      useFiltersStore.currentStats?.minimumInvestment ?? 0,
                      useFiltersStore.currentStats?.maximumInvestment ?? 100,
                    ])
                  "
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('min_invest').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-input-range
              name="minimumInvestment"
              is-slider
              :slider-max="useFiltersStore.currentStats?.maximumInvestment"
              :slider-min="useFiltersStore.currentStats?.minimumInvestment"
              :drop-func="dropMinInvest"
              conver-able
              @change="
                (data) => {
                  updateSliderFilter('min_invest', 'minimumInvestment', data);
                }
              "
            />
          </div>
          <base-separator />

          <div
            v-if="props.propertyType !== 'lands'"
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.irr.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('irr')">
              <p class="text-body-2 text-bold text-black-monochrome">IRR</p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('irr').class"
                  :style="getClassesAndStyles('irr').style"
                  @click.stop="
                    resetSliderFilter('irr', 'irr', [
                      useFiltersStore.currentStats?.irrMin ?? 0,
                      useFiltersStore.currentStats?.irrMax ?? 100,
                    ])
                  "
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('irr').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-input-range
              name="irr"
              after="%"
              :maxlength="3"
              is-slider
              :slider-max="useFiltersStore.currentStats?.irrMax"
              :slider-min="useFiltersStore.currentStats?.irrMin"
              :drop-func="dropIrr"
              @change="
                (data) => {
                  updateSliderFilter('irr', 'irr', data);
                }
              "
            />
          </div>

          <base-separator v-if="props.propertyType !== 'lands'" />

          <div
            v-if="props.propertyType !== 'lands'"
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.apr.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('apr')">
              <p class="text-body-2 text-bold text-black-monochrome">APR</p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('apr').class"
                  :style="getClassesAndStyles('apr').style"
                  @click.stop="
                    resetSliderFilter('apr', 'apr', [
                      useFiltersStore.currentStats?.aprMin ?? 0,
                      useFiltersStore.currentStats?.aprMax ?? 100,
                    ])
                  "
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('apr').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-input-range
              name="apr"
              after="%"
              :maxlength="3"
              is-slider
              :slider-max="useFiltersStore.currentStats?.aprMax"
              :slider-min="useFiltersStore.currentStats?.aprMin"
              :drop-func="dropApr"
              @change="
                (data) => {
                  updateSliderFilter('apr', 'apr', data);
                }
              "
            />
          </div>

          <base-separator />

          <div
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.documents.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('documents')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("unit_page.un_documents") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('documents').class"
                  :style="getClassesAndStyles('documents').style"
                  @click.stop="handleBedroomReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('documents').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in documentsCheckboxes"
              :checkbox-label="item.label"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleDocumentsCheckboxClick(item)"
            />
          </div>
          <base-separator />
          <div
            v-if="props.propertyType !== 'lands'"
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.bedroom.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('bedroom')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_beds") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('bedroom').class"
                  :style="getClassesAndStyles('bedroom').style"
                  @click.stop="handleBedroomReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('bedroom').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in bedroomCheckboxes"
              :checkbox-label="item.label"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleBedroomCheckboxClick(item)"
            />
          </div>

          <base-separator v-if="props.propertyType !== 'lands'" />

          <div
            v-if="props.propertyType !== 'lands'"
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.bathroom.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('bathroom')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_baths") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('bathroom').class"
                  :style="getClassesAndStyles('bathroom').style"
                  @click.stop="handleBathroomReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('bathroom').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in bathroomCheckboxes"
              :checkbox-label="item.label"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleBathroomCheckboxClick(item)"
            />
          </div>
          <base-separator />

          <div
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.rating.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('rating')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_rating") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('rating').class"
                  :style="getClassesAndStyles('rating').style"
                  @click.stop="handleRatingReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('rating').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in ratingCheckboxes"
              :checkbox-label="item.label"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleRatingCheckboxClick(item)"
            >
              <div
                v-for="_ in Array.from({
                  length: isNaN(parseInt(item.label))
                    ? 0
                    : parseInt(item.label),
                })"
                v-if="item.label !== 'No rating'"
              >
                <base-icon
                  name="base/StarFull"
                  color="#F59914"
                  size="14px"
                  filled
                />
              </div>
              <p v-else>{{ item.label }}</p>
            </base-navigator-filters-checkbox>
          </div>

          <base-separator v-if="props.propertyType !== 'lands'" />
          <div
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.providerIds.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('providerIds')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_issuer") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('providerIds').class"
                  :style="getClassesAndStyles('providerIds').style"
                  @click.stop="handleCompanyReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('providerIds').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in companyCheckboxes"
              :checkbox-label="item.label"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleCompanyCheckboxClick(item)"
            />
          </div>

          <base-separator />
          <div
            class="filter-item"
            :style="{
              maxHeight: expandFiltersMap.countryId.expanded
                ? countryCheckboxes.length * 50 + 'px'
                : '18px',
            }"
          >
            <button class="expand-filter" @click="switchExpand('countryId')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_locs") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('countryId').class"
                  :style="getClassesAndStyles('countryId').style"
                  @click.stop="handleCountryReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('countryId').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in countryCheckboxes"
              :checkbox-label="countryNames[item.label] || 'Others'"
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleCountryCheckboxClick(item)"
            />
          </div>
          <base-separator />
          <div
            v-if="props.propertyType !== 'lands'"
            class="filter-item"
            :class="{
              'filter-item-open': expandFiltersMap.stage.expanded,
            }"
          >
            <button class="expand-filter" @click="switchExpand('stage')">
              <p class="text-body-2 text-bold text-black-monochrome">
                {{ $t("search_filters.fr_stage") }}
              </p>
              <span class="expand-filter-flexbox">
                <span
                  :class="getClassesAndStyles('stage').class"
                  :style="getClassesAndStyles('stage').style"
                  @click.stop="handleStageReset"
                  >{{ $t("search_filters.fr_reset") }}</span
                >
                <suspense>
                  <base-icon
                    name="base/ChevronDown"
                    color="var(--black-monochrome)"
                    size="18px"
                    :rot="getClassesAndStyles('stage').rotation"
                  />
                </suspense>
              </span>
            </button>
            <base-navigator-filters-checkbox
              v-for="item in stageCheckboxes"
              :checkbox-label="
                $t(`search_filters.fr_stages.${item.label.replace(/ /g, '_')}`)
              "
              :label-amount="item.count"
              :checked="item.checked"
              :is-mobile="false"
              :is-zero-amount-displayed="true"
              :placeholder-checked="false"
              @click="handleStageCheckboxClick(item)"
            />
          </div>
        </div>
      </sectionv>
      <div v-show="!isFilterLoader" class="base-navigator--controls">
        <!-- <div
        v-show="!isFilterLoader && Object.keys(route.query).length"
        class="base-navigator--controls"
      > -->
        <base-button
          v-if="!$device.isMobile"
          :text="$t('search_filters.fr_clear')"
          size="xss"
          variant="dark_ghost"
          @click="dropAllFilters"
        />
      </div>
    </nav>
  </aside>
</template>

<script setup lang="ts">
import type {
  FilterCheckbox,
  ExpandFiltersMap,
  PropertyTypes,
} from "~/components/base/navigator/types";
import eventBus from "~/utilities/composables/eventBus";
import { countryNames } from "~/utilities/constants/countryCodes";
import { filtersStore } from "~/store/search_filters";

interface IProps {
  handleStageCheckboxClick: (item: FilterCheckbox) => void;
  stageCheckboxes: FilterCheckbox[];
  countryCheckboxes: FilterCheckbox[];
  companyCheckboxes: FilterCheckbox[];
  bathroomCheckboxes: FilterCheckbox[];
  bedroomCheckboxes: FilterCheckbox[];
  ratingCheckboxes: FilterCheckbox[];
  documentsCheckboxes: FilterCheckbox[];
  propertyCheckboxes: FilterCheckbox[];
  handleStageReset: () => void;
  expandFiltersMap: ExpandFiltersMap;
  handleCountryCheckboxClick: (item: FilterCheckbox) => void;
  handleCountryReset: () => void;
  handleBathroomCheckboxClick: (item: FilterCheckbox) => void;
  handleRatingCheckboxClick: (item: FilterCheckbox) => void;
  handleCompanyCheckboxClick: (item: FilterCheckbox) => void;
  handleBathroomReset: () => void;
  handleRatingReset: () => void;
  handleCompanyReset: () => void;
  handleBedroomCheckboxClick: (item: FilterCheckbox) => void;
  handleDocumentsCheckboxClick: (item: FilterCheckbox) => void;
  handleBedroomReset: () => void;
  handlePropertyTypeCheckboxClick: (item: FilterCheckbox) => void;
  updateSliderFilter: Function;
  resetSliderFilter: Function;
  isFilterLoader: boolean;
  propertyType: PropertyTypes;
  dropAllFilters?: () => void;
}

const props = defineProps<IProps>();

const useFiltersStore = filtersStore();
const route = useRoute();

const { isMobile } = useDevice();

const switchExpand = (f: keyof typeof props.expandFiltersMap) => {
  props.expandFiltersMap[f].expanded = !props.expandFiltersMap[f].expanded;
};

const getClassesAndStyles = computed(() => {
  return (key: keyof typeof props.expandFiltersMap) => ({
    class: [
      {
        animate__fadeIn: props.expandFiltersMap[key].canReset === "show",
        animate__fadeOut: props.expandFiltersMap[key].canReset === "hide",
      },
      "text-body-2 text-bold",
      "text-violet-main",
      "animate__animated",
    ],
    style: {
      display:
        props.expandFiltersMap[key].canReset === "init" ? "none" : "block",
    },
    rotation: props.expandFiltersMap[key].expanded ? "180deg" : "0deg",
  });
});

const dropIrr = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-irr-range", ctx);
  };
};
const dropApr = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-apr-range", ctx);
  };
};
const dropMinInvest = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-minimumInvestment-range", ctx);
  };
};
const dropPrice = () => {
  return function (ctx: () => void) {
    eventBus.on("drop-tokenPrice-range", ctx);
  };
};

const checkIfMobile = () => {
  if (isMobile && props.expandFiltersMap) {
    Object.keys(props.expandFiltersMap).forEach((key) => {
      props.expandFiltersMap[key].expanded = false;
    });

    const openedKeys = reactive([]);

    watch(
      () =>
        Object.entries(props.expandFiltersMap)
          .filter(([_, value]) => value.expanded)
          .map(([key]) => key),
      (newKeys) => {
        newKeys.forEach((key) => {
          if (!openedKeys.includes(key)) {
            openedKeys.push(key);
          }
        });

        if (openedKeys.length > 2) {
          const keyToClose = openedKeys.shift();
          props.expandFiltersMap[
            keyToClose as unknown as keyof typeof props.expandFiltersMap
          ].expanded = false;
        }
      },
      { deep: true }
    );
  }
};

// const generateStars = (input: string): number | null => {
//   const match = input.match(/\d+/);
//   return match ? parseInt(match[0], 10) : null;
// }

onMounted(() => {
  checkIfMobile();
});
</script>

<style scoped lang="scss">
.search-skeleton__filters {
  width: 100%;
  height: 600px;
}
.nav-container {
  position: relative;
  height: 100%;
  max-width: 190px;
  width: 100%;
  &--mobile {
    max-width: 90%;
    .base-navigator {
      .rest-nav {
        position: relative;

        &-wrapper {
          max-width: 100% !important;
        }
      }
    }
  }
  .base-navigator {
    position: relative;
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: flex-start;
    gap: 16px;
    padding: 6px 0px 24px 0px;
    .rest-nav {
      width: 100%;
      // padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        // padding: 0px 10px;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        max-width: 190px;
        .filter-item {
          width: 100%;
          max-height: 19px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          transition: max-height 0.3s ease-in-out;
          will-change: max-height;
          padding: 1px;
          &-open {
            max-height: 400px;
          }
          .expand-filter {
            @include drop-button-styles;
            display: flex;
            width: 100%;
            height: fit-content;
            justify-content: space-between;
            align-items: center;
            &-flexbox {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
            }
          }
        }
      }
    }
    &--controls {
      display: grid;
      align-items: center;
      justify-items: center;
      justify-self: center;
      gap: 8px;
      width: 100%;
      .base-button {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="portfolio-tokens-chart">
    <div class="title">
      <div class="chart-container" @mouseleave="hoveredChatLegendItem = ''">
        <Doughnut :data="chartData" :options="chartOptions" />
      </div>
      <span class="title-data">
        <p class="text-subtitle text-bold">
          {{ totalTokens?.toLocaleString() }}
        </p>
        <p class="text-body-2">Total No. of tokens</p>
      </span>
    </div>
    <div id="doghnut-scroll" class="wrapper">
      <div class="chart-legend">
        <div
          v-for="(label, index) in chartData.labels"
          :id="label?.replace(/ /g, '_')"
          :key="index"
          class="legend-item"
        >
          <span
            class="legend-color"
            :class="{
              'legend-color--hovered':
                label?.replace(/ /g, '_') === hoveredChatLegendItem,
            }"
            :style="{
              backgroundColor: chartData?.datasets?.[0]?.backgroundColor[index],
            }"
          />
          <span class="legend-label text-body-2">{{ label }}</span>
          <span class="legend-value text-body-2 text-bold">{{
            parseFloat(chartData.datasets?.[0]?.data[index]?.toLocaleString()).toFixed(2)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "vue-chartjs";
import { defineProps } from "vue";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

interface IProps {
  chart: {
    percentage: string;
    unitId: string;
    name?: string;
    balance: number;
  }[];
}

const props = defineProps<IProps>();

const generateColorArray = (count: number, hover?: boolean): string[] => {
  const baseColors = [
    { r: 103, g: 66, b: 208 },
    { r: 159, g: 122, b: 234 },
    { r: 196, g: 166, b: 249 },
    { r: 224, g: 204, b: 252 },
  ];

  const result: string[] = [];

  for (let i = 0; i < count; i++) {
    const baseColor = baseColors[i % baseColors.length];

    const variation = {
      r: Math.min(
        255,
        Math.max(0, baseColor.r + Math.floor(Math.random() * 30 - 15))
      ),
      g: Math.min(
        255,
        Math.max(0, baseColor.g + Math.floor(Math.random() * 30 - 15))
      ),
      b: Math.min(
        255,
        Math.max(0, baseColor.b + Math.floor(Math.random() * 30 - 15))
      ),
    };

    result.push(
      `rgba(${variation.r}, ${variation.g}, ${variation.b}, ${hover ? "0.8" : "1"})`
    );
  }

  return result;
};

// Генерируем данные для графика на основе пропсов
const chartData = computed(() => {
  return {
    labels: props.chart?.map((item) => item.name),
    datasets: [
      {
        data: props.chart?.map((item) => item.balance),
        backgroundColor: generateColorArray(props.chart?.length),
        hoverBackgroundColor: generateColorArray(props.chart?.length, true),
        borderColor: "#ECECEC",
        borderWidth: 1,
        cutout: "50%",
      },
    ],
  };
});

const hoveredChatLegendItem = ref("");

const chartOptions = ref({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  onHover: (event: MouseEvent, chartElement: any[]) => {
    if (chartElement.length > 0) {
      const index = chartElement?.[0].index;
      if (
        typeof index === "number" &&
        chartData.value.labels[index] &&
        hoveredChatLegendItem.value !==
          chartData.value.labels[index].replace(/ /g, "_")
      ) {
        const label = chartData.value.labels[index].replace(/ /g, "_");
        hoveredChatLegendItem.value = label;
        handleScrollToHovered(label);
      }
    }
  },
});

const handleScrollToHovered = (val: string) => {
  if (val) {
    const scrollContainer = document.getElementById("doghnut-scroll");
    const element = document.getElementById(val); // Ищем элемент по ID

    if (element && scrollContainer) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();

      const scrollOffset =
        elementRect.top - containerRect.top + scrollContainer.scrollTop;

      scrollContainer.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    }
  }
};

const totalTokens = computed(() => {
  const balances = props.chart.map((item) => item.balance);
  const total = balances.reduce((sum, balance) => {
    return typeof balance === "number"
      ? sum + balance
      : parseFloat((sum + parseFloat(balance)).toFixed(2));
  }, 0);
  return total ?? 0;
});
</script>

<style lang="scss">
.portfolio-tokens-chart {
  padding: 24px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  height: 312px;
  max-width: 314px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  gap: 16px;
  overflow: hidden;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-data {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
    }
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-height: 100%;
    gap: 40px;
    overflow-y: scroll;

    .chart-legend {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      .legend {
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
        }
        &-color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-left: 4px;
          transition: scale 0.3s ease;
          will-change: scale;
          &--hovered {
            scale: 1.7;
          }
        }
        &-label {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80%;
        }
        &-value {
          font-weight: bold;
        }
      }
    }
  }
  .chart-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 88px;
    height: 88px;
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .portfolio-tokens-chart {
    max-width: 345px;
    height: fit-content;
    .wrapper {
      justify-content: flex-start;
      .chart-legend {
        max-width: 100%;
        height: fit-content;
      }
    }
    .chart-container {
    }
  }
}

.wrapper::-webkit-scrollbar {
  width: 12px;
}

.wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: var(--violet-5);
  border-radius: 6px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--violet-main);
}

@supports not selector(::-webkit-scrollbar) {
  .wrapper {
    scrollbar-width: thin;
    scrollbar-color: transparent;
  }
}
</style>

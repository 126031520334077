<template>
  <header v-if="failSafeInterval" class="failsafe-header">
    <h1>Next save in {{ failDsafeTimerDisplay }}s</h1>
  </header>
  <div v-if="!$q.loading.isActive && currentItem" class="float-butts">
    <q-fab
      v-model="floatButt"
      label="Actions"
      vertical-actions-align="left"
      color="purple"
      icon="keyboard_arrow_up"
      direction="up"
    >
      <q-fab-action
        v-if="currentItem.sanitizeName"
        color="primary"
        label="Preview"
        @click="openPreview(currentItem.sanitizeName)"
      />
      <q-fab-action
        color="secondary"
        :label="adminStore.formMode === 'edit' ? 'Update' : 'Add'"
        @click="handleSubmit"
      />
      <q-fab-action
        color="orange"
        label="Start failsafe"
        @click="handleFailSave"
      />
      <q-fab-action
        color="accent"
        label="Get saved item"
        @click="handleGetFailSave"
      />
    </q-fab>
  </div>
  <div class="page-content">
    <div v-if="!$q.loading.isActive && currentItem" class="unit-container">
      <section class="unit__left">
        <div v-if="!$device.isMobile" class="wall-wrapper">
          <base-files-uploader />
        </div>
        <base-page-tabs
          :tabs="props.tabs"
          :current-tab="currentTab"
          :disable="adminStore.formMode === 'edit' ? 'Provider' : ''"
          @change-tab="onTabClick"
        />

        <swiper
          :slides-per-view="1"
          :space-between="30"
          :allow-touch-move="false"
          @swiper="onSwiper"
          @slide-change="onSlideChange"
        >
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-dynamic">
                <div class="unit-bubbles">
                  <slot name="bubbles" :current-item="currentItem" />
                </div>
              </section>
              <div class="current__tab-text">
                <q-btn label="Edit description" :color="'accent'" style="width: 100%;" @click="callEditDescription" />
                
              </div>
              <div class="current__tab-text">
                <editor
                  v-if="currentTranslation"
                  v-model="translatedDescription"
                  api-key="v5n069t1i4u2b85xtio3jrmx4gktki4qys7iaksiafi1fzzq"
                  tag-name="div"
                />
              </div>
              <div class="current__tab-map">
                <q-input
                  v-model="currentItem.location"
                  standout="bg-black text-white"
                  label="Location"
                />
                <q-select
                  v-model="currentItem.countryId"
                  standout
                  :options="countries"
                  label="Country"
                  option-value="code"
                  option-label="name"
                  emit-value
                  map-options
                />
                <q-select
                  v-model="currentItem.stage"
                  standout
                  :options="['complete', 'in_progress']"
                  label="Stage"
                  emit-value
                />
                <q-select
                  v-model="currentItem.subtype"
                  standout
                  :options="unitSubtypes"
                  label="Subtype"
                  emit-value
                />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <slot name="finance" :current-item="currentItem" />
            </div>
          </swiper-slide>

          <swiper-slide v-if="props.isTimeline">
            <div class="current__tab">
              <base-unit-timeline
                v-if="currentItem.timeline"
                is-edit
                :timeline-arr="currentItem.timeline"
                @change-timeline="handleTimelineChanged"
              />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <div class="wrapper">
                  <
                  <q-input
                    v-model="currentItem.contract!.address"
                    standout="bg-black text-white"
                    type="text"
                    label="Address"
                    style="width: 100%"
                  />
                  <q-select
                    v-model="currentItem.contract!.chainId"
                    standout
                    :options="blockchains"
                    label="Token Chain"
                    option-label="name"
                    option-value="id"
                    emit-value
                    map-options
                    style="width: 100%"
                  />
                  <q-select
                    v-model="currentItem.contract!.type"
                    standout
                    :options="['ERC20']"
                    label="Contract type"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="
                      currentItem.contract[
                        currentItem.contract!.type as keyof UnitResponseDTO
                      ]!.name
                    "
                    standout="bg-black text-white"
                    type="text"
                    label="Token Name"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="
                      currentItem.contract[currentItem.contract!.type]!.symbol
                    "
                    standout="bg-black text-white"
                    type="text"
                    label="Symbol"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="
                      currentItem.contract[currentItem.contract!.type]!.decimals
                    "
                    standout="bg-black text-white"
                    type="text"
                    label="Decimals"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.tokenomics!.maxSupply"
                    standout="bg-black text-white"
                    type="number"
                    label="Max Token Supply"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.tokenomics!.totalSupply"
                    standout="bg-black text-white"
                    type="number"
                    label="Total Supply"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.tokenomics!.availableSupply"
                    standout="bg-black text-white"
                    type="number"
                    label="Available Supply Supply"
                    style="width: 100%"
                  />

                  <q-input
                    v-model="currentItem.contract!.icon"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Icon URL"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.contract!.description"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Description"
                    style="width: 100%"
                  />

                  <q-select
                    v-model="currentItem.contract!.jurisdiction"
                    standout
                    :options="countries"
                    label="Token Jurisdiction"
                    option-value="code"
                    option-label="name"
                    emit-value
                    map-options
                    style="width: 100%"
                  />
                </div>
              </section>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <q-select
                  v-model="currentItem.contract!.issuer"
                  filled
                  label="Issuers"
                  :options="allIssuers"
                  option-label="name"
                  option-value="id"
                  use-chips
                  @filter="getIssuers"
                />
              </section>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <div>
                  <q-select
                    v-model="pickedPartner"
                    filled
                    label="Partners"
                    :options="allPartners"
                    emit-value
                    option-label="name"
                    multiple
                    use-chips
                    @filter="getPartners"
                    @add="addPartner"
                    @remove="removePartner"
                  />
                </div>
              </section>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <div class="current__tab-map">
                <q-select
                  v-model="currentItem.ownershipStatus"
                  standout
                  :options="holdOptions"
                  label="Ownership rights"
                  emit-value
                  clearable
                />
                <q-select
                  v-model="currentItem.ownershipText"
                  standout
                  :options="templateOptions"
                  label="Legal Template"
                  emit-value
                  clearable
                />
              </div>
              <div class="current__tab-text">
                <p>
                  {{
                    currentItem.ownershipText
                      ? LegalTemplates[
                          currentItem.ownershipText as keyof typeof LegalTemplates
                        ]
                      : ""
                  }}
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <div class="current__tab-map">
                <q-select
                  v-model="currentItem.provider"
                  standout
                  :options="providersOptions"
                  label="Pick provider"
                  clearable
                  option-label="name"
                  option-value="id"
                  emit-value
                  map-options
                />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <section class="unit__right">
        <slot name="searchCard" :current-item="currentItem" />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { useAdminStore, type CompanyResponseDTO } from "~/store/admin";
import type {
  CreateEstateTimelineDTO,
  UnitProviderDTO,
  PartnerResponseDTO,
  UnitResponseDTO,
} from "~/services/swagger/Api";
import { LegalTemplates } from "~/types/admin";
import { askForFailSafe, getFailSafeItem } from "./common";
import { cloneDeep } from "lodash";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import { unitSubtypes } from "~/utilities/helpers/admin/common";
import { PopUpServices } from "~/services/PopUp/callPopUp";

definePageMeta({
  layout: "admin-edit",
  middleware: "auth-middleware",
});

interface IProps {
  tabs: string[];
  isTimeline?: boolean;
}

const props = defineProps<IProps>();

const route = useRoute();
const templateOptions = Object.keys(LegalTemplates).map((key) => key);

const adminStore = useAdminStore();
const popupStore = PopUpServices();
const currentItem = computed(() => adminStore.currentItem);
const unit = adminStore.getUnitClass();
const holders = adminStore.getHoldersClass();
const allPartners = ref<PartnerResponseDTO[] | undefined>([]);
const allIssuers = ref<CompanyResponseDTO[] | undefined>([]);
const pickedPartner = ref<PartnerResponseDTO[] | undefined>(undefined);
const currentTranslation = ref("");
const translatedDescription = ref("");

const holdOptions = ["Freehold", "Leasehold"];

const countries = computed(() => adminStore.countries);
const blockchains = computed(() => adminStore.blockchains);
const $q = useQuasar();

const floatButt = ref(false);
const providersOptions = ref<UnitProviderDTO[] | null>(null);

const currentTab = ref(0);

const handleTimelineChanged = (point: {
  icon: string;
  activeColor: string;
  isActive: boolean;
  text: string;
  date: string;
  stage: string;
}) => {
  if (!point) return;
  const timeLinePoint: CreateEstateTimelineDTO = {
    stage: point.stage as CreateEstateTimelineDTO["stage"],
    date: point.date || "",
  };

  if (timeLinePoint.date) {
    adminStore.updateTimeline(currentItem.value.id, timeLinePoint);
  } else {
    adminStore.deleteTimeline(currentItem.value.id, timeLinePoint);
  }
};

const handleSubmit = () => {
  checkBlankProps();
  const obj = cloneDeep(currentItem.value);
  if (obj?.contract?.issuer) {
    obj.contract.issuerId = obj.contract.issuer?.id;
    delete obj.contract.issuer;
  }
  if (adminStore.formMode === "add") {
    currentItem.value.type = route.params.id === "estate" ? "Estate" : "Land";
    onPropertySave(obj);
  } else if (adminStore.formMode === "edit") {
    delete obj.type;
    onPropertyUpdate(obj);
  }
};

const onPropertySave = async (item: UpdatedUnitResponseDTO) => {
  try {
    item.providerId = item.provider;
    delete item.provider;
    await unit.addProperty(item);
    alert("Estate successfully added!");
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};

const onPropertyUpdate = async (item: UpdatedUnitResponseDTO) => {
  try {
    item.providerId = item.provider;
    delete item.provider;
    await unit.updateProperty(item, pickedPartner.value);

    alert("Estate successfully updated!");
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};

const swiperInst = ref<typeof Swiper | null>(null);

const addPartner = (details: { index: number; value: PartnerResponseDTO }) => {
  adminStore.currentItem.partners?.push({
    ...details.value,
    id: allPartners.value?.find((item) => item.name === details.value.name)?.id,
  });
};
const removePartner = (details: {
  index: number;
  value: PartnerResponseDTO;
}) => {
  adminStore.$state.currentItem.partners?.splice(details.index, 1);
};

const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};

const onTabClick = (tabName: string | undefined) => {
  if (!tabName) return;
  const tabIndex = props.tabs.indexOf(tabName);
  if (swiperInst.value && tabIndex !== -1) {
    currentTab.value = tabIndex;
    swiperInst.value.slideTo(tabIndex);
  }
};
const onSlideChange = (swiper: { activeIndex: number }) => {
  if (typeof swiper?.activeIndex !== "number") return 0;
  currentTab.value = swiper.activeIndex;
};

const openPreview = (id: string) => {
  if (!id) return;
  window.open(`${window.location.origin}/property/${id}`);
};

const checkBlankProps = () => {
  currentItem.value.partners?.forEach((item) => {
    if (!item.url || !item.url.trim()) {
      delete item.url;
    }
  });
};

const failSafeInterval = ref<NodeJS.Timeout>();
const failDsafeTimerDisplay = ref(10);
const handleFailSave = () => {
  askForFailSafe(currentItem.value, failDsafeTimerDisplay, failSafeInterval);
};

const handleGetFailSave = async () => {
  const res = getFailSafeItem(failSafeInterval);
  if (res) {
    adminStore.$patch({
      currentItem: { ...res } as unknown as UpdatedUnitResponseDTO,
    });
  }
};

const checkAndOpenPage = async () => {
  if (route.params.slug === "edit") {
    await unit.onOpenEditPage(route.params.id as string);
  } else {
    adminStore.createNewProperty(route.params.id as string);
  }
  adminStore.getCountries();
  adminStore.getBlockchains();
};

const getPartners = async (_: any, update: any, __: any) => {
  if (allPartners.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllPartners();
  if (!res) return;
  update(() => {
    allPartners.value = res;
  });
};
const getIssuers = async (_: any, update: any, __: any) => {
  if (allIssuers.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllCompanies();
  if (!res) return;
  update(() => {
    allIssuers.value = res;
  });
};

const callEditDescription = () => {
  popupStore.callPopUp({
    componentName: "item-editor-translations",
    persistent: true,
  });
};

onMounted(async () => {
  $q.loading.show();
  await checkAndOpenPage();
  holders.fetchAllPartners(true);
  providersOptions.value = await adminStore.getProviders();
  pickedPartner.value = currentItem.value.partners;
  $q.loading.hide();
});
</script>

<style scoped lang="scss">
@import url(~/assets/styles/admin-unit-page/index.scss);
</style>

<template>
  <div class="external text-body-2 text-sans text-regular">
    {{ $t('search_card.req_open') }}
    <div class="external__icon">
      <base-icon name="base/External" size="9px" />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style>
.external {
  display: flex;
  text-decoration: underline;
  align-items: center;
  cursor: pointer;
}
.external__icon {
  padding-left: 8px;
}
</style>

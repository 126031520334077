<template>
  <admin-search-card :inputs-map-blocked="inputsMapBlocked">
    <template #bubbles="{ currentItem }">
      <span class="stats__bubbles-small">
        <span class="flexbox">
          <p class="font--b2 mobile-font--b4 text-black-monochrome">IRR</p>

          <q-input
            v-model="currentItem.irr"
            class="q-inputs"
            standout="bg-white"
            type="number"
          />
        </span>
      </span>
      <span class="stats__bubbles-small">
        <span class="flexbox">
          <p class="font--b2 mobile-font--b4 text-black-monochrome">APR</p>
          <q-input
            v-model="currentItem.apr"
            class="q-inputs"
            standout="bg-indigo-14  text-white"
            type="number"
          />
        </span>
      </span>
    </template>
  </admin-search-card>
</template>

<script setup lang="ts">
const inputsMapBlocked = reactive<Record<string, boolean>>({
  name: false,
  location: false,
  irr: false,
  apr: false,
  currency: false,
  price: false,
  coords: false,
  tags: false,
  priceUSD: false,
  bathroom: false,
  bedroom: false,
  description: false,
  stage: false,
  finance: false,
  partners: false,
});
</script>

<style scoped></style>

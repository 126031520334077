<template>
  <footer class="base-footer">
    <div class="footer-wrapper">
      <div class="footer-wrapper--table">
        <nav class="footer-links">
          <span class="footer-links-item">
            <h5 class="text-subtitle text-bold text-white-monochrome">
              {{ $t("footer.f_comm") }}
            </h5>
            <div class="flexbox community">
              <a
                href="https://www.linkedin.com/company/rwa-estate/"
                target="_blank"
                ><base-icon
                  name="base/LinkedinLogo"
                  size="32px"
                  color="var(--gray-monochrome)"
              /></a>
              <a href="https://t.me/rwasupport" target="_blank">
                <base-icon
                  name="base/telegram"
                  size="32px"
                  color="var(--gray-monochrome)"
                />
              </a>
              <a
                href="mailto:hi@rwa-estate.com"
                class="text-body-1 mobile-font--b5 email-desktop"
                >hi@rwa-estate.com</a
              >
              <div class="email-mobile">
                <a
                href="mailto:hi@rwa-estate.com"
                class="text-body-1 mobile-font--b5"
                >hi@rwa-estate.com</a
              >
              </div>
              <base-button
                :text="$t('header.h_add')"
                size="xss"
                class="btn-add-desktop"
                variant="light_bordered"
                icon-name="base/Add"
                icon-size="16px"
                @click="addUnit"
              />
            </div>
          </span>
          <span class="footer-links-item">
            <h5 class="text-subtitle text-bold text-white-monochrome">
              {{ $t("footer.f_about") }}
            </h5>
            <a
              class="text-body-1 mobile-font--b2"
              href="/faq"
              target="_blank"
              style="padding-top: 4px"
              >FAQs</a
            >
          </span>
          <span class="footer-links-item">
            <h5 class="text-subtitle text-bold text-white-monochrome">
              {{ $t("footer.f_legal.title") }}
            </h5>
            <span
              class="text-body-1 mobile-font--b2"
              style="padding-top: 4px"
              @click="handleLink('privacy-policy')"
              >{{ $t("footer.f_legal.privacy") }}
            </span>
            <span
              class="text-body-1 mobile-font--b2"
              style="padding-top: 4px"
              @click="handleLink('terms')"
              >{{ $t("footer.f_legal.terms") }}
            </span>
            <span
              class="text-body-1 mobile-font--b2"
              style="padding-top: 4px"
              @click="handleLink('cookies-policy')"
              >{{ $t("footer.f_legal.cookies") }}
            </span>
          </span>
        </nav>
        <section class="footer-subscription">
          <h5
            class="text-subtitle text-bold text-white-monochrome mobile-font--b2"
            style="margin-bottom: 4px"
          >
            {{ $t("footer.f_updated") }}
          </h5>
          <p class="text-body-1 text-gray-monochrome mobile-text-body-1">
            {{ $t("footer.f_launch") }}
          </p>
          <div>
            <base-subscribe
              :text="$t('footer.f_submit')"
              :anim-text="$t('footer.f_submit-done')"
            />
          </div>
        </section>
        <!-- <div class="email-mobile">
          <a href="mailto:hi@rwa-estate.com" class="text-body-1 mobile-font--b5"
            >hi@rwa-estate.com</a
          >
        </div>
        <base-button
          :text="$t('header.h_add')"
          size="lg"
          class="btn-add-mobile"
          variant="light_bordered"
          icon-name="base/Add"
          icon-size="16px"
          @click="addUnit"
        /> -->
      </div>

      <div class="footer-wrapper--logo">
        <img src="~/assets/icons/Logo_light.svg" alt="" >
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { locale } = useI18n()

const handleLink = (link: string) => {
  const route = localePath(`/${link}`)
  window.open(route, "_blank")
}

const addUnit = () => {
  window.open("https://t.me/rwasupport", "_blank")
}
</script>

<style scoped lang="scss">
.base-footer {
  // height: 460px;
  width: 100%;
  max-width: 100dvw;
  overflow: hidden;
  background-color: var(--black-monochrome);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  .footer-wrapper {
    position: relative;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 40px 0px 0px 0px;
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    max-width: 990px;
    margin: auto;
    row-gap: 31px;

    // @media (max-width: 2599px) {
    //   max-width: 1440px;
    //   margin: auto;
    // }

    &--table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      row-gap: 32px;
      column-gap: 50px;
      .footer {
        &-links {
          display: grid;
          grid-template-columns: minmax(156px, 1fr) minmax(100px, 1fr) minmax(
              247px,
              1fr
            );
          height: 100%;
          width: -moz-fit-content;
          width: fit-content;
          gap: 40px;
          flex-wrap: wrap;

          &-item {
            display: grid;
            grid-template-columns: 1fr;
            align-self: start;
            row-gap: 15px;
            width: fit-content;
            .flexbox {
              // max-width: 150px;
              display: flex;
              flex-wrap: wrap;
              gap: 24px;
            }
            .community {
              gap: 17px;
            }
            a,
            span {
              text-decoration: none;
              color: var(--gray-monochrome);
              cursor: pointer;
            }
          }
        }
        &-subscription {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-items: flex-start;
          // justify-self: flex-end;
          align-self: flex-start;
          max-width: 362px;
          gap: 16px;
          p {
            margin-bottom: 7px;
          }
        }
        .email-mobile{
          display: none;
        }
      }
    }
    &--info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-column: span 3;
      align-self: flex-end;
      a {
        color: var(--gray-monochrome);
        text-decoration: none;
      }
    }
    &--logo {
      position: relative;
      align-self: flex-end;
      width: 100%;
      height: 117px;
      bottom: -30px;
      img {
        position: absolute;
        left: 0;
        width: 100%;
        max-height: 160px;
      }
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
  .btn-add-mobile,
  .email-mobile {
    display: none;
  }
}

@media (max-width: 65rem) {
  .base-footer {
    height: auto;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .footer-wrapper {
      max-width: 430px;
      padding: 9px 0 40px;
      gap: 32px;

      &--table {
        grid-template-columns: 1fr;
        padding: 0 24px;

        .footer {
          &-links {
            grid-template-columns: 1fr;
            width: fit-content;
            margin-top: 29px;
            gap: 38px;
            &-item {
              row-gap: 17px;

              .flexbox {
                max-width: unset;
              }
            }
          }

          &-subscription {
            border-radius: 16px;
            border: 1px solid var(--white-transparent);
            max-width: inherit;
            width: 100%;
            padding: 16px;
            gap: 2px;
            justify-self: unset;
            margin-top: 15px;

            p {
              padding-bottom: 11px;
            }
          }
        }
      }

      &--logo {
        height: auto;
        grid-row-end: 1;
        padding: 0 24px 30px;
        margin: 0;
        border-bottom: 1px solid var(--gray-monochrome-20);

        img {
          position: relative;
          bottom: unset;
          left: unset;
          width: 203px;
          max-height: unset;
        }
      }
    }
    .email-desktop{
      display: block;
    }
    .btn-add-desktop{
      width: 100%;
      height: 40px;
    }

    .email-desktop {
      display: none;
    }
    .email-mobile {
      display: block;
      width: 100%;
      a {
        color: var(--gray-monochrome);
        text-decoration: none;
      }
    }
    .btn-add-mobile {
      display: flex;
      width: 100%;
      height: 48px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  @include mobile-fonts;
}
</style>

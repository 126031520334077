<template>
  <section class="base-search" @touchstart="handleClick" @click="handleClick">
    <div class="base-search-inner" :class="{'base-search-inner--with-icon':!props.removeIcon}">
      <q-input
        v-model="searchInput"
        placeholder="Search"
        style="width: 100%"
        borderless
        :disable="props.disabled"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { Notification } from "~/services/notifications/toast";
import eventBus from "~/utilities/composables/eventBus";

interface IEmits {
  (e: "typing", value: string): void;
  (e: "enter"): void;
  (e: "blur"): void;
  (e: "click"): void;
  (e: "blank"): void;
}
interface IProps {
  disabled?: boolean;
  search?: string;
  removeIcon?: boolean;
}

const emit = defineEmits<IEmits>();
const props = defineProps<IProps>();
const { t } = useI18n();

const handleClick = (event: Event) => {
  if (props.disabled) {
    event.preventDefault();
    const comingSoon = new Notification({
      type: "system",
      message: t("errors.coming_soon"),
    });
    comingSoon.createNewToast();
  }
};

const searchInput = computed({
  get: () => props.search || "",
  set: (value: string) => emit("typing", value),
});

onMounted(() => {
  eventBus.on("clear-directory-search", () => {
    searchInput.value = "";
  });
});
</script>

<style scoped lang="scss">
.base-search {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--black-monochrome-60);

  &-inner {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 7px 40px;
    :deep(input) {
      font-size: 14px;
    }
    :deep(input::placeholder) {
      opacity: 1;
      font-size: 14px;
      color: var(--black-monochrome-60);
    }
    &--with-icon {
      background-image: url("~/assets/icons/base/SearchDark.svg");
      background-position: 10% 50%;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 32rem) {
  .base-search {
    min-width: 345px;
    border: 1px solid var(--black-monochrome-40);
    box-shadow: unset;
  }
}
</style>

<template>
  <section v-if="props.unitPageData?.finance" class="current__tab-finance">
    <div
      v-if="
        checkFinanceField(
          props.unitPageData?.finance?.projectTotalIncome?.value
        )
      "
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_projected_total_income") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectedTotalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="text-body-1 text-bold text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.value
            )
          }}%
        </p>
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.projectTotalIncome?.subfields
              ?.projectedRentalIncome
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_projected_rental_income") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectedRentalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="text-body-1 text-bold text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.subfields
                ?.projectedRentalIncome
            )
          }}%
        </p>
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.projectTotalIncome?.subfields
              ?.projectionAppreciation
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_projection_appreciation") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectionAppreciation')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="text-body-1 text-bold text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.subfields
                ?.projectionAppreciation
            )
          }}%
        </p>
      </div>
    </div>
    <div
      v-if="
        checkFinanceField(
          props.unitPageData?.finance?.totalInvestmentValue?.value
        )
      "
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_total") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.TotalInvestmentValue')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.totalInvestmentValue?.value"
          :finance-u-s-d="unitPageData?.financeUSD?.totalInvestmentValue?.value"
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.underlyingAssetPrice
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_underlying") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.UnderlyingAssetPrice')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.underlyingAssetPrice
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.underlyingAssetPrice
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.legalFees
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_legal_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.LegalFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields?.legalFees
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields?.legalFees
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.platformFees
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_platform_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.PlatformFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields?.platformFees
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.platformFees
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.auditFees
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_audit_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AuditFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields?.auditFees
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields?.auditFees
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.registrationTax
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_reg_tax") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.RegistrationTax')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.registrationTax
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.registrationTax
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.daoRegistrationFees
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_daoRegistrationFees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.daoRegistrationFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.daoRegistrationFees
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.daoRegistrationFees
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.initialMaintenanceReserve
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_initialMaintenanceReserve") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.initialMaintenanceReserve')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.initialMaintenanceReserve
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.initialMaintenanceReserve
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.operatingReserve
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_operatingReserve") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.operatingReserve')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.totalInvestmentValue?.subfields
              ?.operatingReserve
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.totalInvestmentValue?.subfields
              ?.operatingReserve
          "
          sub
        />
      </div>
    </div>
    <div
      v-if="checkFinanceField(props.unitPageData?.finance.annualCashFlow)"
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_an_cash_flow") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualCashFlow')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.annualCashFlow"
          :finance-u-s-d="unitPageData?.financeUSD?.annualCashFlow"
        />
      </div>
    </div>
    <div
      v-if="checkFinanceField(props.unitPageData?.finance.monthCashFlow)"
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_mo_cash_flow") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MonthlyCashFlow')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.monthCashFlow"
          :finance-u-s-d="unitPageData?.financeUSD?.monthCashFlow"
        />
      </div>
    </div>
    <div
      v-if="checkFinanceField(props.unitPageData?.finance.annualGrossIncome)"
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_an_gross_in") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualGrossIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.annualGrossIncome"
          :finance-u-s-d="unitPageData?.financeUSD?.annualGrossIncome"
        />
      </div>
    </div>

    <div
      v-if="
        checkFinanceField(props.unitPageData?.finance?.maintainingCosts?.value)
      "
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_maintain_c") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MaintainingCosts')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.maintainingCosts?.value"
          :finance-u-s-d="unitPageData?.financeUSD?.maintainingCosts?.value"
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.maintainingCosts?.subfields
              ?.propertyManagement
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_prop_man") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.PropertyManagement')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.maintainingCosts?.subfields
              ?.propertyManagement
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.maintainingCosts?.subfields
              ?.propertyManagement
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.maintainingCosts?.subfields
              ?.maintenanceReserve
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_man_res") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MaintenanceReserve')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.maintainingCosts?.subfields
              ?.maintenanceReserve
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.maintainingCosts?.subfields
              ?.maintenanceReserve
          "
          sub
        />
      </div>
      <div
        v-if="
          checkFinanceField(
            props.unitPageData?.finance?.maintainingCosts?.subfields?.utilities
          )
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_utils") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.Utilities')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.maintainingCosts?.subfields?.utilities
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.maintainingCosts?.subfields?.utilities
          "
          sub
        />
      </div>

      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.propertyUpgrades
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_PropertyUpgrades") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.propertyUpgrades')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.maintainingCosts?.subfields?.propertyUpgrades
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.maintainingCosts?.subfields
              ?.propertyUpgrades
          "
          sub
        />
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.renovationCosts
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome-60">
            {{ $t("unit_page.un_renovationCosts") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.renovationCosts')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="
            unitPageData?.finance?.maintainingCosts?.subfields?.renovationCosts
          "
          :finance-u-s-d="
            unitPageData?.financeUSD?.maintainingCosts?.subfields
              ?.renovationCosts
          "
          sub
        />
      </div>
    </div>
    <div v-if="props.unitPageData?.finance?.otherFees" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="text-body-1 text-regular text-black-monochrome">
            {{ $t("unit_page.un_other_fee") }}
          </p>
        </span>
        <unit-page-finance-block-components-investment-block
          :currency="props.unitPageData.currency"
          :finance="unitPageData?.finance?.otherFees"
          :finance-u-s-d="unitPageData?.financeUSD?.otherFees"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UnitResponseDTO } from "~/services/swagger/Api";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import {
  formatPercentage,
} from "~/utilities/helpers/format-data/number";
import type { IEstateFinance } from "~/types/unit-item-finance";

interface UpdatedUnitResponseDTO extends Omit<UnitResponseDTO, "finance"> {
  finance: IEstateFinance;
}

interface IProps {
  unitPageData: UpdatedUnitResponseDTO;
}

const props = defineProps<IProps>();

const checkFinanceField = (field?: string | number) => {
  if (typeof field === "undefined") return false;
  if (isNaN(parseFloat(field.toString()))) return false;
  if (parseFloat(field.toString()) === 0) return false;
  return true;
};

const isLocalCurrency = computed(
  () => currentCurrency.value.code !== BASE_CURRENCY.value.code
);

const currentCurrency = computed(() =>
  getCurrentCurrency(props.unitPageData?.currency)
);
</script>

<style scoped lang="scss">
.current__tab-finance {
  display: grid;

  .wrapper {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: 16px;
    border-bottom: 1px solid var(--gray-monochrome-40);
    padding: 16px 0 14px 0;
    &:last-child {
      border: none;
    }
    .flexbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      .icon-flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
      }
    }
  }

  .list-flexbox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    .wrapper-flex {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .text-right {
    text-align: right;
  }
}
</style>

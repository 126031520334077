import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { gtagReachGoal } from "~/services/SEO/goals/createCustomGoal";
import type { UnitFileDTO } from "~/services/swagger/Api";
import { Notification } from "~/services/notifications/toast";

export const redirectByProviderName = (item: {
  providerName: string | undefined;
  utmLink: string | undefined;
  providerLink: string | undefined;
  externalUrl: string | undefined;
  source?: keyof typeof gtagReachGoal;
  images: UnitFileDTO[] | undefined;
  logoFile: string | undefined;
}) => {
  const { $i18n } = useNuxtApp()
  const t = $i18n.t

  const routes: Record<string, string | undefined> = {
    binaryx: item.utmLink ?? item.externalUrl ?? item.providerLink,
    sabaiecoverse: item.utmLink ?? item.externalUrl ?? item.providerLink,
    investbay: item.utmLink ?? item.externalUrl ?? item.providerLink,
    estateprotocol: item.utmLink ?? item.externalUrl ?? item.providerLink,
    goro: item.utmLink ?? item.externalUrl ?? item.providerLink,
    landshare: item.utmLink ?? item.externalUrl ?? item.providerLink,
    tauvlo: item.utmLink ?? item.externalUrl ?? item.providerLink,
    // digishares: '',
    // polibit: '',
  };

  const normalizedProviderName = item.providerName
    ?.toLowerCase()
    ?.replace(/\s+/g, "");
  if (!normalizedProviderName) {
    const errorNotify = new Notification({
      type: "system",
      message: t("errors.emptyProvider"),
    });
    errorNotify.createNewToast();
    return
  };
  const redirectUrl = routes[normalizedProviderName];

  const callPopUp = PopUpServices();

  callPopUp.callPopUp({
    componentName: "provider-provider-pop-up",
    persistent: false,
    data: {
      url: redirectUrl,
      source: item.source,
      images: item.images,
      logoFile: item.logoFile,
    },
  });
};

<template>
  <section class="legal">
  
    <div class="legal__title">
      <h1 class="text-head-1 text-bold text-sora" v-html="$t('legal.cookies.page_title')"/>
      <h2 class="text-body-2 text-regular">
        {{
          $t("legal.cookies.page_update_date", {
            date: new Intl.DateTimeFormat("en-US").format(new Date())
          })
        }}
      </h2>
    </div>
    <section class="legal__info">
      <legal-sidebar
        v-if="!$device.isMobile"
        ref="sideBarRef"
        :titles="headers"
        @click-title="handleClickTitle"
      />
      <base-separator
        v-if="!$device.isMobile"
        vertical
        :style="{ height: sideBarNode?.sideBarRef?.clientHeight + 'px' }"
      />
      <div class="text">
        <span id="0" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.0')"/>
          <p
            class="text-body-1"
            v-html="$t('legal.cookies.paragraphs.0.text')"
          />
          <p
            class="text-body-1"
            v-html="$t('legal.cookies.paragraphs.1.text')"
          />
          <p
            class="text-body-1"
            v-html="$t('legal.cookies.paragraphs.2.text')"
          />
        </span>
        <span id="1" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.1')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.3.text')"
            />
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.4.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1"  v-html="$t('legal.cookies.ul.0')"/>
            <br >
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.5.text')"
            />
          </span>
        </span>
        <span id="2" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.2')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.6.text')"
            />
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.0')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.7.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.cookies.ul.1')"/>
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.1')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.8.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.cookies.ul.2')"/>
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.2')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.9.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.cookies.ul.3')"/>
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.3')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.10.text')"
            />
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.4')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.11.text')"
            />
          </span>
        </span>
        <span id="3" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.3')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.12.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.cookies.ul.4')"/>
          </span>
        </span>
        <span id="4" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.4')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.13.text')"
            />
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.5')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.14.text')"
            />
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.6')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.15.text')"
            />
            <br >
            <p class="text-body-1 text-violet-main"  v-html="$t('legal.cookies.sub_headers.7')"/>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.16.text')"
            />
          </span>
        </span>
        <span id="5" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.5')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.17.text')"
            />
            <ul class="ul-style ul-style--dots text-body-1" v-html="$t('legal.cookies.ul.5')"/>
            <br ><br >
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.18.text')"
            />
          </span>
        </span>
        <span id="6" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.6')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.19.text')"
            />
          </span>
        </span>
        <span id="7" class="text__paragraph">
          <h5 class="text-head-2 text-bold text-sora" v-html="$t('legal.cookies.headers.7')"/>
          <span>
            <p
              class="text-body-1"
              v-html="$t('legal.cookies.paragraphs.20.text', {email:'hi@rwa-estate.com '})"
            />
          </span>
        </span>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
const { tm } = useI18n()

const sideBarNode = useTemplateRef("sideBarRef")

const handleClickTitle = (val: string) => {
  if (val) {
    const element = document.getElementById(val)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

const headers = computed(() => {
  const headersList = tm("legal.cookies.nav") as unknown as {
    title: string
  }[]
  return headersList ?? []
})
</script>

<style scoped lang="scss"></style>

<template>
  <section v-if="props.unitPageData?.finance" class="current__tab-finance">
    <p class="font--b1-2" style="padding-bottom: 16px">Financials</p>
    <div v-if="props.unitPageData?.finance?.value" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_total") }}
          </p>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{ formatPrice(props.unitPageData?.finance?.value) }}$
        </p>
      </div>
    </div>
    <base-separator
      v-if="props.unitPageData?.finance.value"
      style="margin-top: 16px; margin-bottom: 16px"
    />
    <div v-if="props.unitPageData?.finance?.annualTaxAmount" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_annual_tax_amount") }}
          </p>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{ formatPrice(props.unitPageData?.finance?.annualTaxAmount) }}$
        </p>
      </div>
    </div>
    <base-separator
      v-if="props.unitPageData?.finance.annualTaxAmount"
      style="margin-top: 16px; margin-bottom: 16px"
    />
    <div v-if="props.unitPageData?.finance?.taxAssessedValue" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_tax_assessed_value") }}
          </p>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{ formatPrice(props.unitPageData?.finance?.taxAssessedValue) }}$
        </p>
      </div>
    </div>
    <base-separator
      v-if="props.unitPageData?.finance.taxAssessedValue"
      style="margin-top: 16px; margin-bottom: 16px"
    />
  </section>
</template>

<script setup lang="ts">
import type { EstateFinanceDTO, LandFinanceDTO, UnitResponseDTO } from "~/services/swagger/Api";
import { formatPrice } from "~/utilities/helpers/format-data/number";

interface UpdatedUnitResponseDTO extends Omit<UnitResponseDTO, "finance"> {
  finance: LandFinanceDTO & EstateFinanceDTO;
}

interface IProps {
  unitPageData: UpdatedUnitResponseDTO;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.current__tab-finance {
  display: grid;
  .wrapper {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: 16px;
    .flexbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      .icon-flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
      }
    }
  }
}
</style>

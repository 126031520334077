import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@9.14.0_jiti@2.4._68d01467d1b60bf52df8e9490eb38c87/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth-middleware": () => import("/app/main-site/middleware/admin-auth-middleware.ts"),
  "auth-middleware": () => import("/app/main-site/middleware/auth-middleware.ts"),
  "unit-page-middleware": () => import("/app/main-site/middleware/unit-page-middleware.ts")
}
<template>
  <section class="search-no-objects">
    <img :src="props.img" alt="no-data" >
    <slot />
  </section>
</template>

<script setup lang="ts">
import baseImg from "~/assets/img/no-data.svg";
const props = withDefaults(defineProps<{ img?: string }>(), {
  img: baseImg,
});
</script>

<style scoped lang="scss">
.search-no-objects {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
}

@media (max-width: 65rem) {
  @include mobile-fonts;
}
</style>

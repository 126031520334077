export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    nuxtApp.hook("page:finish", (page) => {
      const config = useRuntimeConfig();
      const isProduction = config.public.NUXT_SITE_ENV === "production";
      if (isProduction) {
        window.dataLayer = window.dataLayer || [];
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WPMLJH26";
        document.head.appendChild(script);
      }
    });
  }
});

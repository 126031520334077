<template>
  <section class="portfolio__card">
    <div class="property">
      <q-img
        class="property__img"
        width="129px"
        height="72px"
        :src="unitImg || noImage"
        format="webp"
        lazy
      />
      <div class="property__info">
        <div class="info-title">
          <div class="info-title__badges">
            <span
              v-if="props.unit?.provider?.logoFile?.url"
              class="partner-logo"
            >
              <q-img
                width="60px"
                height="14px"
                :src="props.unit?.provider?.logoFile?.url"
                fit="cover"
                lazy
              />
            </span>
            <span
              class="stage-badge"
              :style="{ borderColor: stageBadge.color }"
            >
              <base-icon :name="`base/${stageBadge.icon}`" size="8px" filled />
              <p class="text-body-3" :style="{ color: stageBadge.color }">
                {{ $t(stageBadge.title) }}
              </p>
            </span>
            <div class="metrics">
              <span class="metrics__item">
                <p class="text-body-3">APR</p>
                <p class="text-body-3">
                  {{ formatPercentage(props.unit?.apr) }}%
                </p>
              </span>
              <span class="metrics__item">
                <p class="text-body-3">IRR</p>
                <p class="text-body-3">
                  {{ formatPercentage(props.unit?.irr) }}%
                </p>
              </span>
            </div>
          </div>
          <div class="info-title__title">
            <p class="text-subtitle text-bold">{{ props.unit?.name }}</p>
            <span class="country-flexbox">
              <base-icon
                size="16px"
                filled
                :name="`flags/${countryNames[getCountryFlag]?.split(' ').join('_').toLowerCase()}`"
              />
              <p class="font--b6 text-black-monochrome-60">
                {{ props.unit.location || "Not provided" }}
              </p>
            </span>
          </div>
        </div>
        <div class="info-amount">
          <span class="info-amount__avail">
            <p class="font--b1-2">
              {{
                BASE_CURRENCY.symbol +
                formatPrice(
                  (props.userTokens ?? 0) *
                    parseFloat(props.unit?.tokenomics?.priceUSD ?? "0"),
                  false,
                  true
                )
              }}
            </p>
            <p class="font--b5-5 text-positive-secondary">
              +{{ formatPercentage(12) }}%
            </p>
          </span>
          <span class="info-amount__token" @click="navigateToScan">
            <base-icon
              size="20px"
              color="var(--black-monochrome-60)"
              name="chain/all"
            />
            <p class="font--b4-semi-bold text-black-monochrome-60">
              {{ Math.floor((props.userTokens ?? 0) * 100) / 100 }} tokens
            </p>
            <base-icon
              size="20px"
              color="var(--black-monochrome-60)"
              name="base/DownRight"
              rot="270deg"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="investment">
      <div class="investment__metrics">
        <span class="metric-item">
          <p class="text-body-1 text-bold">
            {{
              BASE_CURRENCY.symbol + formatPrice(tokenGrowthEarning, true, true)
            }}
          </p>
          <p class="text-body-2 text-black-monochrome-60">
            Token growth earning
          </p>
        </span>
        <!-- <span class="metric-item">
          <p class="text-body-1 text-bold">
            {{ BASE_CURRENCY.symbol + formatPrice(240000, true, true) }}
          </p>
          <p class="text-body-2 text-black-monochrome-60">
            Rental Income Earning
          </p>
        </span> -->
      </div>
      <nav class="investment__controls">
        <base-button
          text="Details"
          :variant="'dark_ghost'"
          class="inv-button inv-button--gray"
          bold-text
          @click="goToUnit"
        />
        <base-button
          text="Buy more tokens"
          :variant="'dark_ghost'"
          class="inv-button inv-button--violet"
          bold-text
          @click="goToProvider"
        />
      </nav>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import {
  formatPercentage,
  formatPrice,
} from "~/utilities/helpers/format-data/number";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import noImage from "~/assets/img/no-image.svg";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import { countryNames } from "~/utilities/constants/countryCodes";
import type { PortfolioDTO } from "~/services/swagger/Api";
interface IProps {
  portfolio: PortfolioDTO;
  unit: UpdatedUnitResponseDTO;
  userTokens: number | undefined;
}

const props = defineProps<IProps>();

const tokenGrowthEarning = computed(
  () =>
    props.portfolio.tokenGrowthEarning.find(
      (item) => item.unitId === props.unit.id
    )?.balance ?? 0
);

const { t } = useI18n();
const localePath = useLocalePath();
const stageBadge = computed(() => {
  const stage = getStage(props.unit?.stage);
  return {
    title: stage.title,
    color: stage.color,
    icon: stage.icon,
  };
});

const getCountryFlag = computed(
  () => props.unit.countryId ?? props.unit.country?.code
);

const getStage = (stage: typeof props.unit.stage) => {
  switch (stage) {
    case "complete":
      return {
        title: t("search_filters.fr_stages.complete"),
        icon: "StageDotGreen",
        color: "var(--green-600)",
      };
    case "in_progress":
      return {
        title: t("search_filters.fr_stages.progress"),
        color: "var(--orange-sec)",
        icon: "StageDotOrange",
      };
    default:
      return {
        title: t("search_filters.fr_stages.progress"),
        color: "var(--orange-sec)",
        icon: "StageDotOrange",
      };
  }
};

const goToUnit = () => {
  if (!props.unit?.sanitizeName) return;
  navigateTo(localePath(`/property/${props.unit.sanitizeName}`));
};

const goToProvider = () => {
  redirectByProviderName({
    providerName: props.unit?.provider?.name,
    providerLink: props.unit?.provider?.url,
    utmLink: props.unit?.referralLink?.link,
    externalUrl: props.unit?.externalUrl,
    logoFile: props.unit?.provider?.logoFile?.url,
    images: props.unit.files,
  });
};

const unitImg = computed(
  () =>
    props.unit?.files?.find(
      (item) => item.type === "covers" || item.type === "image"
    )?.file?.url
);

const navigateToScan = () => {
  if (!props.unit.contract?.url) return;
  const link = `${props.unit.contract?.url}#balances`;
  window.open(link, "_blank");
};
</script>

<style scoped lang="scss">
.portfolio__card {
  display: flex;
  height: 172px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: 0px 0px 6.8px 0px rgba(0, 0, 0, 0.08);
  .property {
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    width: 100%;
    &__img {
      border-radius: 8px;
    }
    &__info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .info-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        &__badges {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          .partner-logo {
            display: flex;
            height: 24px;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 40px;
            border: 1px solid var(--gray-monochrome-40);
            background: transparent;
            cursor: default;
          }
          .stage-badge {
            display: flex;
            height: 24px;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 18px;
            border: 1px solid transparent;
            background: var(--white-contrast);
            cursor: default;
          }
          .metrics {
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            &__item {
              display: flex;
              height: 24px;
              padding: 4px 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 8px;
              border: 1px solid var(--gray-monochrome-40);
              background: var(--white-monochrome);
              cursor: default;
            }
          }
        }
        &__title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          .country-flexbox {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: default;
          }
        }
      }
      .info-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        cursor: default;

        &__avail {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }
        &__token {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          cursor: pointer;
        }
      }
    }
  }
  .investment {
    display: flex;
    padding: 8px 16px 8px 8px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 0.7px solid var(--gray-monochrome-40);
    background: var(--white-monochrome);
    width: 100%;
    &__metrics {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 60px;
      .metric-item {
        display: grid;
        align-items: center;
        justify-items: flex-start;
        cursor: default;

        &__flex {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }
      }
    }
    &__controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      width: fit-content;
      .inv-button {
        &--gray {
          border-color: var(--gray-monochrome-40);
        }
        &--violet {
          border-color: var(--violet-main);
        }
      }
    }
  }
}

@media (max-width: 65rem) {
  @include mobile-fonts;
  .portfolio__card {
    padding: 10px;
    height: fit-content;
    .investment__metrics {
      gap: 0px;
      .metric-item {
        width: fit-content;
      }
    }
    .property {
      &__info {
      }
    }
  }
}
</style>

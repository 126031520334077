<template>
  <button
    :class="[
      'review-status',
      `review-status--${props.size}`,
      `review-status--${props.size}--${props.type.toLowerCase()}`,
      { 'review-status--active': props.isActive },
    ]"
  >
    <span style="width: 8px; height: 8px">
      <Suspense>
        <base-icon
          :name="`base/Dot${props.size === 'small' ? 'Small' : 'Big'}`"
          filled
          size="8px"
          :color="buttonType?.circle"
        />
      </Suspense>
    </span>
    <p
      class="text-body-2 text-bold"
      :class="['review-status__text', `review-status__text--${props.size}`]"
    >
      {{ buttonType?.text }}
    </p>
  </button>
</template>

<script setup lang="ts">
import type { ReviewResponseDTO } from "~/services/swagger/Api";

interface IProps {
  size: "big" | "small";
  type: ReviewResponseDTO["status"] | "all";
  isActive?: boolean;
}

const props = defineProps<IProps>();

const { t } = useI18n();

const buttonType = computed(() => {
  switch (props.type) {
    case "all":
      return { text: t('profile.reviews_all'), circle: "var(--black-monochrome-60)" };
    case "Moderating":
      return { text: t('profile.reviews_moderating'), circle: "var(--blue-second)" };
    case "Published":
      return { text: t('profile.reviews_published'), circle: "var(--positive-secondary)" };
    case "Rejected":
      return { text: t('profile.reviews_rejected'), circle: "var(--negative-sec)" };
    case "Deleted":
      return { text: t('profile.reviews_deleted'), circle: "var(--orange-sec)" };
  }
});
</script>

<style scoped lang="scss">
.review-status {
  @include drop-button-styles;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: transparent;
  box-shadow: var(--shadow-light);
  transition: background-color 0.3s ease;
  will-change: background-color;
  &__text {
    text-align: center;
    white-space: nowrap;
    &--small {
      font-size: 12px;
      font-weight: 400;
    }
  }
  &--big {
    padding: 8px 12px;
    height: 36px;
    &--rejected,
    &--moderating,
    &--published,
    &--deleted {
      border-color: var(--gray-monochrome-40);
    }
    &--all {
      border-color: var(--black-monochrome-60);
    }
    &--rejected {
      color: var(--negative-sec);
    }
    &--moderating {
      color: var(--blue-second);
    }
    &--published {
      color: var(--positive-secondary);
    }
    &--deleted {
      color: var(--orange-sec);
    }
  }
  &--small {
    height: 24px;
    padding: 4px 8px;
    &--rejected {
      color: var(--negative-sec);
      border-color: var(--negative-sec);
    }
    &--moderating {
      color: var(--blue-second);
      border-color: var(--blue-second);
    }
    &--published {
      color: var(--positive-secondary);
      border-color: var(--positive-secondary);
    }
    &--deleted {
      color: var(--orange-sec);
      border-color: var(--orange-sec);
    }
  }
  &--active {
    background-color: var(--white-contrast);
  }
}
</style>

<template>
  <div class="search-mobile--button-wrapper">
    <slot name="button" :on-click="showMobileFilters">
      <base-button
        text=""
        size="xss"
        variant="light_bordered"
        icon-size="16px"
        :round="true"
        icon-name="base/SlidersHorizontal"
        class="btn-filter"
        @click="showMobileFilters"
      />
    </slot>
    <q-dialog ref="dialogRef" position="bottom" style="z-index: 9999999">
      <div
        v-touch-pan.mouse.down="handleSwipe"
        class="mobil-popup"
        :style="{ top: positionTop + 'px' }"
        :class="{ 'return-to-top': positionTop === 0 }"
        @touchstart.self
        @touchmove.self
      >
        <span class="mobil-popup-pull" />
        <slot />
      </div>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import type QDialog from "quasar"

import { useDialogPluginComponent } from "quasar"

const { dialogRef } = useDialogPluginComponent()

const positionTop = ref<number>(0)

const handleSwipe = ({ evt, ...newInfo }) => {
  evt.preventDefault()
  positionTop.value = newInfo.distance.y
  if (newInfo.isFinal) {
    if (positionTop.value >= 90) {
      dialogRef.value?.hide()
      positionTop.value = 0
    } else {
      positionTop.value = 0
    }
  }
}
const showMobileFilters = () => {
  dialogRef.value?.show()
}
</script>

<style scoped lang="scss">
.btn-filter{
  height: 40px;
  padding: 0 11px;
}
</style>

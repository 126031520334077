<template>
  <section class="comparison">
    <header class="header">
      <h3
        class="header-title text-bold mobile-font--h5 text-black-monochrome text-body-1"
      >
        {{ $t("header.h_com") }}
      </h3>

      <div class="header-tabs">
        <!-- <div class="petals">
          <div
            :class="[
              'petal',
              'petal-property',
              { 'petal-active': currentTab === 'estates' },
            ]"
            @click="handleChangeTab('estates')"
          >
            <span class="petal-text text-body-2 ">Property</span>
            <span class="compare-badge compare-badge--property"
              ><p class="font--b6 text-black-monochrome">
                {{
                  matchesEstates.length
                    ? matchesEstates.length
                    : getLocalStorage(`match_estates`)?.length || 0
                }}
              </p></span
            >
          </div>
          <div
            :class="[
              'petal',
              ,
              'petal-land',
              { 'petal-active': currentTab === 'lands' },
            ]"
            @click="handleChangeTab('lands')"
          >
            <span class="petal-text text-body-2 ">Land</span>
            <span class="compare-badge compare-badge--land"
              ><p class="font--b6 text-black-monochrome">
                {{
                  matchesLands.length
                    ? matchesLands.length
                    : getLocalStorage(`match_lands`)?.length || 0
                }}
              </p></span
            >
          </div>
        </div> -->
        <base-button
          ref="sortButtRef"
          :text="$t('search_filters.fr_sort')"
          :size="$device.isMobile ? 'xs' : 'xss'"
          variant="light_bordered"
          icon-name="base/Sliders"
          :icon-size="$device.isMobile ? '18px' : '16px'"
          bold-text
        />
        <div>
          <q-menu
            :target="sortButtRef"
            class="sorting-menu"
            anchor="bottom left"
            self="top left"
            :offset="[0, 15]"
          >
            <div class="menu">
              <h3 class="text-body-1 text-black-monochrome text-bold">
                {{ $t("search_filters.fr_sort") }}
              </h3>

              <div
                v-for="(item, index) in sortingFilters"
                class="list-container"
              >
                <ul>
                  <li>
                    <button @click="sortEstates('desc', item)">
                      <p class="text-body-2 text-black-monochrome">
                        {{ item.label }}
                      </p>
                      <base-icon
                        v-if="item.desc"
                        size="22px"
                        name="base/Done"
                        filled
                      />
                    </button>
                  </li>
                </ul>
                <q-separator
                  v-if="sortingFilters.length !== index + 1"
                  style="width: 100%"
                />
              </div>
            </div>
          </q-menu>
        </div>
        <base-button
          :text="$device.isMobile ? 'All' : $t('search_filters.fr_remove_all')"
          :size="$device.isMobile ? 'xs' : 'xss'"
          variant="light_bordered"
          icon-name="base/Trash"
          :icon-size="$device.isMobile ? '18px' : '16px'"
          style="font-weight: 400"
          bold-text
          @click="removeAll"
        />
      </div>
    </header>
    <section v-show="!isTabChanging && !isLoading" class="matching__container">
      <div
        v-if="titleBoxRef && titleBoxRef[0] && isSortOn"
        ref="sortingPickerRef"
        class="sorting-picker animate__animated animate__fadeIn"
        :style="{
          width: !$device.isMobile
            ? titleBoxRef[0].clientWidth + 32 + 'px'
            : titleBoxRef[0].clientWidth + 16 + 'px',
        }"
      />

      <div class="matching__container-item">
        <span v-if="!$device.isMobile" class="sidebar-hidden"/>
        <span v-else class="sidebar sidebar-mobile animate__animated">
          <base-icon name="base/House" />
          <p class="mobile-font--b2 text-black-monochrome">
            {{ $t("main.m_prop") }}
          </p>
        </span>

        <TransitionGroup
          ref="estateItem1Ref"
          name="fade"
          tag="div"
          class="estate-item"
          @scroll="syncScroll('estateItem1Ref')"
        >
          <span
            v-for="(title, index) in matchArray.value"
            ref="titleBoxRef"
            :key="title.id"
            class="box box-title"
            :class="{ animate__zoomOut: deletedClasess[index] }"
            :style="{
              padding: titleBoxPaddingFromAmount + 'px',
              // height: titleBoxHFromAmount + 'px',
            }"
          >
            <base-search-card-sold-out-tag v-if="title.status === 'sold_out'" />
            <button
              class="delete hover-rotate"
              :style="{
                top: matchArray.value.length === 4 ? 20 + 'px' : 28 + 'px',
                right: matchArray.value.length === 4 ? 20 + 'px' : 28 + 'px',
              }"
              @click="removeFromComparison(index)"
            >
              <base-icon
                size="100%"
                color="var(--black-monochrome-60)"
                name="base/Close"
              />
            </button>
            <span
              v-if="!$device.isMobile"
              v-show="
                titleBoxRef &&
                titleBoxRef[index] &&
                typeof title.isDesktopMapOpen === 'boolean'
              "
              ref="mapReplacerRef"
              class="map-replacer animate__animated"
              :class="[
                {
                  animate__fadeInDown:
                    typeof title.isDesktopMapOpen === 'boolean' &&
                    title.isDesktopMapOpen,
                },
                {
                  animate__fadeOutUp:
                    typeof title.isDesktopMapOpen === 'boolean' &&
                    !title.isDesktopMapOpen &&
                    titleBoxRef &&
                    titleBoxRef[index],
                },
              ]"
            >
              <map-static
                v-if="titleBoxRef && titleBoxRef[index]"
                :is-info="false"
                :coords="title.coords"
                :map-height="
                  titleBoxRef[index].clientHeight
                    ? titleBoxRef[index].clientHeight + 'px'
                    : '0px'
                "
              />
            </span>
            <span class="photo" @click="openEstate(title.sanitizeName)">
              <q-img
                class="q-img"
                fit="cover"
                :src="getThumbnail(title.files?.[0]?.file, 'thumbnail_470x370')"
              />
            </span>

            <h5
              class="font--b1-2 mobile-font--b3 text-subtitle text-bold"
              @click="openEstate(title.sanitizeName)"
            >
              {{
                $device.isMobile ? truncateString(title.name, 16) : title.name
              }}
            </h5>
          </span>
          <span
            v-if="!isEnoughEstates"
            ref="placeholderBoxRef"
            class="box box-placeholder"
          >
            <span class="placeholder">
              <base-button
                size="round-ls"
                variant="dark"
                icon-name="base/Plus"
                round
                icon-size="30px"
                @click="openSearchPage"
              />

              <p class="text-subtitle mobile-font--b3 text-black-monochrome">
                {{ $t("compare.add") }}
              </p>
            </span>
          </span>
        </TransitionGroup>

        <span v-if="!$device.isMobile" class="sidebar animate__animated">
          <p class="text-body-2 text-black-monochrome">
            {{ $t("compare.address") }}
          </p>
          <p
            v-if="currentTab !== 'lands'"
            class="text-body-2 text-black-monochrome"
          >
            {{ $t("compare.country") }}
          </p>
        </span>
        <span v-else class="sidebar sidebar-mobile animate__animated">
          <base-icon name="base/MapPin" />
          <p class="mobile-font--b2 text-black-monochrome">
            {{ $t("main.m_loc") }}
          </p>
        </span>
        <TransitionGroup
          ref="estateItem2Ref"
          name="fade"
          tag="div"
          class="estate-item"
          @scroll="syncScroll('estateItem2Ref')"
        >
          <span
            v-for="(country, index) in matchArray.value"
            ref="countryBoxRef"
            :key="country.id"
            class="box box-country"
            :class="{ animate__zoomOut: deletedClasess[index] }"
          >
            <comparison-map-block
              v-if="countryBoxRef && countryBoxRef[index]"
              :location="country.location"
              :country-name="country.country?.name"
              :coords="country.coords"
              :arr-length="matchArray.value?.length"
              :parent-height="countryBoxRef[index]"
              :is-map-open="country.isDesktopMapOpen"
              @open-decktop="openDecktopMap(country.coords, index)"
              @open-mobile="openMobileMap"
            />
          </span>
          <span
            v-if="!isEnoughEstates"
            class="box box-country"
            style="opacity: 0"
          />
        </TransitionGroup>
        <span v-if="!$device.isMobile" class="sidebar animate__animated">
          <p
            v-for="title in compareGroup.third"
            class="text-body-2 text-black-monochrome"
          >
            {{ title }}
          </p>
        </span>
        <span v-else class="sidebar sidebar-mobile animate__animated">
          <base-icon name="base/Table" filled />
          <p class="mobile-font--b2 text-black-monochrome">
            {{ $t("compare.details") }}
          </p>
        </span>
        <TransitionGroup
          ref="estateItem3Ref"
          name="fade"
          tag="div"
          class="estate-item"
          @scroll="syncScroll('estateItem3Ref')"
        >
          <span
            v-for="(prop, index) in matchArray.value"
            ref="firstPropsBoxRef"
            :key="prop.id"
            class="box box-props"
            :class="{ animate__zoomOut: deletedClasess[index] }"
          >
            <span
              v-for="(item, innerIndex) in compareGroup.secondBlock"
              class="text-black-monochrome row-flexbox"
            >
              <p v-if="$device.isMobile" class="font--b4">
                {{ item.title }}
                <!-- {{ $t("search_filters.fr_beds") }} -->
              </p>
              <span
                class="text-body-1 mobile-font--b4-bold text-bold"
                :style="{
                  color: isHighestValue(item.key, index, matchArray.value),
                }"
              >
                {{
                  !item.isConvertable
                    ? (prop[item.key as keyof typeof prop] ?? 0)
                    : convertArea(
                        prop[item.key as keyof typeof prop],
                        AREA_UNITS.m2,
                        AREA_UNITS[BASE_AREA_UNIT.code]
                      )
                }}
                <span v-if="item.isConvertable">{{ BASE_AREA_UNIT.code }}</span>
              </span>
              <q-separator
                v-if="innerIndex + 1 !== compareGroup.secondBlock.length"
              />
            </span>
            <!-- <span class="text-black-monochrome row-flexbox">
              <p v-if="$device.isMobile" class="font--b4">
                {{ $t("search_filters.fr_baths") }}
              </p>
              <p class="font--b2 mobile-font--b4-bold">
                {{ prop.bathroom || 0 }}
              </p>
            </span>
            <q-separator />
            <span class="text-black-monochrome row-flexbox">
              <p v-if="$device.isMobile" class="font--b4">
                {{ $t("search_filters.fr_area") }}
              </p>
              <p class="font--b2 mobile-font--b4-bold">
                {{ prop.area || 0 }}m²
              </p>
            </span> -->
          </span>
          <span
            v-if="!isEnoughEstates"
            class="box box-props"
            style="opacity: 0"
          />
        </TransitionGroup>

        <span v-if="!$device.isMobile" class="sidebar animate__animated">
          <p
            v-for="title in compareGroup.fourth"
            class="text-body-2 text-black-monochrome"
          >
            {{ title }}
          </p>
        </span>
        <span v-else class="sidebar sidebar-mobile animate__animated">
          <base-icon name="base/USD" />
          <p class="mobile-font--b2 text-black-monochrome">
            {{ $t("unit_page.un_finance") }}
          </p>
        </span>
        <TransitionGroup
          ref="estateItem4Ref"
          class="estate-item"
          name="fade"
          tag="div"
          @scroll="syncScroll('estateItem4Ref')"
        >
          <span
            v-for="(prop, index) in matchArray.value"
            ref="secondPropsBoxRef"
            :key="prop.id"
            class="box box-props"
            :class="{ animate__zoomOut: deletedClasess[index] }"
          >
            <span class="column-flexbox grow-box">
              <p v-if="$device.isMobile" class="font--b4">
                {{ compareGroup.thirdBlock.price }}
              </p>
              <span class="currency-flex">
                <span
                  class="text-black-monochrome text-body-1 mobile-font--b4-bold text-bold"
                  :style="{
                    color: isHighestValue('priceUSD', index, matchArray.value),
                  }"
                >
                  <p
                    v-if="
                      BASE_CURRENCY.code !==
                      getCurrentCurrency(prop.currency).code
                    "
                  >
                    ≈
                  </p>

                  {{
                    BASE_CURRENCY.symbol +
                    formatPrice(
                      prop.priceUSD,
                      true,
                      BASE_CURRENCY.code !==
                        getCurrentCurrency(prop.currency).code
                    )
                  }}
                  <base-tooltip
                    v-if="
                      BASE_CURRENCY.code !==
                      getCurrentCurrency(prop.currency).code
                    "
                    :tooltip-text="
                      $t('search_card.fr_operates', {
                        currency: getCurrentCurrency(prop.currency).code,
                      })
                    "
                    position="right"
                  >
                    <template #icon>
                      <suspense>
                        <base-icon
                          name="base/Info"
                          size="16px"
                          color="var(--black-monochrome-60)"
                        />
                      </suspense> </template
                  ></base-tooltip>
                </span>
              </span>
            </span>
            <q-separator />
            <span class="text-black-monochrome column-flexbox">
              <p v-if="$device.isMobile" class="font--b4">
                {{ $t("search_filters.fr_price") }}
              </p>
              <span class="currency-flex">
                <p
                  class="text-black-monochrome text-bold text-body-1 mobile-font--b4-bold"
                  :style="{
                    color: isHighestValue(
                      'tokenomics.priceUSD',
                      index,
                      matchArray.value
                    ),
                  }"
                >
                  {{
                    BASE_CURRENCY.symbol +
                    formatPrice(
                      prop.tokenomics.priceUSD,
                      false,
                      BASE_CURRENCY.code !==
                        getCurrentCurrency(prop.currency).code
                    )
                  }}
                </p>
                <span
                  v-if="
                    BASE_CURRENCY.code !==
                    getCurrentCurrency(prop.tokenomics.currency).code
                  "
                >
                  <p class="text-body-2 text-black-monochrome">
                    ≈
                    {{
                      getCurrentCurrency(prop.tokenomics.currency).symbol +
                      ((prop.tokenomics.price || 0) > 100000000
                        ? abbreviateNumber(prop.tokenomics.price)
                        : formatPrice(prop.tokenomics.price))
                    }}
                  </p>
                  <base-tooltip
                    :tooltip-text="`${$t('search_card.fr_operates', {
                      currency: getCurrentCurrency(prop.currency).code,
                    })}<br> ${$t('search_card.fr_rate')}`"
                    style="margin-bottom: 1px"
                  >
                    <template #icon>
                      <suspense>
                        <base-icon
                          name="base/Info"
                          size="16px"
                          color="var(--black-monochrome-60)"
                        />
                      </suspense> </template
                  ></base-tooltip>
                </span>
              </span>
            </span>
            <q-separator />
            <span
              v-if="currentTab === 'estates'"
              class="text-black-monochrome column-flexbox"
            >
              <span v-if="$device.isMobile" class="font--b4 flexbox"
                >IRR

                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                    @click="callTooltipNotification('irr')"
                  />
                </suspense>
              </span>
              <p
                v-if="prop.irr"
                class="text-body-1 text-bold mobile-font--b4-bold"
                :style="{
                  color: isHighestValue('irr', index, matchArray.value),
                }"
              >
                {{ formatPercentage(prop.irr) }}%
              </p>
              <base-apr-irr v-else @click="openIssuer(prop)"/>
            </span>
            <q-separator v-if="currentTab === 'estates'" />
            <span
              v-if="currentTab === 'estates'"
              class="text-black-monochrome column-flexbox"
            >
              <span v-if="$device.isMobile" class="font--b4 flexbox"
                >APR

                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                    @click="callTooltipNotification('apr')"
                  />
                </suspense>
              </span>
              <p
                v-if="prop.apr"
                class="text-body-1 text-bold mobile-font--b4-bold"
                :style="{
                  color: isHighestValue('apr', index, matchArray.value),
                }"
              >
                {{ formatPercentage(prop.apr) }}%
              </p>
              <base-apr-irr v-else @click="openIssuer(prop)"/>
            </span>
            <q-separator v-if="currentTab === 'estates'" />
            <span
              v-if="currentTab === 'estates'"
              class="text-black-monochrome column-flexbox"
            >
              <p v-if="$device.isMobile" class="font--b4">
                {{ $t("search_card.fr_growth") }}
              </p>
              <p
                class="text-body-1 mobile-font--b4-bold text-bold"
                :style="{
                  color: isHighestValue(
                    'finance.projectTotalIncome.subfields.projectionAppreciation',
                    index,
                    matchArray.value
                  ),
                }"
              >
                {{
                  formatPercentage(
                    prop.finance?.projectTotalIncome?.subfields
                      ?.projectionAppreciation
                  ) || 0
                }}%
              </p>
            </span>
            <q-separator v-if="currentTab === 'estates'" />
            <span
              v-if="$device.isMobile"
              class="text-black-monochrome column-flexbox"
            >
              <base-progress-bar
                max-width="100%"
                :percent="prop.tokenomics?.availableSupplyPercentage || 0"
                type="line"
              />
            </span>
            <p
              v-if="!$device.isMobile"
              class="text-body-1 text-black-monochrome text-bold"
              :style="{
                color: isHighestValue(
                  'token.availableSupply',
                  index,
                  matchArray.value
                ),
              }"
            >
              {{ abbreviateNumber(prop.tokenomics.availableSupply) || 0 }}
            </p>
          </span>
          <span
            v-if="!isEnoughEstates"
            class="box box-props"
            style="opacity: 0"
          />
        </TransitionGroup>
      </div>
    </section>

    <section
      v-if="isTabChanging || isLoading"
      class="matching__container tab-changer"
    >
      <span/>
      <q-skeleton width="100%" height="238px" />
      <q-skeleton width="100%" height="238px" />
      <q-skeleton width="110px" height="110px" />
      <q-skeleton width="100%" height="110px" />
      <q-skeleton width="100%" height="110px" />
      <q-skeleton width="110px" height="110px" />
      <q-skeleton width="100%" height="110px" />
      <q-skeleton width="100%" height="110px" />
    </section>
  </section>
</template>

<script setup lang="ts">
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import { Api, type UnitResponseDTO } from "~/services/swagger/Api";
import { useQuasar } from "quasar";
import { truncateString } from "~/utilities/helpers/strings/truncateString";
import {
  formatPrice,
  formatPercentage,
  abbreviateNumber,
} from "~/utilities/helpers/format-data/number";
import { Notification } from "~/services/notifications/toast";
import { isEqual } from "lodash";
import eventBus from "~/utilities/composables/eventBus";
import noImage from "~/assets/img/no-image.svg";
import { getCurrentCurrency, BASE_CURRENCY } from "~/composables/CURRENCIES";
import { updateComparisonSeo } from "~/services/SEO/updateComparePageSeo";
import { unitPageStore } from "~/store/unitPage";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import {
  BASE_AREA_UNIT,
  convertArea,
  AREA_UNITS,
} from "~/composables/AREA_UNITS";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";

definePageMeta({
  layout: "default",
});

updateComparisonSeo();

type UpdatedUnitResponse = UnitResponseDTO & {
  isDesktopMapOpen: boolean | null;
};

type SortingVariants = "asc" | "desc";
type FilterItem = {
  label: string;
  value: string[];
  asc: boolean;
  desc: boolean;
  id: number;
};

const apiClient = new Api();
const $q = useQuasar();
const localePath = useLocalePath();
const { t } = useI18n();
const useUnitpageStore = unitPageStore();

const currentTab = ref<"estates" | "lands">("estates");

const matchArray = computed<Ref<UpdatedUnitResponse[]>>(() => {
  switch (currentTab.value) {
    case "estates":
      return matchesEstates;
    case "lands":
      return matchesLands;
  }
});

const cashedIds = ref<string[] | null>(null);
const isEnoughEstates = ref(true);
const isTabChanging = ref(false);
const isLoading = ref(true);
// const matchesArray = ref<UpdatedEstateDTO[]>([]);
const matchesEstates = ref<UpdatedUnitResponse[]>([]);
const matchesLands = ref<UpdatedUnitResponse[]>([]);
const titleBoxRef = ref<HTMLElement[] | null>(null);
const countryBoxRef = ref<HTMLElement[] | null>(null);
const firstPropsBoxRef = ref<HTMLElement[] | null>(null);
const secondPropsBoxRef = ref<HTMLElement[] | null>(null);
const sideBarsRef = ref<HTMLElement[] | null>(null);
const estateItem1Ref = ref<HTMLElement | null>(null);
const estateItem2Ref = ref<HTMLElement | null>(null);
const estateItem3Ref = ref<HTMLElement | null>(null);
const estateItem4Ref = ref<HTMLElement | null>(null);
const sortingPickerRef = ref<HTMLElement | null>(null);
const sortButtRef = ref<HTMLElement | null>(null);
const placeholderBoxRef = ref<HTMLElement | null>(null);
const mapReplacerRef = ref<HTMLElement[] | null>(null);
const deletedClasess = ref<boolean[]>([false, false, false, false]);
const titleBoxPaddingFromAmount = computed(() =>
  matchArray.value.value.length === 4 ? 12 : 16
);

const UNIT = useUnitpageStore.createUnit();

const estatesThirdSidebar = Object.freeze([
  t("search_filters.fr_beds"),
  t("search_filters.fr_baths"),
  t("search_filters.fr_area"),
]);
const landsThirdSidebar = Object.freeze([
  t("search_filters.fr_acreage"),
  t("unit_page.un_current_use.title"),
]);
const estatesFourthSidebar = Object.freeze([
  t("search_card.fr_price"),
  t("search_filters.fr_price"),
  "IRR",
  "APR",
  t("search_card.fr_growth"),
  t("search_card.fr_tokens_avail"),
]);
const landsFourthSidebar = Object.freeze([
  t("search_card.fr_price_land"),
  t("search_filters.fr_price"),
  t("search_card.fr_tokens_avail"),
]);

const estateSecondBlock = Object.freeze([
  { title: t("search_filters.fr_beds"), key: "bedroom" },
  { title: t("search_filters.fr_baths"), key: "bathroom" },
  { title: `${t("search_filters.fr_area")}`, key: "area", isConvertable: true },
]);

const landSecondBlock = Object.freeze([
  { title: t("search_filters.fr_acreage"), key: "area", isConvertable: true },
  { title: t("unit_page.un_current_use.title"), key: "currentUse" },
]);
const estateThirdBlock = Object.freeze({
  price: t("search_card.fr_price"),
});

const landThirdBlock = Object.freeze({
  price: t("search_card.fr_price_land"),
});

const compareGroup = computed(() => {
  switch (currentTab.value) {
    case "estates":
      return {
        third: estatesThirdSidebar,
        fourth: estatesFourthSidebar,
        secondBlock: estateSecondBlock,
        thirdBlock: estateThirdBlock,
      };
    case "lands":
      return {
        third: landsThirdSidebar,
        fourth: landsFourthSidebar,
        secondBlock: landSecondBlock,
        thirdBlock: landThirdBlock,
      };
  }
});
// const titleBoxHFromAmount = computed(() =>
//   matchesArray.value.length === 4 ? 242 : 310
// );

const idsCopy = ref<string[] | null>(null);
const isSortOn = ref(false);

const sortingFilters = ref([
  {
    label: "IRR",
    value: ["irr"],
    asc: false,
    desc: false,
    id: 1,
  },
  {
    label: "APR",
    value: ["apr"],
    asc: false,
    desc: false,
    id: 2,
  },
]);

// const getCashedEstates = async () => {
//   const estatesIds: string[] | null = getLocalStorage("match_estates");
//   idsCopy.value = estatesIds;
//   isEnoughEstates.value =
//     Boolean(estatesIds) && Array.isArray(estatesIds) && estatesIds.length >= 2;
//   cashedIds.value = estatesIds;
//   if (!estatesIds) {
//     animateBlocks();
//     return;
//   }
//   $q.loading.show();
//   try {
//     const fetchPromises = cashedIds.value!.map((item) => fetchEstates(item));
//     await Promise.all(fetchPromises);
//     animateBlocks();
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setTimeout(() => {
//       $q.loading.hide();
//     }, 1000);
//   }
// };
const getCashedProps = async () => {
  const propsIds: string[] | null = getLocalStorage(
    `match_${currentTab.value}`
  );
  idsCopy.value = propsIds;
  eventBus.emit("compare-counter", propsIds?.length || 0);
  isEnoughEstates.value =
    Boolean(propsIds) && Array.isArray(propsIds) && propsIds.length >= 2;
  cashedIds.value = propsIds;
  if (!propsIds) {
    animateBlocks();
    isLoading.value = false;
    return;
  }
  isLoading.value = true;
  try {
    const fetchPromises = cashedIds.value!.map((item) => fetchProps(item));
    await Promise.all(fetchPromises);
    const idOrder = new Map(idsCopy.value?.map((id, index) => [id, index]));
    matchArray.value.value.sort(
      (a, b) =>
        (idOrder.get(a.id) ?? Infinity) - (idOrder.get(b.id) ?? Infinity)
    );
    animateBlocks();
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const renewEstates = async (newIds?: string[]) => {
  if (!newIds || !Array.isArray(newIds) || !newIds.length) return;
  isEnoughEstates.value =
    Boolean(newIds) && Array.isArray(newIds) && newIds.length >= 2;
  matchArray.value.value = matchArray.value.value.filter((estate, index) => {
    if (!newIds.includes(estate.id)) {
      deletedClasess.value.splice(index, 1);
      return false;
    }
    return true;
  });

  cashedIds.value = newIds;

  for (const id of newIds) {
    if (!matchArray.value.value.some((estate) => estate.id === id)) {
      await fetchProps(id);
      // try {
      //   const response = await currentPropertyType.value.getPageData(id, true);
      //   if (!response || !response.data) {
      //     handleErrorFetchingProp(id);
      //     return;
      //   }
      //     const data = response.data.value;
      //     data.files = data.files
      //       ?.filter((item) => item.type === "image")
      //       .slice(0, 1);
      //     if (!data.files.length) {
      //       data.files.push({ file: { url: noImage } });
      //     }
      //     matchArray.value.value.push({ ...data, isDesktopMapOpen: false });
      // } catch (error) {
      //   console.error(error);
      // }
    }
  }
  nextTick(() => {
    animateBlocks();
    disableSorting();
  });
};

const disableSorting = () => {
  isSortOn.value = false;
  sortingFilters.value.forEach((q) => {
    q.asc = false;
    q.desc = false;
  });
};

const fetchProps = async (id: string) => {
  try {
    const response = await UNIT.getPageData(id, true, true);
    if (!response) {
      handleErrorFetchingProp(id);
      return;
    }
    const data = response;
    data.files = data.files
      ?.filter((item) => item.type === "image")
      .slice(0, 1);
    if (!data.files.length) {
      data.files.push({ file: { url: noImage } });
    }
    matchArray.value.value.push({ ...data, isDesktopMapOpen: null });
  } catch (error) {
    handleErrorFetchingProp(id);
    console.error(error);
  }
};

const handleErrorFetchingProp = (id: string) => {
  let propsIds: string[] | null = getLocalStorage(`match_${currentTab.value}`);
  if (propsIds) {
    propsIds = propsIds.filter((q) => q !== id);
    writePersistentLocalStorage(`match_${currentTab.value}`, propsIds);
  }
};

const removeFromComparison = (
  index: number,
  isAll?: boolean
): Promise<void> => {
  return new Promise((resolve, reject) => {
    // if (matchArray.value.value.length === 2 && !isAll) {
    //   const errorNotify = new Notification({
    //     type: "system",
    //     message: t("errors.compare.less"),
    //   });
    //   errorNotify.createNewToast();
    //   reject();
    //   return;
    // }
    deletedClasess.value[index] = true;
    let propsIds: string[] | null = getLocalStorage(
      `match_${currentTab.value}`
    );
    if (propsIds) {
      propsIds = propsIds.filter((q) => q !== matchArray.value.value[index].id);
      writePersistentLocalStorage(`match_${currentTab.value}`, propsIds);
      eventBus.emit("compare-counter", propsIds?.length || 0);
    }
    setTimeout(() => {
      nextTick(() => {
        deletedClasess.value.splice(index, 1);
        deletedClasess.value.forEach(
          (q, i) => (deletedClasess.value[i] = false)
        );

        nextTick(() => {
          matchArray.value.value.splice(index, 1);
          isEnoughEstates.value = matchArray.value.value.length > 1;
          disableSorting();
          resolve();
        });
      });
    }, 500);
  });
};

const removeAll = async () => {
  for (let index = 0; index < matchArray.value.value.length; index++) {
    deletedClasess.value[index] = true;
  }
  setTimeout(() => {
    nextTick(() => {
      deletedClasess.value.length = 0;
      nextTick(() => {
        matchArray.value.value.length = 0;
        writePersistentLocalStorage(`match_${currentTab.value}`, []);
        eventBus.emit("compare-counter", 0);
      });
      setTimeout(() => {
        isEnoughEstates.value = false;
      }, 1000);
    });
  }, 500);
};

const animateItems = (
  itemsRef: HTMLElement[] | null,
  delayMultiplier: number,
  animation: string
) => {
  itemsRef?.forEach((item, index) => {
    if (item) {
      setTimeout(
        () => {
          item.style.setProperty("opacity", "1");
          item.classList.add(`animate__${animation}`);
        },
        delayMultiplier * (index + 1)
      );
    }
  });
};

const animateBlocks = () => {
  // animateItems(titleBoxRef.value, 100, "");
  // animateItems(countryBoxRef.value, 200, "");
  // animateItems(firstPropsBoxRef.value, 220, "");
  // animateItems(secondPropsBoxRef.value, 220, "");
  // countryBoxRef.value?.forEach((item) => {
  // item.setAttribute("style", `width:${titleBoxRef.value[0].clientWidth}px`);
  // });
  if (!isEnoughEstates.value) {
    animateItems([placeholderBoxRef.value!], 100, "fadeInRight");
  }
  sideBarsRef.value = Array.from(document.querySelectorAll(".sidebar"));
  animateItems(sideBarsRef.value, 100, "fadeInLeft");
};

const isSyncing = ref(false);
const containers = ref();
const d = useDevice();
const syncScroll = (source: string) => {
  if (isSyncing.value) return;

  isSyncing.value = true;
  const sourceContainer = containers.value[source].$el;
  if (sourceContainer) {
    const { scrollTop, scrollLeft } = sourceContainer;
    if (sortingPickerRef.value) {
      if (d.isMobile) {
        sortingPickerRef.value.style.display =
          scrollLeft === 0 ? "block" : "none";
      } else {
        sortingPickerRef.value.style.left = scrollLeft
          ? "-" + scrollLeft + "px"
          : "0px";
      }
    }
    for (const key in containers.value) {
      if (key !== source) {
        const targetContainer =
          containers.value[key as keyof typeof containers];
        if (targetContainer) {
          targetContainer.$el.scrollTop = scrollTop;
          targetContainer.$el.scrollLeft = scrollLeft;
        }
      }
    }
  }
  isSyncing.value = false;
};

const openSearchPage = () => {
  navigateTo(localePath(`/directory`));
};

const openEstate = (id: string) => {
  if (!id) return;
  const dynamicRoute = localePath(`/property/${id}`);
  window.open(window.location.origin + dynamicRoute, "_blank");
};

const openDecktopMap = (coords: number[] | undefined, index: number) => {
  if (typeof index !== "number" || !coords) return;
  nextTick(() => {
    if (matchArray.value.value[index].isDesktopMapOpen) {
      matchArray.value.value[index].isDesktopMapOpen = false;
    } else {
      matchArray.value.value[index].isDesktopMapOpen = true;
    }
  });
};
const openMobileMap = () => {
  // const mapModals = matchesArray.value.map((item) => {
  //   return {
  //     coords: item.coords,
  //     name: item.name,
  //   };
  // });
};

const callTooltipNotification = (type: "irr" | "apr") => {
  const tooltipNotify = new Notification({
    type: "system",
    message: type === "irr" ? t("finance.IRR") : t("finance.APR"),
  });
  tooltipNotify.createNewToast();
};

const sortEstates = (type: SortingVariants, item: FilterItem) => {
  // Обновляем состояние сортировки
  item[type] = !item[type];
  const otherType: SortingVariants = type === "asc" ? "desc" : "asc";
  item[otherType] = false;
  // Очищаем сортировку для других фильтров
  sortingFilters.value.forEach((filter) => {
    if (filter.id !== item.id) {
      filter.asc = false;
      filter.desc = false;
    }
  });

  // Проверяем, активна ли сортировка
  isSortOn.value = sortingFilters.value.some(
    (filter) => filter.asc || filter.desc
  );

  // Закрываем карту на мобильных устройствах
  matchArray.value.value.forEach((q) => {
    if (q.isDesktopMapOpen) {
      q.isDesktopMapOpen = false;
      setTimeout(() => {
        q.isDesktopMapOpen = null;
      }, 500);
    }
  });

  matchArray.value.value = toRaw(matchArray.value.value).toSorted((a, b) => {
  for (const filter of sortingFilters.value) {
    const sortType = filter.asc ? "asc" : filter.desc ? "desc" : null;
    if (!sortType) continue;

    const valueA = parseFloat(getValueByPath(a, filter.value) ?? '0');
    const valueB = parseFloat(getValueByPath(b, filter.value) ?? '0');

    if (valueA == null && valueB == null) continue;
    if (valueA == null) return sortType === "asc" ? 1 : -1;
    if (valueB == null) return sortType === "asc" ? -1 : 1;

    const comparison = valueA - valueB;

    if (comparison !== 0) {
      return sortType === "asc" ? comparison : -comparison;
    }
  }
  return 0;
});



  if (!isSortOn.value) {
    const propsIds: string[] | null = getLocalStorage(
      `match_${currentTab.value}`
    );
    if (propsIds && Array.isArray(matchArray.value.value)) {
      // Сортируем matchArray.value.value в соответствии с порядком id в propsIds
      matchArray.value.value.sort((a, b) => {
        return propsIds.indexOf(a.id) - propsIds.indexOf(b.id);
      });
    }
  }
};

// Функция для извлечения значения по пути
const getValueByPath = (obj: any, path: string[]) => {
  return path.reduce((acc, key) => acc?.[key], obj);
};

const clearSort = () => {
  // Очищаем сортировку
  sortingFilters.value.forEach((filter) => {
    filter.asc = false;
    filter.desc = false;
  });
  isSortOn.value = false;
};

const gatherProps = async () => {
  await getCashedProps();
  containers.value = {
    estateItem1Ref,
    estateItem2Ref,
    estateItem3Ref,
    estateItem4Ref,
  };
};

const handleChangeTab = (tab: typeof currentTab.value) => {
  isTabChanging.value = true;
  currentTab.value = tab;
  nextTick(async () => {
    clearSort();
    if (!matchArray.value.value.length) {
      await gatherProps();
    }
    isEnoughEstates.value = matchArray.value.value.length > 1;
    setTimeout(
      () => {
        isTabChanging.value = false;
      },
      !matchArray.value.value.length ? 0 : 700
    );
  });
};

const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split(".").reduce((acc, key) => acc?.[key], obj);
};

const isHighestValue = (
  key: string,
  index: number,
  array: Record<string, any>[]
) => {
  const currentValue = parseFloat(getNestedValue(array[index], key));

  // Проверяем, что currentValue является числом
  if (isNaN(currentValue)) return "var(--black-monochrome)";

  // Получаем все числовые значения для ключа
  const allValues = array
    .map((item) => parseFloat(getNestedValue(item, key)))
    .filter((value) => !isNaN(value));

  // Находим максимальное значение
  const maxValue = Math.max(...allValues);

  // Возвращаем цвет, если currentValue равно maxValue
  return currentValue === maxValue && currentValue !== 0
    ? "var(--green-500)"
    : "var(--black-monochrome)";
};

const openIssuer = (prop) => {
  redirectByProviderName({
    providerName: prop?.provider?.name,
    providerLink: prop.provider?.url,
    utmLink: prop.referralLink?.link,
    externalUrl: prop?.externalUrl,
    source: "directory__open_ref",
    images: prop?.files,
    logoFile: prop?.provider?.logoFile?.url,
  });
};

onMounted(async () => {
  gatherProps();
  watch(
    () => $q.appVisible,
    (val) => {
      if (val) {
        const newIds = getLocalStorage(`match_${currentTab.value}`);
        if (isEqual(newIds, cashedIds.value)) {
          return;
        } else {
          renewEstates(newIds);
        }
      }
    }
  );
});
</script>

<style scoped lang="scss">
@import url(~/assets/styles/custom-select/index.scss);

.comparison {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 990px;
  padding-top: 30px;
  padding-bottom: 120px;
  gap: 24px;
  .header {
    position: relative;
    width: 100%;
    height: 106px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 990px;
    // gap: 24px;
    &-title {
      position: absolute;
      top: 5px;
      left: 0;
    }
    &-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px;
      border-radius: 40px;
      border: 1px solid var(--gray-monochrome);
      background: var(--white-contrast);
      .petals {
        position: absolute;
        left: 8%;
        top: 5px;
        .petal {
          position: relative;
          &-property {
            z-index: 30;
          }
          .compare-badge {
            position: absolute;
            top: -6px;

            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            z-index: 9;
            border-radius: 50%;
            border: 1px solid var(--purple-main);
            background: var(--lilac-secondary-light);
            &--property {
              right: 3px;
            }
            &--land {
              right: -1px;
            }
            p {
              font-weight: 500;
              line-height: 10px;
            }
          }
        }
      }
    }
  }
  .matching__container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 145px 1fr;
    row-gap: 24px;
    column-gap: 24px;
    max-width: 990px;
    width: 100%;

    .sorting-picker {
      position: absolute;
      height: calc(100% + 32px);
      top: -16px;
      left: 152px;
      border-radius: 24px;
      border: 1px solid rgba(45, 210, 156, 0.72);
      background: rgba(45, 210, 156, 0.24);
      z-index: 2;
    }

    &-item {
      display: contents;
      .sidebar {
        display: flex;
        width: 145px;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 16px;
        background: var(--gray-40);
        grid-row: span 1;
        opacity: 0;
        z-index: 3;
        &-hidden {
          width: 145px;
          grid-row: span 1;
          opacity: 0;
        }
      }
      .estate {
        &-item {
          width: 100%;
          grid-row: span 1;
          display: flex;
          align-items: center;
          gap: 24px;
          z-index: 3;
          .box {
            position: relative;
            width: 100%;
            max-width: 566px;
            display: grid;
            padding: 16px;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            flex: 1 0 0;
            border-radius: 16px;
            background: #f3f7fe;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
            overflow: hidden;
            height: 100%;
            justify-items: center;
            will-change: opacity;
            transition: opacity 0.3s ease-in-out;
            .q-separator {
              width: 100%;
            }
            &-title {
              height: 310px;
              // display: flex;
              grid-template-rows: 1fr fit-content(100%);

              position: relative;
              align-items: flex-start;
              will-change: padding, height;
              transition:
                padding 0.3s ease,
                height 0.3s ease;
              h5 {
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
              }
              .map-replacer {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 999;
              }

              .photo {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                cursor: pointer;
                .q-img {
                  max-height: 240px;
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                }
                // .trash-backdrop {
                //   position: absolute;
                //   left: 0;
                //   top: 0;
                //   width: 100%;
                //   height: 100%;
                //   display: flex;
                //   align-items: center;
                //   justify-content: center;
                //   padding: 24px;
                //   background-color: var(--black-monochrome-72);
                //   z-index: 4;
                //   border-radius: 12px;
                //   transition: opacity 0.3s ease;
                // }
              }
              &:hover {
                .delete {
                  opacity: 1;
                }
              }
              .delete {
                position: absolute;
                display: flex;
                padding: 4px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 32px;
                height: 32px;
                outline: none;
                border: none;
                background: var(--white-monochrome);
                border-radius: 50%;
                cursor: pointer;
                z-index: 99;
                opacity: 0;
                will-change: opacity;
              }
            }
            &-country {
              height: 100%;
              min-height: 99px;
              // justify-content: space-between;
              position: relative;
              // overflow: hidden;
            }
            &-props {
              align-items: center;
              .currency-flex {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                span {
                  display: flex;
                  align-items: center;
                  gap: 1px;
                }
              }
              .row-flexbox {
                position: relative;
                width: 100%;
                text-align: center;
                .q-separator {
                  width: 100%;
                  position: absolute;
                  left: 0;
                  bottom: -6px;
                }
              }
            }
            &-placeholder {
              height: 310px;
              width: 100%;
              display: grid;
              align-items: center;
              justify-content: center;
              .placeholder {
                width: fit-content;
                height: fit-content;
                display: grid;
                align-items: center;
                justify-items: center;
                text-align: center;
                gap: 32px;
              }
            }
          }
        }
      }
    }
  }
  .tab-changer {
    align-items: center;
    justify-items: center;
    width: 100%;
    height: fit-content;
    grid-template-columns: 143px 1fr 1fr;
    .q-skeleton {
      border-radius: 10px;
    }
  }
}

@keyframes shake-bottom {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}

@media (max-width: 65rem) {
  @include mobile-fonts;
  .comparison {
    padding-left: 16px;
    padding-right: 16px;
    .header {
      &-tabs {
        gap: 16px;
        .petals {
          left: 25%;
          top: 9px;
        }
      }
    }
    .matching__container {
      grid-template-columns: 1fr;
      max-width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      // overflow: hidden;
      .sorting-picker {
        height: calc(100% + -20px);
        top: 36px;
        left: 0;
      }
      &-item {
        .estate-item {
          gap: 16px;
          overflow-x: scroll;
          overflow-y: hidden;
          height: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .box {
            min-width: 164px;
            padding: 12px;
            &-title {
              height: 227px;
              .delete {
                opacity: 1;
                top: 20px;
                right: 20px;
                width: 28px;
                height: 28px;
              }
            }
            &-placeholder {
              height: 227px;
            }
            &-country {
              div {
                display: grid;
                gap: 8px;
                min-height: 99px;
              }
            }
            &-props {
              height: 100%;
              // justify-content: flex-end;
              .grow-box {
                flex-grow: 1;
              }

              .currency-flex {
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
              }
              .row-flexbox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .column-flexbox {
                width: 100%;
                gap: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: flex-start;

                .flexbox {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 4px;
                }
              }
            }
          }
        }
        .sidebar {
          width: 100%;
          &-mobile {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            justify-content: flex-start;
            max-width: calc(100dvw - 48px);
            padding: 4px 12px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.sorting-menu {
  border-radius: 24px;
  border: 1px solid var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
  background: var(--Monochrome-White_contrast, #f3f7fe);
  padding: 24px;
  height: auto;
  width: 316px;
  max-height: 252px;
  .menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    display: flex;
    width: 100%;
    height: 100%;
    h3 {
      margin-bottom: 6px;
    }
    .list-container {
      display: grid;
      gap: 12px;
      width: 100%;
      ul {
        display: grid;
        gap: 12px;
        padding-left: 25px;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          width: 100%;
          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: transparent;
            outline: none;
            border: none;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}
</style>

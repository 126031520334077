<template>
  <div ref="sideBarRef" class="sidebar">
    <span class="sidebar__title text-subtitle text-bold">{{ $t('header.h_nav_legal') }}</span>
    <div class="sidebar__list">
      <span
        v-for="(item, index) in props.titles"
        class="list-item"
        :class="{ 'list-item--active': currentActive === index }"
      >
        <button class="list-item__title text-body-2" @click="handleTitleClick(index)">
          {{ $rt(item.title) }}
        </button>
        <base-separator v-if="index + 1 !== props.titles.length" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  titles: { title: string }[];
}

const emit = defineEmits(["click-title"]);

const props = defineProps<IProps>();

const currentActive = ref<number>(0);

const handleTitleClick = (index: number) => {
  emit("click-title", index);
  currentActive.value = index;
};

const sideBarRef = ref<HTMLElement | null>(null);

defineExpose({
  sideBarRef,
});
</script>

<style scoped lang="scss"></style>
